//  трекер для отслеживания переходов, сохранение в куку
export default ({ $gaApp }) => {
  // Кол-во дней хранения cookie
  const daysToStore = 30
  const period = daysToStore * 60 * 60 * 24 // в секундах

  // Параметры создания cookie с UID
  const uidCookieName = 'columbus_ad_tracker' // Название cookie, в которой хранится uid
  const uidCookieOptions = {
    maxAge: period,
    path: '/',
  }

  const admitadParam = $gaApp.route.query.admitad_uid
  const cityadsParam = $gaApp.route.query.click_id

  if (admitadParam) {
    $gaApp.cookies.set(
      uidCookieName,
      JSON.stringify({
        url: {
          utm_source: 'admitad',
          admitad_uid: admitadParam,
        },
        id: '1',
      }),
      uidCookieOptions,
    )
  }

  if (cityadsParam) {
    $gaApp.cookies.set(
      uidCookieName,
      JSON.stringify({
        url: {
          utm_source: 'cityads',
          click_id: cityadsParam,
        },
        id: '3',
      }),
      uidCookieOptions,
    )
  }
}
