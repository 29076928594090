<template>
  <svg fill="none" viewBox="0 0 32 32">
    <g fill="#000">
      <path
        d="M12 8.667a4 4 0 1 1 4-4v4h-1.333v8h-12c0-2.485 0-3.728.406-4.708a5.333 5.333 0 0 1 2.886-2.886c.98-.406 2.223-.406 4.708-.406H12Z"
      />
      <path
        d="M20 8.667a4 4 0 1 0-4-4v4h1.333v8h12c0-2.485 0-3.728-.406-4.708a5.333 5.333 0 0 0-2.886-2.886c-.98-.406-2.223-.406-4.708-.406H20Z"
      />
      <path
        d="M14.667 19.334H4l.399 3.191c.33 2.647.496 3.97 1.119 4.967a5.333 5.333 0 0 0 2.281 2.014C8.866 30 10.2 30 12.866 30h1.8V19.334Z"
      />
      <path
        d="M17.333 19.334V30h1.8c2.668 0 4.001 0 5.068-.494a5.333 5.333 0 0 0 2.281-2.014c.623-.997.788-2.32 1.12-4.967L28 19.334H17.333Z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-info-skyeng-gift',
}
</script>
