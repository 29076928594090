import { NuxtClient } from '~/init/client'
import { NuxtWebview } from '~/init/webview'

export default async (context) => {
  // пытаемся найти инфу про вебвью
  const isWebview =
    context.$gaApp.route.query.webview ||
    context.$gaApp.cookies.get('ga-webview') ||
    context.$gaApp.services.app.browserStorage.local.get('ga-webview')

  const client = isWebview
    ? new NuxtWebview(context.$gaApp)
    : new NuxtClient(context.$gaApp)

  await client.init()
}
