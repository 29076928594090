import { MODAL_STATE } from '../constants/products'

/**
 *
Предоставляет методы для управления модальным окном:

`toggle(value)` - переключает состояние модального окна (открыто/закрыто), устанавливая значение opened в store modal.

`close()` - закрывает модальное окно, устанавливая opened в false.

`open()` - открывает модальное окно, устанавливая opened в true.

`shouldShowModal(data)` - определяет, следует ли показывать модальное окно, на основе переданных данных и состояния кэша модального окна.

Также в конструкторе подписывается на событие `'module/app/router/page-created'` для закрытия модального окна при смене страницы.

 */

export class ModalService {
  constructor(gaApp) {
    this.gaApp = gaApp

    gaApp.eventBus.subscribe('module/app/router/page-created', () => {
      gaApp.ctx.$doubleRAF(() => {
        this.close()
      })
    })
  }

  toggle(value) {
    this.gaApp.stores.review.modal.opened = value
  }

  close() {
    this.gaApp.stores.review.modal.opened = false
  }

  open() {
    this.gaApp.stores.review.modal.opened = true
  }

  shouldShowModal(data) {
    if (data?.products?.length === 0) {
      return
    }

    const currentOrderId = data.products[0].orderId
    const modalData = this.gaApp.services.review.cache.getModalCache()

    // Проверяем, существуют ли данные модального окна или изменился ли orderId заказа.
    const firstShow = modalData?.orderId !== currentOrderId

    if (firstShow) {
      // Обновляем кэш модального окна с новым идентификатором заказа и сбрасываем дату.
      this.gaApp.services.review.cache.resetModalCache(currentOrderId)
    }

    return (
      modalData.countViews < MODAL_STATE.MAX_VIEWS &&
      this.gaApp.services.review.date.timeSinceUpdateAt(modalData.updateAt)
    )
  }
}
