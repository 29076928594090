import { getFirstElementOrNull } from '@ga/utils'

import {
  CartBuilder,
  CouponApplyBuilder,
  CouponRemoveBuilder,
  FavoriteBuilder,
  ItemClickBuilder,
  OutOfStockDialogBeforeCheckoutDeleteBuilder,
  OutOfStockDialogBeforeCheckoutFavoriteBuilder,
  OutOfStockDialogBeforeCheckoutOpenBuilder,
  ShareAddToCartBuilder,
  ShareCartClickBuilder,
  ShareCartOpenBuilder,
  ShareCartProductClickBuilder,
  ShareSetCartBuilder,
  ViewBuilder,
  WarningBuilder,
} from '../../builders'
import { BaseController } from '../base.controller/base.controller'

export class CartAnalytics extends BaseController {
  /**
   * Просмотр корзины.
   */
  onView({ ids, id, totals }) {
    this.send(ViewBuilder, {
      items: this.getItemsAnalyticData({ ids, withStock: true }),
      currency: this._currency,
      id,
      totals,
    })
  }

  /**
   * При клике на продукт в корзине (не рекомендации)
   */
  onItemClick({ ids }) {
    const data = this.getItemsAnalyticData({ ids })

    this.send(ItemClickBuilder, getFirstElementOrNull(data))
  }

  /**
   * Добавление продуктов в корзину.
   */
  onAddToCart({ ids }) {
    this.send(CartBuilder, {
      currency: this._currency,
      items: this.getItemsAnalyticData({ ids }),
      addToCart: true,
      isAdded: false,
    })
  }

  /**
   * Удаление продуктов из корзины.
   */
  onRemoveFromCart({ ids }) {
    this.send(CartBuilder, {
      currency: this._currency,
      items: this.getItemsAnalyticData({ ids }),
      addToCart: false,
      isAdded: true,
    })
  }

  /**
   * Увеличение на единицу товара в корзине.
   */
  onIncrementItemCart(id) {
    const items = this.getItemsAnalyticData({
      ids: [id],
      isOneItem: true,
    })

    this.send(CartBuilder, {
      items,
      addToCart: true,
      currency: this._currency,
      isAdded: true,
      decrement: false,
    })
  }

  /**
   * Уменьшение на единицу товара в корзине.
   */
  onDecrementItemCart(id) {
    const items = this.getItemsAnalyticData({
      ids: [id],
      isOneItem: true,
    })

    this.send(CartBuilder, {
      items,
      addToCart: false,
      currency: this._currency,
      isAdded: true,
      decrement: true,
    })
  }

  /**
   * Добавление в избранное.
   */
  onAddToFavorites({ ids }) {
    this.send(FavoriteBuilder, {
      items: this.getItemsAnalyticData({ ids }),
    })
  }

  /**
   * Открытие модалки ультрасьютиклз.
   */
  onOpenWarning(product) {
    this.send(WarningBuilder, product)
  }

  /**
   * Открытие модалки удаления/избранного для товаров не в стоке
   * по кнопке "оформить заказ".
   */
  onOpenOutOfStockDialogBeforeCheckout() {
    this.send(OutOfStockDialogBeforeCheckoutOpenBuilder)
  }

  /**
   * Удаление товаров не в стоке из модалки после клика "оформить заказ"
   */
  onDeleteOutOfStockBeforeCheckout({ ids }) {
    this.send(OutOfStockDialogBeforeCheckoutDeleteBuilder, {
      items: this.getItemsAnalyticData({ ids }),
    })
  }

  /**
   * Добавление в избранное товаров не в стоке из модалки после клика "оформить заказ"
   */
  onAddToFavoriteOutOfStockBeforeCheckout({ ids }) {
    this.send(OutOfStockDialogBeforeCheckoutFavoriteBuilder, {
      items: this.getItemsAnalyticData({ ids }),
    })
  }

  /**
   * Применение промокода
   */
  onCouponApply(coupon, isSuccess) {
    this.send(CouponApplyBuilder, { coupon, isSuccess })
  }

  /**
   * Удаление промокода
   */
  onCouponRemove(coupon) {
    this.send(CouponRemoveBuilder, { coupon })
  }

  /**
   * Нажатие на кнопку "поделиться корзиной"
   */
  onShareCartClick(data) {
    this.send(ShareCartClickBuilder, data)
  }

  /**
   * Просмотр расшаренных товаров
   */
  onShareCartOpen({ products }) {
    this.send(ShareCartOpenBuilder, {
      products: this.getShareProductsAnalyticData(products),
    })
  }

  /**
   * Клик на расшаренный товар
   */
  onShareProductClick({ product, index }) {
    this.send(ShareCartProductClickBuilder, {
      product: this.getShareProductAnalyticData(product, index + 1),
    })
  }

  /**
   * Добавление расшаренных товаров в корзину
   */
  onShareAddToCart({ products }) {
    this.send(ShareAddToCartBuilder, {
      products: this.getShareProductsAnalyticData(products),
    })

    this.send(ShareSetCartBuilder, {
      products: this.getShareProductsAnalyticData(products),
    })
  }
}
