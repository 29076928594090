import {
  BtsButtonDownloadClickBuilder,
  BtsProductClickBuilder,
  BtsSocialClickBuilder,
  FormSentBuilder,
  HeroButtonClickBuilder,
  LoyaltyButtonClickBuilder,
} from '../../builders'
import { BaseController } from '../base.controller/base.controller'

export class InfoAnalytics extends BaseController {
  /**
   * Нажатие на кнопку "unlock your treasure" в hero-блоке лендинга Катара
   */
  onHeroButtonClick() {
    this.send(HeroButtonClickBuilder)
  }

  /**
   * Нажатие на кнопку "join" в блоке программы лояльности лендинга Катара
   */
  onLoyaltyButtonClick() {
    this.send(LoyaltyButtonClickBuilder)
  }

  /**
   * Отправка формы на странице лендинга Катара
   */
  onFormSent() {
    this.send(FormSentBuilder)
  }

  // back-to-school

  /**
   * Клик по кнопке "скачать чек-лист" лендинга back-to-school
   */
  btsOnButtonDownloadClick() {
    this.send(BtsButtonDownloadClickBuilder)
  }

  /**
   * Клик по продукту лендинга back-to-school
   */
  btsOnProductClick({ listName, itemSku }) {
    this.send(BtsProductClickBuilder, { listName, itemSku })
  }

  /**
   * Клик по иконке мессенджера/соц. сети back-to-school
   */
  btsOnSocialClick({ name, place }) {
    this.send(BtsSocialClickBuilder, { name, place })
  }
}
