import objectHash from 'object-hash'

import { PAGINATION_BUTTON } from '../constants'

const { NEXT, PREV } = PAGINATION_BUTTON
export class PlpService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async fetchProducts(page = 1, direction = NEXT) {
    this.setPagination({
      direction,
      value: {
        pending: true,
        error: false,
      },
    })

    try {
      await this.gaApp.services.purchased.api.fetchProductsByPage({
        page,
      })
    } catch (error) {
      this.setPagination({
        direction,
        value: { error: true },
      })
    } finally {
      this.setPagination({
        direction,
        value: { pending: false },
      })
    }
  }

  fetchProductsPrev() {
    const pageFirst = this.gaApp.stores.purchased.plp.pageNumberFirst

    if (pageFirst <= 1) {
      return false
    }

    return this.fetchProducts(pageFirst - 1, PREV)
  }

  fetchProductsNext() {
    const pageLast = this.gaApp.stores.purchased.plp.pageNumberLast
    const pagesCount = this.gaApp.stores.purchased.plp.totalPages

    if (pageLast === pagesCount) {
      return false
    }

    this.fetchProducts(pageLast + 1, NEXT)
  }

  resetListingAndFetchProducts() {
    if (this.gaApp.services.purchased.filters.areHashesEqual()) {
      return false
    }

    this.clearPagesAndFetchFirstPageProducts()
  }

  clearPagesAndFetchFirstPageProducts() {
    requestAnimationFrame(() => {
      window.scrollTo({ top: 0 })
    })

    this.gaApp.stores.purchased.plp.pages = []

    this.gaApp.services.filters.main.pageNumber.updateFilter(1)

    this.gaApp.services.filters.main.updateFiltersURL()

    this.fetchProducts(1, NEXT)
  }

  setProductsData(data, filtersSelected, page) {
    const { products, pageCount, count, countSelectedFilters } = data

    this.gaApp.stores.purchased.plp.productsCount = count

    if (products.length) {
      this.setPage({
        number: page,
        products: this.normalizeProducts(products),
      })
    }

    const setProductsHash = () => {
      this.gaApp.stores.purchased.plp.productsHash = objectHash(
        {
          filters: filtersSelected,
        },
        { unorderedArrays: true },
      )
    }

    const setCountSelectedFilters = () =>
      this.gaApp.stores.filters.main.setCountSelectedFilters(
        countSelectedFilters,
      )

    const setPageCount = () => {
      this.gaApp.stores.purchased.plp.totalPages = pageCount
    }

    this.gaApp.services.app.scheduler.postTasks([
      { task: setProductsHash },
      { task: setCountSelectedFilters },
      { task: setPageCount },
    ])
  }

  setHasProducts(productsData) {
    const { countSelectedFilters, products } = productsData
    if (countSelectedFilters) {
      this.gaApp.stores.purchased.plp.hasProducts = true
    } else {
      this.gaApp.stores.purchased.plp.hasProducts = Boolean(products.length)
    }
  }

  scrollToLastViewedProduct = () => {
    const product = this.gaApp.stores.purchased.plp.lastViewedProduct
    this.gaApp.services.app.scrollNavigation.scrollToAnchor(product)
    this.setLastViewedPage(1)
    this.setLastViewedProduct(null)
  }

  normalizeProducts(products = []) {
    return products.map((product) => {
      const colors = product.attributes?.colors ?? {}
      const units = product.attributes?.units ?? {}

      product.attributes.colors = {
        ...colors,
        values: colors?.values?.[0] ? [colors?.values[0]] : [],
      }

      product.attributes.units = {
        ...units,
        values: units?.currentUnitValue ? [units?.currentUnitValue] : [],
      }

      return product
    })
  }

  clear() {
    this.gaApp.stores.purchased.plp.pages = []
    this.gaApp.stores.purchased.plp.totalPages = 0
    this.gaApp.stores.purchased.plp.productsCount = 0
  }

  setPage(page) {
    this.gaApp.stores.purchased.plp.pages.push(page)
    this.gaApp.stores.purchased.plp.pages.sort((a, b) => a.number - b.number)
  }

  setPagination({ direction, value }) {
    this.gaApp.stores.purchased.plp.pagination[direction] = {
      ...this.gaApp.stores.purchased.plp.pagination[direction],
      ...value,
    }
  }

  setLastViewedPage(page) {
    this.gaApp.stores.purchased.plp.lastViewedPage = page
  }

  setLastViewedProduct(product) {
    this.gaApp.stores.purchased.plp.lastViewedProduct = product
  }
}
