import { ApiService } from './api.service'
import { LaunchQaService } from './launch-qa.service'
import { LrpEffaclarService } from './lrp-effaclar.service'
import { PageService } from './page.service'
import { SkyengService } from './skyeng.service'

export const services = (gaApp) => ({
  api: new ApiService(gaApp),
  page: new PageService(gaApp),
  launchQa: new LaunchQaService(gaApp),
  lrpEffaclar: new LrpEffaclarService(gaApp),
  skyeng: new SkyengService(gaApp),
})
