<template>
  <svg fill="none" viewBox="0 0 10 10">
    <path
      fill="currentColor"
      fill-opacity=".48"
      fill-rule="evenodd"
      stroke="none"
      d="m4.706 6.053 3.52 3.52L9.28 8.521l-3.52-3.52 3.52-3.522L8.227.427 4.706 3.948 1.185.427.132 1.479 3.654 5 .133 8.521l1.052 1.053 3.52-3.521Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-info-skyeng-cross',
}
</script>
