import { formatArrayAsString } from '@ga/utils'

import {
  DigiAqSynonymClickBuilder,
  DigiAutocompleteBuilder,
  PageLeaveProductViewBuilder,
  ProductCartBuilder,
  ProductClickBuilder,
  ProductFavoriteBuilder,
  ProductViewBuilder,
  SearchBuilder,
} from '../../builders'
import {
  ACTION_TYPES,
  AUTOCOMPLETE_ITEM_TYPES,
  CHUNK_SIZE_ANALYTICS,
} from '../../constants'
import { ViewedProducts } from '../../helpers'
import { BaseController } from '../base/base.controller'

export class SearchAnalytics extends BaseController {
  constructor(gaApp, core) {
    super(gaApp, core)

    this.viewedProduct = new ViewedProducts()
  }

  // GTM
  /**
   * Ввели запрос и нажали enter или кнопку перехода на поисковую выдачу
   */
  onClickSearchQuery(inputTerm) {
    this.send(SearchBuilder, {
      action_type: ACTION_TYPES.CLICK_SEARCH_QUERY,
      search_term: inputTerm,
      input_term: inputTerm,
    })
  }

  /**
   * Воспользовались "автокомплитом" в строке запроса
   */
  onAutocomplete(inputTerm, autocompleteTerm) {
    this.send(SearchBuilder, {
      action_type: ACTION_TYPES.AUTOCOMPLETE,
      input_term: inputTerm,
      autocomplete_term: autocompleteTerm,
    })
  }

  /**
   * Открытие модалки поиска
   */
  onOpenSearch() {
    this.send(SearchBuilder, {
      action_type: ACTION_TYPES.OPEN_SEARCH,
    })
  }

  /**
   * При закрытии модалки
   */
  onCloseSearch() {
    /**
     * Если при закрытии модалки, юзер увидел меньше CHUNK_SIZE_ANALYTICS товаров,
     * то нужно отправить об этом событие в аналитику
     */
    const products = this.viewedProduct.getAllProducts()
    const productsLength = products.length
    const productsLengthRest = productsLength % CHUNK_SIZE_ANALYTICS
    const lengthRestDiff = productsLength - productsLengthRest

    if (productsLengthRest && productsLengthRest < CHUNK_SIZE_ANALYTICS) {
      const chunk = products
        .slice(lengthRestDiff)
        .map((product) => this.getProductData(product))

      this.send(PageLeaveProductViewBuilder, { chunk })
    }

    // Очищаем список просмотренных продуктов
    this.viewedProduct.clearProducts()
  }

  /**
   * Клик на подсказке (history / popular / brands / category)
   */
  onClickSearchHints(inputTerm, type, searchTerm, searchPosition) {
    this.send(SearchBuilder, {
      action_type: ACTION_TYPES.CLICK_SEARCH_HINTS,
      type,
      input_term: inputTerm,
      search_term: searchTerm,
      search_position: searchPosition + 1,
    })
  }

  /**
   * Клик по истории
   */
  onClickHistory(searchTerm, autocompleteItem) {
    this.send(DigiAutocompleteBuilder, {
      anyQueryWorked: false,
      autocompleteItem,
      autocompleteItemType: AUTOCOMPLETE_ITEM_TYPES.HISTORY,
      originalSearchTerm: searchTerm,
      searchTerm,
    })
  }

  /**
   * Клик по категории
   */
  onClickCategory(searchTerm, autocompleteItem) {
    this.send(DigiAutocompleteBuilder, {
      anyQueryWorked: false,
      autocompleteItem,
      autocompleteItemType: AUTOCOMPLETE_ITEM_TYPES.CATEGORIES,
      originalSearchTerm: searchTerm,
      searchTerm,
    })
  }

  /**
   * Клик по бренду
   */
  onClickBrand(searchTerm, autocompleteItem) {
    this.send(DigiAutocompleteBuilder, {
      anyQueryWorked: false,
      autocompleteItem,
      autocompleteItemType: AUTOCOMPLETE_ITEM_TYPES.BRANDS,
      originalSearchTerm: searchTerm,
      searchTerm,
    })
  }

  /**
   * Клик по запросу
   */
  onClickQueries(searchTerm, autocompleteItem) {
    this.send(DigiAutocompleteBuilder, {
      anyQueryWorked: false,
      autocompleteItem,
      autocompleteItemType: AUTOCOMPLETE_ITEM_TYPES.QUERIES,
      originalSearchTerm: searchTerm,
      searchTerm,
    })
  }

  /**
   * Клик по товару
   */
  onClickProduct(searchTerm, product, isWidgetProductListing) {
    this.send(DigiAutocompleteBuilder, {
      anyQueryWorked: false,
      autocompleteItem: formatArrayAsString(
        [product.productType, product.brand, product.name],
        ' | ',
      ),
      autocompleteItemType: AUTOCOMPLETE_ITEM_TYPES.PRODUCTS,
      productId: product.itemId,
      originalSearchTerm: searchTerm,
      searchTerm,
    })

    if (!isWidgetProductListing) {
      const data = this.getProductData(product)
      this.send(ProductClickBuilder, data)
    }
  }

  /**
   * Клик по баблу подсказок
   */
  onClickSynonym(searchTerm, anyQuerySynonymClicked, anyQuerySynonyms) {
    this.send(DigiAqSynonymClickBuilder, {
      searchTerm,
      anyQuerySynonymClicked,
      anyQuerySynonyms,
    })
  }

  /**
   * Просмотр товаров
   */
  onProductView(data) {
    const product = this.getProductData(data)
    const chunk = this.viewedProduct
      .setViewed(data)
      .getLastChunk()
      .map((product) => this.getProductData(product))

    const products = this.viewedProduct
      .getAllProducts()
      .map((product) => this.getProductData(product))

    this.send(ProductViewBuilder, {
      product,
      chunk,
      products,
    })
  }

  /**
   * Добавление продукта в корзину.
   */
  onAddToCart(product, isAdded) {
    const data = this.getProductData(product, isAdded)
    this.send(ProductCartBuilder, { ...data, addToCart: true })
  }

  /**
   * Добавление продукта в избранное.
   */
  onAddToFavorite(product) {
    const data = this.getProductData(product)

    this.send(ProductFavoriteBuilder, data)
  }
}
