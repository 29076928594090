import { DEFAULT_TIMER, SYMAPHORE_FLAGS } from '../../constants'

const { FULL_SHOWED_CURRENT, AFTER_ENTER_CURRENT, UNMOUNTED_PREVIOUS } =
  SYMAPHORE_FLAGS

export class RibbonService {
  constructor(gaApp) {
    this.gaApp = gaApp

    this.duration = null
    this.iterator = null
    this.nextRibbonSymaphore = null
  }

  init(data = [], duration) {
    this.duration = duration || DEFAULT_TIMER
    this.iterator = this.gaApp.services.ticker.iterator.init(data)

    this.nextRibbonSymaphore = this.createNextRibbonSymaphore()

    this.setFirstRibbon()
  }

  /**
   * Запрашиваем первую плашку без задержки
   * Является "точкой входа" начала показа плашек.
   *
   * Устанавливаем через scheduler.postTask(), что бы плашка появилась на странице,
   * после остальных операций на странице, с целью плавности анимации появления
   */
  setFirstRibbon() {
    this.gaApp.services.app.scheduler.postTask(
      () => this.setNextRibbon(0),
      'background',
    )
  }

  /**
   * Запрашивает следующую плашку с задержкой равной %delay%
   * и установливает идентификаторы предыдущей и следующей плашки
   *
   * Нужно, для интервальной смены плашек. Когда текст плашки отобразился полностью,
   * необходимо показать следующую плашку спустя %delay%-милисекунд
   *
   * @param {number} delay время ождания следующей плашки
   */
  async setNextRibbon(delay = this.duration) {
    /**
     * Проверка на наличие итератора (this.iterator?) нужна потому, что
     * первая плашка устанавливается через postTask(_, 'background')
     *
     * И изредка возникает кейс, когда время выполнения тела postTask выпадает
     * на время отсутсвия итератора. Обычно при переходе между страницами
     */
    const nextRibbon = await this.iterator?.next(delay)
    const nextRibbonId = nextRibbon?.value?.id

    if (nextRibbonId) {
      this.previousId.set(this.currentId.get())
      this.currentId.set(nextRibbonId)

      this.nextRibbonSymaphore.reset()
    }
  }

  /**
   * Вызывает метод this.setNextRibbon() только после того, как все флаги будут установлены
   *
   * Нужно для синхронизации событий, которые вызывает компонент во время жизни
   *
   * Что бы запросить следующую плашку, нужно, что бы произошло несколько событий:
   * 1. Текущая плашка появилась на экране и завершила анимацию появления (SYMAPHORE_FLAGS.FULL_SHOWED_CURRENT)
   * 2. Текст плашки отобразился полностью (например: бегущая строка завершила прокрутку) (SYMAPHORE_FLAGS.AFTER_ENTER_CURRENT)
   * 3. Предыдущая плашка была удалена из DOM (если плашка первая, то это событие не требуется) (SYMAPHORE_FLAGS.UNMOUNTED_PREVIOUS)
   *
   * Только после того, как все эти события наступили, происходит запрос следующей плашки
   */
  createNextRibbonSymaphore() {
    let symaphore = null

    const setFlag = (flag) => {
      return symaphore && symaphore(flag)
    }

    const reset = () => {
      // Функция, которая будет выполнена, при условии получения всех флагов
      const fn = this.setNextRibbon.bind(this)

      // Флаги, которые нужно установить, что бы соблюсти условие запуска функции
      const flags = [
        FULL_SHOWED_CURRENT, // Когда текст плашки отобразился полностью
        AFTER_ENTER_CURRENT, // Когда анимация появления плашки завершилась
        this.previousId.get() && UNMOUNTED_PREVIOUS, // Когда, предыдущая плашка была удалена из DOM
      ].filter(Boolean)

      symaphore = this.gaApp.services.ticker.symaphore.createAsyncSymaphore(
        fn,
        flags,
      )
    }

    return {
      setFlag,
      reset,
    }
  }

  isPrevious(ribbon) {
    return ribbon.id === this.previousId.get()
  }

  isCurrent(ribbon) {
    return ribbon.id === this.currentId.get()
  }

  get currentId() {
    return {
      set: (value) => (this.gaApp.stores.ticker.main.ribbon.currentId = value),
      get: () => this.gaApp.stores.ticker.main.ribbon.currentId,
    }
  }

  get previousId() {
    return {
      set: (value) => (this.gaApp.stores.ticker.main.ribbon.previousId = value),
      get: () => this.gaApp.stores.ticker.main.ribbon.previousId,
    }
  }

  reset() {
    this.currentId.set(null)
    this.previousId.set(null)

    this.duration = null
    this.iterator = null
    this.nextRibbonSymaphore = null
    this.gaApp.services.ticker.iterator.reset()
  }
}
