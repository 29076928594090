import { queryHighlight } from '@ga/utils'

import { findQueryPosition } from '../components/organisms/searchable-alphabet-list/scripts/utils'
import { BRANDS_SEARCH_ABORT_KEY } from '../constants'

export class SearchService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  // Для того, чтобы выделить жирным совпадение поискового запроса в названии бренда,
  // нужно учесть возможность наличия апострофа и т.н. алиасов букв вроде è, é и т.д.
  buildBrand(option, b) {
    const startPos = option.labelPlain.indexOf(
      this.gaApp.stores.brands.search.queryPlain,
    )
    const querySize = this.gaApp.stores.brands.search.queryPlain.length

    const apostropheIndex = option.label.search(/['`’]/)

    const { start, size } = findQueryPosition(
      this.gaApp.stores.brands.search.queryPlain,
      startPos,
      querySize,
      apostropheIndex,
    )

    const highlighted = option.label.substr(start, size)

    return {
      ...option,
      label: queryHighlight(option.label, highlighted, {
        htmlTag: 'mark',
      }),
    }
  }

  /**
   * Возвращает рузльтаты поиска
   *
   * @param {string} queryPlain нормализованная строка поиска
   * @param {object} groupped содержит сгруппированные значения брэндом по буквам
   * @param {array} resultFirst результаты поиска, где поисковая фраза входит название брэнда с первого символа
   * @param {array} resultSecond результаты поиска, где поисковая фраза входит название брэнда НЕ с первого символа
   *
   * @returns {object} объект содуржащий результаты поиска
   */
  getSearchResult({ queryPlain, groupped, resultFirst, resultSecond }, b) {
    let first = []
    let second = []
    let entryDirectChar = ''

    // Если введен только один символ, то пытаемся найти группу элементов по этому символу
    const queryUppercase = queryPlain.value.toUpperCase()
    const elements = groupped.value[queryUppercase]

    if (elements) {
      entryDirectChar = queryUppercase
      first = elements
      second = []
    }

    // Иначе билдим результат с учетом всей строки поиска
    if (!elements && queryPlain.value) {
      first = resultFirst.value.map((option) => this.buildBrand(option, b))
      second = resultSecond.value.map((option) => this.buildBrand(option, b))
    }

    return {
      first,
      second,
      entryDirectChar,
    }
  }

  /**
   * Нужно для того, что сформировать результаты поиска
   *
   * @param {object} $event объект, который возвращается из компонента алфивитного списка при поиске
   */
  search($event, b) {
    this.gaApp.stores.brands.search.queryOriginal = $event.query.value
    this.gaApp.stores.brands.search.queryPlain = $event.queryPlain.value

    const { first, second, entryDirectChar } = this.getSearchResult($event, b)

    this.gaApp.stores.brands.search.resultFirst = first
    this.gaApp.stores.brands.search.middleOfAWord = second
    this.gaApp.stores.brands.search.firstCharEntryDirect = entryDirectChar
  }

  searchBrands(parameters) {
    return this.gaApp.services.brands.api.searchBrands(parameters, {
      abortKey: BRANDS_SEARCH_ABORT_KEY,
    })
  }

  setSearchResults(data) {
    const {
      query,
      brandSearch,
      inputLanguageQuery,
      transliteratedLanguageQuery,
    } = data

    const {
      popularBrands,
      inputLanguage,
      transliteratedLanguage,
      middleOfAWord,
    } = brandSearch

    this.gaApp.stores.brands.search.queryOriginal = query
    // совпадение по названию с первого символа
    this.gaApp.stores.brands.search.resultFirst = inputLanguage
    // совпадение по названию с первого символа, НО в транслите
    this.gaApp.stores.brands.search.resultSecond = transliteratedLanguage
    // совпадение по популярным брендам
    this.gaApp.stores.brands.search.popularBrands = popularBrands
    // совпадение по названию НЕ с первого символа
    this.gaApp.stores.brands.search.middleOfAWord = middleOfAWord

    // используем данные для вывода,
    // если оба списка не пустые (inputLanguage, transliteratedLanguage)
    if (
      inputLanguageQuery &&
      inputLanguage?.length &&
      transliteratedLanguage?.length
    ) {
      // первый символ из query
      this.gaApp.stores.brands.search.firstCharEntryDirect =
        inputLanguageQuery.toUpperCase()
      // первый символ из query в транслите
      this.gaApp.stores.brands.search.firstCharEntryDirectTranslit =
        transliteratedLanguageQuery.toUpperCase()
    } else {
      // иначе очищаем поля
      this.gaApp.stores.brands.search.firstCharEntryDirect = ''
      this.gaApp.stores.brands.search.firstCharEntryDirectTranslit = ''
    }
  }

  reset() {
    this.gaApp.stores.brands.search.$reset()
  }
}
