export class PageService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async getMainPageData() {
    // TODO: удалить условие и его содержимое
    // при окончательном переходе на plaid
    if (
      this.gaApp.stores.user.main.isAuthorized &&
      !this.gaApp.features.get('newBrands')
    ) {
      this.gaApp.services.brands.api.getFavorites()
    }

    try {
      await this.gaApp.services.brands.api.getBrands()
    } catch (error) {
      this.gaApp.redirectError({ statusCode: 500, message: error })
    }
  }

  getData(redirect) {
    switch (redirect.pageType) {
      case 'main':
        return this.getMainPageData()

      default:
        return undefined
    }
  }
}
