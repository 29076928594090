import { computed, reactive, unref, useSlots } from 'vue'

export const useSlot = (product, props, sizes, mods) => {
  const slots = useSlots()

  const has = (slotName) => {
    const slot = slots[slotName]?.()

    // Если передали контент в слот, то выводим слот
    if (slot) {
      return true
    }

    const attrs = unref(product).attributes

    // Если в слот ничего не передали, то проверяем наличие данных для дефолтного контента
    switch (slotName) {
      case 'top-1':
        return attrs && (attrs.colors.count > 1 || attrs.units.count > 1)

      // В этом слоте нет дефолтного контента
      // case 'top-2':

      // В этом слоте нет дефолтного контента
      // case 'top-3':

      case 'bot-1':
        return unref(product).dolyame

      default:
        return false
    }
  }

  const hasSomeTop = computed(() => {
    return ['top-1', 'top-2', 'top-3'].some(has)
  })

  const properties = reactive({
    sizes,
    mods: {
      reversed: unref(props)?.reversed,
      highlight: unref(mods)?.highlight,
    },
  })

  return {
    has,
    hasSomeTop,
    properties,
  }
}
