<template>
  <svg fill="none" stroke="none" viewBox="0 0 126 42">
    <g clip-path="url(#info-skyeng-logo-a)">
      <path
        fill="currentColor"
        stroke="none"
        d="m35.774 24.158.344-.781c.23-.528.644-.666 1.172-.276 1.01.736 2.664 1.287 4.87 1.287 1.884 0 2.688-.482 2.688-1.057 0-.643-.666-.827-1.77-.965l-2.503-.321c-3.01-.39-4.526-1.47-4.526-3.354 0-2.022 1.654-3.63 5.49-3.63 2.136 0 3.515.322 4.41.666.988.39 1.126.712 1.126 1.517v1.033c0 .644-.23.873-.873.873h-.712c-.643 0-.873-.23-.873-.873v-.345c-.413-.138-1.447-.344-2.664-.344-2.113 0-3.148.39-3.148 1.033 0 .483.62.758 1.746.92l2.458.321c3.01.39 4.594 1.287 4.594 3.447 0 2.205-2.09 3.607-5.812 3.607-2.733 0-4.938-.873-5.811-1.677-.321-.3-.367-.69-.206-1.08Z"
      />
      <path
        fill="currentColor"
        stroke="none"
        d="M62.886 26.731h-1.264c-.62 0-.827-.092-1.125-.551l-2.389-3.516c-.344-.482-.597-.597-1.309-.597H54.41v3.79c0 .644-.23.874-.873.874h-.919c-.643 0-.873-.23-.873-.873V14.44h-.85c-.643 0-.873-.23-.873-.873v-.758c0-.644.23-.873.873-.873h2.642c.644 0 .873.23.873.873v6.686h2.137c.712 0 .965-.115 1.309-.598l1.998-3.078c.322-.482.529-.574 1.15-.574h1.263c.689 0 .827.551.436 1.125l-2.229 3.378c-.23.322-.505.666-.78.942.275.23.665.62.826.85l2.803 4.089c.39.551.252 1.102-.437 1.102Z"
      />
      <path
        fill="currentColor"
        stroke="none"
        d="M67.235 28.845v-.758c0-.643.23-.873.873-.873h1.103c.666 0 .987-.138 1.24-.666l.138-.298-5.26-9.948c-.322-.598-.07-1.057.62-1.057h1.195c.62 0 .895.114 1.125.551l2.205 4.526c.505 1.034 1.08 2.229 1.539 3.262.46-1.01.987-2.159 1.493-3.17l2.25-4.618c.207-.436.506-.551 1.126-.551h1.195c.69 0 .942.46.62 1.057l-5.996 11.373c-.689 1.31-1.493 2.044-3.285 2.044h-1.309c-.642 0-.872-.23-.872-.874Z"
      />
      <path
        fill="currentColor"
        stroke="none"
        d="M93.378 20.942v.276c0 .643-.23.873-.873.873h-9.419c.322 1.217 1.539 2.389 4.135 2.389 2.32 0 3.377-.758 3.86-1.103.527-.39.895-.253 1.17.276l.39.758c.207.39.093.689-.253.987-.505.436-2.228 1.517-5.168 1.517-3.951 0-6.707-2.32-6.707-5.95 0-3.562 2.641-5.905 6.431-5.905 3.792 0 6.434 2.32 6.434 5.882Zm-10.292-1.08h7.742c-.299-1.195-1.517-2.32-3.883-2.32s-3.56 1.125-3.859 2.32Z"
      />
      <path
        fill="currentColor"
        stroke="none"
        d="M110.129 20.55v5.308c0 .643-.23.873-.873.873h-.919c-.643 0-.873-.23-.873-.873v-5.239c0-1.86-1.103-2.872-2.964-2.872-1.447 0-3.17.896-4.02 1.838v6.272c0 .643-.23.873-.873.873h-.92c-.643 0-.873-.23-.873-.873v-8.11h-.85c-.643 0-.872-.23-.872-.873v-.758c0-.643.23-.873.873-.873h2.228c.643 0 .873.23.873.873v.644c1.241-1.012 2.918-1.7 4.732-1.7 3.447 0 5.331 2.045 5.331 5.49Z"
      />
      <path
        fill="currentColor"
        stroke="none"
        d="M125.99 16.117V26.87c0 2.389-2.573 3.952-5.858 3.952-3.101 0-4.571-.92-5.238-1.54-.321-.298-.367-.689-.207-1.08l.344-.78c.253-.575.666-.69 1.172-.276.803.643 1.585 1.171 3.905 1.195 2.067 0 3.216-.758 3.216-1.678v-1.102c-.827.528-1.838.873-3.286.873-3.721 0-6.294-2.183-6.294-5.675s2.572-5.698 6.294-5.698c1.608 0 2.665.46 3.378 1.01.183-.39.551-.826 1.011-.826h.689c.644-.001.874.229.874.872Zm-2.665 6.48V18.92c-.643-.782-1.585-1.264-3.285-1.264-2.044 0-3.537 1.172-3.537 3.102s1.493 3.078 3.537 3.078c1.677 0 2.62-.483 3.285-1.24Z"
      />
      <path
        fill="#00C1FF"
        d="M6.29 29.387c-1.934 1.11-3.323.185-3.323-1.46V13.915c0-1.645 1.388-2.57 3.323-1.46l12.046 6.914a1.786 1.786 0 0 1 0 3.103L6.29 29.387Z"
      />
      <path
        fill="#6FE4FF"
        stroke="none"
        d="M16.235 29.387c-1.934 1.11-3.323.185-3.323-1.46V13.915c0-1.645 1.388-2.57 3.323-1.46l11.782 6.763a1.96 1.96 0 0 1 0 3.406l-11.782 6.763Z"
      />
      <mask
        id="info-skyeng-logo-b"
        width="18"
        height="19"
        x="12"
        y="11"
        maskUnits="userSpaceOnUse"
        style="mask-type: alpha"
      >
        <path
          fill="currentColor"
          stroke="none"
          d="M16.235 29.387c-1.934 1.11-3.323.185-3.323-1.46V13.915c0-1.645 1.388-2.57 3.323-1.46l11.782 6.763a1.96 1.96 0 0 1 0 3.406l-11.782 6.763Z"
        />
      </mask>
      <g mask="url(#info-skyeng-logo-b)">
        <path
          fill="#009EE4"
          stroke="none"
          d="M6.29 29.387c-1.934 1.11-3.323.185-3.323-1.46V13.915c0-1.645 1.388-2.57 3.323-1.46l12.046 6.914a1.786 1.786 0 0 1 0 3.103L6.29 29.387Z"
        />
      </g>
    </g>
    <defs>
      <clipPath id="info-skyeng-logo-a">
        <path
          fill="#fff"
          stroke="none"
          d="M0 0h125v42H0z"
          transform="translate(.983)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-info-skyeng-logo',
}
</script>
