import isEmpty from 'lodash/isEmpty'

import { PAGE_TYPE } from '../constant'

export class MainService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  get type() {
    return this.gaApp.stores.plp.main.pageData?.type
  }

  isPageMeta() {
    return Boolean(this.gaApp.stores.plp.main.pageData?.params.meta)
  }

  isPageTypeSearch() {
    return this.type === PAGE_TYPE.SEARCH
  }

  isPageTypeListing() {
    return this.type === PAGE_TYPE.LISTING
  }

  isPageTypeBrandzone() {
    return this.gaApp.router.currentRoute.fullPath.startsWith('/brands')
  }

  // временный метод
  // используется, чтобы отличить брендзону от дочерней категории
  isBrandzoneChildCategory() {
    return !this.gaApp.router.currentRoute.fullPath.match(
      /^\/brands\/([^/]+?)(\/|$|\?)$/,
    )
  }

  setHeaderColorTheme({ category, slots } = {}) {
    switch (true) {
      case isEmpty(slots?.blocks) && Boolean(category?.imageUrl?.url):
        return this.gaApp.services.header.theme.setHeaderTransparentInvertedColorScheme()

      default:
        return this.gaApp.services.header.theme.setHeaderRegularColorScheme()
    }
  }
}
