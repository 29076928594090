export class GtmGa4ProductBaseModel {
  constructor(product) {
    if (!product) {
      throw new Error('product is required')
    }

    const {
      id,
      name,
      brand,
      list,
      price,
      variant,
      position,
      inStock,
      itemListId,
    } = product

    if (variant) {
      this.item_variant = variant
    }

    this.item_category = null
    this.item_name = `${brand} ${name}`
    this.item_id = id
    this.price = price
    this.item_brand = brand
    this.item_list_name = list?.placementPath || list
    this.item_list_id = itemListId || id
    this.index = position
    this.stock = inStock
  }
}
