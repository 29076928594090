import {
  FT_LAST_UPDATE_LOCAL_STORE_KEY,
  FT_UPDATE_TIME,
  STORE_FEATURES_BY_REGION,
  STORE_FEATURES_INITIAL,
} from '../constants'
import { featuresFromPairs } from '../utils'

export class MainService {
  constructor(gaApp) {
    this.gaApp = gaApp
    this.getDate = (date) => gaApp.libs.dateWrapper(date)
  }

  /** Получить значение ФТ */
  get(key) {
    return this.gaApp.stores.featuresToggle.main.features[key]
  }

  /** Задает значения туглов в стору  */
  setFeatures(features) {
    const nextFeatures = {
      ...this.gaApp.stores.featuresToggle.main.features,
      ...features,
    }

    this.gaApp.stores.featuresToggle.main.features = nextFeatures
  }

  /** Задает значения туглов из апи в стору */
  setApiFeatures(value) {
    this.gaApp.stores.featuresToggle.main.apiFeatures = value
  }

  /** Задает значения сегментов в стору */
  setSegments(value) {
    this.gaApp.stores.featuresToggle.main.segments = value || []
  }

  /** Задает значения туглов из локального конфига */
  fillInitialFeatures() {
    this.setFeatures(STORE_FEATURES_INITIAL)
  }

  /** Задает значения туглов из локального конфига конкретной локали */
  fillRegionFeatures() {
    const activeLocale = this.gaApp.config.get('locale.active')
    const storeFeatures = STORE_FEATURES_BY_REGION[activeLocale]

    if (storeFeatures) {
      this.setFeatures(storeFeatures)
    }
  }

  /** Задает значения туглов из локального конфига */
  fillLocalFeatures() {
    this.fillInitialFeatures()
    this.fillRegionFeatures()
  }

  /** Задает значения туглов и сегментов в стору */
  async fillApiFeatures() {
    const { features, segments } =
      await this.gaApp.services.featuresToggle.api.getData()
    const normalizedFeatures = featuresFromPairs(features)

    this.setFeatures(normalizedFeatures)
    this.setApiFeatures(normalizedFeatures)
    this.setSegments(segments)
  }

  /** Получить время последнего обновления ФТ */
  getFTLastUpdate() {
    return this.gaApp.services.app.browserStorage.local.get(
      FT_LAST_UPDATE_LOCAL_STORE_KEY,
    )
  }

  /** Задать время последнего обновления ФТ */
  setFTLastUpdate(value) {
    this.gaApp.services.app.browserStorage.local.set(
      FT_LAST_UPDATE_LOCAL_STORE_KEY,
      value,
    )
  }

  /** Перезапрашивает и обновляет фт и сегменты */
  async refresh(force = false) {
    const lastUpdateDate = this.getDate(this.getFTLastUpdate()).add(
      FT_UPDATE_TIME,
      'm',
    )
    const currentDate = this.getDate(new Date())

    /** Если пришло время обновлять или передан флаг принудительного обновления */
    if (currentDate.isAfter(lastUpdateDate) || force) {
      await this.fillApiFeatures()
      this.setFTLastUpdate(new Date().getTime())
      this.pushSegmentsAndAbFtToGtmDataLayer()
    }
  }

  /** Перезапрашивает и обновляет фт и сегменты, doubleRAF дает дождаться рендра страницы */
  refreshWithRaf(force) {
    this.gaApp.ctx.$doubleRAF(() => {
      this.refresh(force)
    })
  }

  /** Добавляем сегменты и AB ft в gtm dataLayer */
  pushSegmentsAndAbFtToGtmDataLayer() {
    const gtmDataLayerInfo = {
      segments: this.gaApp.stores.featuresToggle.main.segments.join(','),
    }

    /** Если есть ФТ dummyAbFeature и значение его true, добавляем его в dataLayer */
    if (this.get('dummyAbFeature')) {
      gtmDataLayerInfo.dummyAbFeature = true
    }

    window.dataLayer.push(gtmDataLayerInfo)
  }

  /** Отправляем ивент в gtm аналитику если есть dummyAbFeature и он включен */
  sendAnalyticsEventIfIssetAbFt() {
    if (this.get('dummyAbFeature')) {
      this.gaApp.analytics.modules.featuresToggle.onIssetAbFt()
    }
  }

  /** Подписываемся на ивенты шины */
  subscribeToEventBus() {
    /** При переходе по роутам, пытаемся обновить фича туглы */
    this.gaApp.eventBus.subscribe('module/app/router/page-created', () => {
      this.refreshWithRaf()
    })

    /** При логине обновляем фича туглы */
    this.gaApp.eventBus.subscribe('module/auth/login', () => {
      this.refreshWithRaf(true)
    })

    /**
     * При создании gtm dataLayer добавляем в него данные сегметов и AB ft
     * пытаемся отправить ивент наличия AB ft
     */
    this.gaApp.eventBus.subscribe('layer/analitycs/initDataLayer', () => {
      this.pushSegmentsAndAbFtToGtmDataLayer()
      this.sendAnalyticsEventIfIssetAbFt()
    })
  }

  async init() {
    if (this.gaApp.config.get('devConfig')) {
      this.fillLocalFeatures()
    } else {
      await this.fillApiFeatures()
      this.setFTLastUpdate(new Date().getTime())

      this.subscribeToEventBus()
    }
  }
}
