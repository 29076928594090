import { ACTIONS } from '../../../constants'
import { GtmBaseModel } from '../../index'

export class GtmGa4ListingStreamCardClickModel extends GtmBaseModel {
  constructor(data) {
    super(ACTIONS.USER_INTERACTION.GA4)

    const { eventCategory, actionType, streamId, position, state, filters } =
      data

    this.event_category = eventCategory
    this.action_type = actionType

    this.stream_id = streamId
    this.position = position
    this.state = state

    if (filters) {
      this.filters = filters
    }
  }
}
