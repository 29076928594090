export class MainService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async logout() {
    if (this.gaApp.stores.customer.main.logoutPending) return undefined

    this.gaApp.stores.customer.main.logoutPending = true

    try {
      await this.gaApp.services.auth.main.logoutWithClearData()

      window.location.assign('/')
    } catch {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('customer.error.logout'),
      )
    } finally {
      this.gaApp.stores.customer.main.logoutPending = false
    }
  }
}
