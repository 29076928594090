const getImageAttributes = (product) => {
  const { productType = '', brand = '', name = '', attributes } = product

  const title = attributes?.colors?.name || ''
  const alt = `${productType} ${brand} ${name} ${title}`

  return {
    alt,
    title,
    itemprop: 'image',
  }
}

export const getSeoEnrichedMedia = (product) => {
  const images = product.imageUrls ?? []

  return images.map((image) => ({
    image,
    meta: getImageAttributes(product) ?? false,
  }))
}
