export class GtmGa4ProductBaseModel {
  constructor(product) {
    this.item_name = product.name
    this.item_id = product.sku
    this.price = product.price
    this.item_brand = product.brand
    this.item_category = product.category
    this.item_variant = product.variant
  }
}
