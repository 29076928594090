export { default as GaModuleApp } from './app/index.vue'
export { default as GaModuleAppError } from './error/index.vue'
export { default as GaModuleAppLangSwitcherModal } from './lang-switcher-modal/lang-switcher-modal.vue'
export { GaLangSwitcher } from './lang-switcher'
export { GaMobileAppSchema } from './mobile-app-schema'

export const GaModuleAppCookieModal = () =>
  import(
    /* webpackChunkName: 'modules/app/cookie-modal' */
    './cookie-modal/cookie-modal.vue'
  )

export const GaModuleAppCookiePanel = () =>
  import(
    /* webpackChunkName: 'modules/app/cookie-panel' */
    './cookie-panel/cookie-panel.vue'
  )

export const GaModuleAppCookieSettingsModal = () =>
  import(
    /* webpackChunkName: 'modules/app/cookie-settings-modal' */
    './cookie-settings-modal/cookie-settings-modal.vue'
  )

export const GaModuleAppCookieNotificationPanel = () =>
  import(
    /* webpackChunkName: 'modules/app/cookie-notification-panel' */
    './cookie-notification-panel/cookie-notification-panel.vue'
  )
