import { GaAnalyticsBaseModel } from '../ga-analytics-base-model/ga-analytics-base-model'

export class GaAnalyticsProductClickModel extends GaAnalyticsBaseModel {
  constructor(product) {
    super('product')

    this.item = {
      ...product.analyticData,
      itemId: product.id,
      itemListName: product.list,
      searchTerm: product.searchTerm,
    }

    this.debug = {
      key: 'plp-item-click',
      data: product,
    }
  }
}
