export const getStateInitial = () => ({
  geolocationPending: false,

  confirmCountry: {
    newCode: null,
    isOpen: false,
  },

  isOpened: false,
  isPending: false,
})

export const state = () => {
  return getStateInitial()
}
