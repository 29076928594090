export class DigiAnalyticsWidgetBaseModel {
  constructor(data) {
    this.data = {
      strategy: this.generateStrategy(data.placement),
      pageProducts: data.placement.products.map((placement) => {
        return placement.itemId
      }),
    }
  }

  generateStrategy(placement) {
    return [placement.name, placement.id, placement.strategyName].join('|')
  }
}
