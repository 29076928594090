import { ACTIONS } from '../../../constants'
import { GtmBaseModel } from '../../index'

export class GtmGa4StreamRecommendCardShowModel extends GtmBaseModel {
  constructor(data) {
    super(ACTIONS.USER_INTERACTION.GA4)

    const { eventCategory, actionType, position, streamId, streamParentId } =
      data

    this.event_category = eventCategory
    this.action_type = actionType

    this.position = position
    this.streamId = streamId
    this.streamParentId = streamParentId
  }
}
