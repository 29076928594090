import { PLP_TYPE, REFERER_TYPES, TYPE } from '../../constants'
import { buildVariant } from '../../helpers'

export class BaseController {
  constructor(gaApp, core) {
    this.gaApp = gaApp

    this.core = core

    this.metrics = {
      [TYPE.DIGI]: this.core.all?.digiAnalytics,
      [TYPE.GAA]: this.core.all?.gaAnalytics,
      [TYPE.GTM_4]: this.core.all?.gtm,
      [TYPE.GTM_UA]: this.core.all?.gtm,
      [TYPE.GTM_UA_SYSTEM]: this.core.all?.gtm,
      [TYPE.MINDBOX]: this.core.all?.mindbox,
    }

    this.getCurrencyService = () => this.gaApp.services.app.currency

    this.getDataForAnalytics = () => {
      const user = this.gaApp.stores.user.main.data
      const pages = this.gaApp.stores.customer.favorites.pages

      return {
        pages,
        user,
      }
    }
  }

  send(Builder, data) {
    const { country } = this.gaApp.i18n.locale
    new Builder(data, country).build().forEach((data, key) => {
      const metric = this.metrics[key]

      if (metric && metric.send) {
        metric.send(data)
      }
    })
  }

  getProductPosition(product) {
    return (
      this.gaApp.stores.search.main.searchResult.products.findIndex(
        (item) => item.itemId === product.itemId,
      ) + 1
    )
  }

  getPrice(price) {
    return {
      amount: this.getCurrencyService().getNominal(price.actual),
      currency: price.actual.currency,
    }
  }

  getSearchTerm() {
    return this.gaApp.stores.search.main.searchResult.query
  }

  getProductData(product) {
    const user = this.gaApp.stores.user.main.data

    const userId = user?.id ?? null

    const {
      itemId,
      currentView,
      name,
      brand,
      inStock,
      attributes,
      price,
      addToCart,
    } = product

    const { amount, currency } = this.getPrice(price)

    return {
      name,
      brand,
      inStock,
      breadcrumbs: null,
      addToCart,
      userId,
      id: itemId,
      price: amount,
      currency,
      plpType: currentView || PLP_TYPE.S,
      searchTerm: this.getSearchTerm(),
      categoryId: null,
      category: null,
      variant: buildVariant(attributes),

      list: REFERER_TYPES.SEARCH_HINTS,
      position: this.getProductPosition(product),
    }
  }
}
