import { TYPE } from '../analytics/constants'

import { PAGE_TYPE } from './../constants/pages'

export class PageService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  getData(redirect) {
    try {
      switch (redirect.pageType) {
        case PAGE_TYPE.MAIN:
          /**
           * если включен фт новых урлов, но идет попытка открыть старый
           * показываем 404
           */
          if (
            this.gaApp.route.fullPath.includes('customer') &&
            this.gaApp.features.get('promoUrlKey')
          ) {
            return this.gaApp.redirectError({ statusCode: 404 })
          }
          return this.gaApp.services.promo.main.loadPromoInfo(redirect.id)
        case PAGE_TYPE.LISTING_PROMO:
        case PAGE_TYPE.LISTING_CLIENT_DAYS:
          return this.gaApp.services.promo.listing.loadListing(
            redirect.pageType,
          )
        default:
          return undefined
      }
    } catch (error) {
      switch (redirect.pageType) {
        case PAGE_TYPE.LISTING_PROMO:
        case PAGE_TYPE.LISTING_CLIENT_DAYS:
          this.gaApp.redirectError({ statusCode: 500, message: error.message })
          break

        case PAGE_TYPE.MAIN:
          if (error.statusCode >= 500) {
            this.gaApp.redirectError({
              statusCode: 500,
              message: error.message,
            })
          } else {
            this.gaApp.redirectError({
              statusCode: error.statusCode,
              message: error.message,
            })
          }
          break

        default:
      }
    }
  }

  // Запрос данных из внутреннего сервиса аналитики
  async getInitialAnalytics() {
    const { path, hash } = this.gaApp.route

    const data = await this.gaApp.analytics.modules.promo.getAnalyticByUrl({
      url: path.replace(hash, ''),
    })

    const gaAnalytics = data[TYPE.GAA] ? data[TYPE.GAA] : null

    return this.gaApp.services.promo.main.setAnalyticData(gaAnalytics)
  }
}
