import { TYPE } from '../../constants'
import {
  GtmGa4ProductViewModel,
  GtmGaUaProductViewModel,
  GtmGaUaProductViewResetModel,
} from '../../models'
import { BaseBuilder } from '../base/base.builder'

export class ProductViewBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map([])

    if (data.chunk.length) {
      models.set(TYPE.GTM_UA_SYSTEM, GtmGaUaProductViewResetModel)
      models.set(TYPE.GTM_UA, GtmGaUaProductViewModel)
      models.set(TYPE.GTM_4, GtmGa4ProductViewModel)
    }

    super(models, data)
  }
}
