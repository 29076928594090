import isEqual from 'lodash/isEqual'

export class FiltersService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async requestFilters() {
    try {
      this.gaApp.stores.filters.main.setFiltersPending(true)

      const filtersSelected = this.gaApp.stores.filters.main.filtersSelected

      this.gaApp.stores.filters.main.setFiltersHash({
        filters: filtersSelected,
      })

      const {
        id: cityId,
        cityDistrict,
        geoPolygons,
      } = this.gaApp.services.location.main.getDeliveryAddress()

      const response = await this.gaApp.services.favorites.api.getFilters({
        filters: filtersSelected,
        cityId,
        cityDistrict,
        geoPolygons,
      })

      this.setFiltersData(response.data)

      return response
    } catch {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('favorites.error.filters'),
      )

      this.gaApp.stores.filters.main.setFiltersPending(false)
    }
  }

  setFiltersData(data) {
    const { analytics, filters, productsCount } = data

    const filtersNormalized = filters.map((filter) => ({
      ...filter,
    }))

    this.gaApp.stores.filters.main.setFilters(filtersNormalized)
    this.gaApp.stores.filters.main.setCountProductsFiltered(productsCount)
    this.gaApp.stores.filters.main.setAnalytics(analytics)

    this.gaApp.stores.filters.main.setFiltersPending(false)
  }

  areHashesEqual() {
    const { filtersHash } = this.gaApp.stores.filters.main
    const { plp } = this.gaApp.stores.favorites

    return isEqual(filtersHash, plp.favoritesHash)
  }

  /**
   * Запрос списка продуктов при изменении селекта
   */
  async refreshFavorites({ select }) {
    this.gaApp.stores.favorites.plp.pages = []

    await Promise.all([
      this.gaApp.services.filters.main.pageNumber.updateFilter(1),

      this.gaApp.services.filters.main.updateFilter({
        filter: { ...select },
      }),

      this.gaApp.services.favorites.plp.fetchFavoriteProducts(),
    ])
  }
}
