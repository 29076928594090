import { TYPE } from '../../constants'
import { buildBreadcrumbs } from '../../helpers'

export class BaseController {
  constructor(gaApp, core) {
    this.gaApp = gaApp
    this.core = core

    this.metrics = {
      [TYPE.GTM_UA]: this.core.all?.gtm,
      [TYPE.GTM_4]: this.core.all?.gtm,
      [TYPE.MINDBOX]: this.core.all?.mindbox,
      [TYPE.GDESLON]: this.core.all?.gdeslon,
      [TYPE.GAA]: this.core.all?.gaAnalytics,
      [TYPE.ADVCAKE]: this.core.all?.advcake,
      [TYPE.DIGI]: this.core.all?.digiAnalytics,
    }

    this.getCurrencyService = () => this.gaApp.services.app.currency
  }

  getPrice(price) {
    return {
      amount: this.getCurrencyService().getNominal(price.actual),
      currency: price.actual.currency,
    }
  }

  getBreadcrumbsData(breadcrumbs) {
    if (Array.isArray(breadcrumbs)) {
      return {
        path: buildBreadcrumbs(breadcrumbs),
        lastHref: breadcrumbs[breadcrumbs.length - 1]?.href,
      }
    }

    return {
      path: null,
      lastHref: null,
    }
  }

  getBrandTitleData(brand, product) {
    return {
      url: brand.brandHref,
      itemListName: `${brand.brand} ${brand.name}`,
    }
  }

  getItemListName(view = 'main') {
    return this.gaApp.stores.pdp[view].analyticsData?.itemListName || null
  }

  getSearchTerm(view = 'main') {
    return this.gaApp.stores.pdp[view].analyticsData?.searchTerm || null
  }

  getItemListId(view = 'main') {
    return this.gaApp.stores.pdp[view].analyticsData?.itemListId || null
  }

  getItemIndex(view = 'main') {
    return this.gaApp.stores.pdp[view].analyticsData?.index || null
  }

  getProductData(data) {
    const { view, ...additionalProps } = data

    if (!view) {
      throw new Error('view is required')
    }
    const productCommon = this.gaApp.services.pdp[view].productData
    const productSelected = this.gaApp.services.pdp[view].productSelected

    const { amount, currency } = this.getPrice(productSelected.price)
    return {
      view,
      brand: productCommon.brand,
      name: productCommon.name,
      amount,
      currency,
      id: productSelected.id,
      breadcrumbs: productCommon.breadcrumbs,
      attributesSelected: productSelected.attributesValue,
      inStock: productSelected.inStock,
      isEyezonEnabled: !!productSelected?.eyezonButtonId,
      itemListName: this.getItemListName(view),
      itemListId: this.getItemListId(view),
      index: this.getItemIndex(view),
      shortVideoCount: productSelected.shortVideos?.length || 0,
      reviewStatistics:
        this.gaApp.services.review.main?.getReviewStatistics?.(),
      searchTerm: this.getSearchTerm(view),
      productType: productCommon.productType,
      url: productSelected.url,
      hasCoupon: !!productSelected.coupon,
      ...additionalProps,
    }
  }

  /**
   * Отправляем аналитические данные из билдеров в соответствующие метрики.
   */
  send(Builders, data) {
    const { country } = this.gaApp.i18n.locale
    new Builders(data, country).build().forEach((data, key) => {
      const metric = this.metrics[key]

      if (metric && metric.send) {
        metric.send(data)
      }
    })
  }

  /**
   * Делаем запрос за аналитическми данными из билдеров в соответствующие метрики.
   */
  get(Builder, data) {
    const requests = [...new Builder(data).build()].flatMap(([key, data]) => {
      const metric = this.metrics[key]

      return metric.get
        ? [metric.get(data).then(({ data }) => ({ key, data: data.data }))]
        : []
    })

    return Promise.allSettled(requests)
  }
}
