// TODO: Разбить содержимое на разные файлы

import random from 'lodash/random'

import { KEYS, STEPS } from '../constants/digital'

export const initFormData = () => ({
  [STEPS.DESIGN]: {
    [KEYS.IMG]: '',
    [KEYS.COLOR]: '',
  },
  [STEPS.PRICE]: {
    [KEYS.AMOUNT]: '',
  },
  [STEPS.RECIPIENT]: {
    [KEYS.PHONE]: '',
    [KEYS.PHONE_SENDER]: '',
    [KEYS.RECIPIENT]: '',
    [KEYS.TEXT]: '',
    [KEYS.SENDER]: '',
  },
  [STEPS.DELIVERY]: {
    [KEYS.DATE]: '',
    [KEYS.TIMEZONE]: '',
  },
  [STEPS.PAYMENT]: {
    [KEYS.PHONE_RECEIPT]: '',
  },
})

export const initStepItems = () => [
  'design',
  'price',
  'recipient',
  'delivery',
  'payment',
]

export const isValidIdOptions = (activeId, options = []) =>
  options.some((option) => option.id === activeId)

export const getTerm = ($gaApp, { termsPath = 'giftCards.terms' } = {}) => {
  const limitationKey = $gaApp.features.get('giftCardsUnlimitedTerm')
    ? 'unlimited'
    : 'limited'
  const key = `${termsPath}.${limitationKey}`

  return $gaApp.i18n.t(key)
}

export const toOpenGraphMeta = (openGraphObject) =>
  Object.entries(openGraphObject).map(([propertyAlias, content]) => {
    const property = `og:${propertyAlias}`

    return {
      property,
      hid: property,
      content,
    }
  })

export const buildPlasticLink = (t, isNewLink = false) => {
  const key = isNewLink ? 'linkNewPpk' : 'link'

  return t(`giftCards.nav.cards.plastic.${key}`)
}

/**
 * Возвращает позицию из центра со случайным смещением
 */
export const randomCenterPosition = () => `calc(50% + ${random(-75, 75)}px)`
