import isEqual from 'lodash/isEqual'

import {
  ARTICLES_BASE_URL,
  BACK_FILTER_TYPE,
  LISTING_PAGE_TYPE,
  URL_FILTER_TYPE,
} from '../constants'
import { LISTING_PAGE_WEB_VIEW_PARAMS } from '../constants/web-view'

export class FiltersService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  // Грузим все фильтры по апи, затем выделяем активные фильтры в URL, и проставляем в хранилище
  async loadFilters(query = {}) {
    const apiKey =
      this.gaApp.stores.articles.list.type === LISTING_PAGE_TYPE.ARTICLES
        ? 'api'
        : 'apiStreams'

    const activeFiltersFromQuery = this.getActiveFiltersFromQuery(query)

    // В зависимости от типа страницы грузим либо фильтры статей, либо фильтры стримов
    const { filters } =
      await this.gaApp.services.articles[apiKey].fetchFilters()

    this.gaApp.stores.articles.list.filters = (filters || []).map((filter) => ({
      ...filter,
      active: this.isActiveFilter(filter, activeFiltersFromQuery),
    }))
  }

  getActiveFiltersFromQuery(query = {}) {
    const activeFilters = []

    Object.entries(query).forEach(([type, filtersStr]) => {
      if (!filtersStr) {
        return
      }

      const filtersNames = filtersStr.split(',')
      filtersNames.forEach((filterName) => {
        activeFilters.push({
          key: filterName,
          type: this.mapUrlFilterToBackFilter(type),
        })
      })
    })

    return activeFilters
  }

  async toggleFilter(filter) {
    const filters = this.gaApp.stores.articles.list.filters
    const filterToToggle = filters.find((currentFilter) =>
      this.isTheSameFilter(currentFilter, filter),
    )

    if (filterToToggle) {
      filterToToggle.active = !filterToToggle.active
    }

    await this.updateFilters(this.gaApp.stores.articles.list.activeFilters)
  }

  async updateFilters(filters = []) {
    await this.updateFiltersURL(filters)
    this.updateFiltersInStore()
  }

  async resetFilters() {
    await this.gaApp.services.articles.list.clearPages()
    await this.updateFilters()
  }

  updateFiltersInStore() {
    const query = this.gaApp.route.query
    const filters = this.gaApp.stores.articles.list.filters

    const activeFiltersFromQuery = this.getActiveFiltersFromQuery(query)

    this.gaApp.stores.articles.list.filters = filters.map((filter) => ({
      ...filter,
      active: this.isActiveFilter(filter, activeFiltersFromQuery),
    }))
  }

  updateFiltersURL(filters = []) {
    let query = this.buildFiltersForQuery(filters)

    if (this.gaApp.isWebview) {
      query = {
        ...query,
        ...LISTING_PAGE_WEB_VIEW_PARAMS,
      }
    }

    if (!isEqual(this.gaApp.route.query, query)) {
      return this.gaApp.router.replace({ query })
    }
  }

  // Создание объекта фильтров для дальнейшей вставки в URL
  buildFiltersForQuery(filters = []) {
    const filtersObj = this.buildFiltersForUrl(filters)

    for (const key in filtersObj) {
      filtersObj[key] = filtersObj[key].join(',')
    }

    return filtersObj
  }

  // Создание строки фильтров для вставки в URL
  buildFiltersForUrl(filters = []) {
    return filters.reduce(
      (acc, filter) => this.setUrlFilterToAcc(acc, filter),
      {},
    )
  }

  // Создание строки фильтров для передачи в апи
  buildFiltersForRequest(filters = []) {
    return filters.reduce(
      (acc, filter) => this.setApiFiltersToAcc(acc, filter),
      {},
    )
  }

  // Построение объекта фильтров для передачи в АПИ, в acc копим тип фильтра и его значения
  setApiFiltersToAcc(acc, filter) {
    return this.setFiltersToAcc(acc, filter)
  }

  // Построение объекта фильтров для строки запроса, в acc копим тип фильтра и его значения
  setUrlFilterToAcc(acc, filter) {
    return this.setFiltersToAcc(acc, filter, this.mapBackFilterToUrlFilter)
  }

  setFiltersToAcc(acc, filter, mapper) {
    const { key, type: filterType } = filter

    const type = mapper ? mapper(filterType) : filterType

    if (!acc[type]) {
      acc[type] = []
    }

    acc[type].push(key)

    return acc
  }

  isActiveFilter(currentFilter, activeFiltersFromQuery) {
    return !!activeFiltersFromQuery.find((activeFilter) => {
      return this.isTheSameFilter(activeFilter, currentFilter)
    })
  }

  mapUrlFilterToBackFilter(urlFilterType) {
    switch (urlFilterType) {
      case URL_FILTER_TYPE.rubric:
        return BACK_FILTER_TYPE.rubric
      case URL_FILTER_TYPE.tag:
        return BACK_FILTER_TYPE.tag
    }
  }

  mapBackFilterToUrlFilter(urlFilterType) {
    switch (urlFilterType) {
      case BACK_FILTER_TYPE.rubric:
        return URL_FILTER_TYPE.rubric
      case BACK_FILTER_TYPE.tag:
        return URL_FILTER_TYPE.tag
    }
  }

  isTheSameFilter(filterA, filterB) {
    return filterA.key === filterB.key && filterA.type === filterB.type
  }

  getFiltersUrlString(filters) {
    const filtersQuery = Object.entries(filters)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    return `${ARTICLES_BASE_URL}?${filtersQuery}`
  }
}
