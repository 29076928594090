import isEmpty from 'lodash/isEmpty'

export class MainRepository {
  constructor(gaApp, endpoints) {
    this.gaApp = gaApp

    this.endpoints = gaApp.api.endpoints(endpoints)
  }

  /**
   * Запрос за категорией
   */
  async getCategory(parameters = {}) {
    const params = { ...parameters }
    const { url } = this.endpoints.category()

    try {
      const response = await this.gaApp.api.request().get(url, {
        params,
      })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  /**
   * Запрос за фильтрами
   */
  async fetchFilters(data = {}, pageType) {
    const { url } = this.endpoints[`${pageType}Filters`]()

    try {
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  /**
   * Запрос с повышенной версией api (v4) за фильтрами
   */
  async fetchFiltersV4(data = {}, pageType) {
    const { url } = this.endpoints[`${pageType}FiltersV4`]()

    try {
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  /**
   * Запрос за продуктами
   */
  async fetchProducts(data = {}, pageType) {
    const { url } = this.endpoints[`${pageType}Products`]()

    try {
      let response

      if (pageType === 'listing' && isEmpty(data.filters)) {
        response = await this.gaApp.api.request().get(url, { params: data })
      } else {
        response = await this.gaApp.api.request().post(url, data)
      }

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  /**
   * Запрос с повышенной версией api (v4) за продуктами
   */
  async fetchProductsV4(data = {}, pageType) {
    const { url } = this.endpoints[`${pageType}ProductsV4`]()

    try {
      let response

      if (pageType === 'listing' && isEmpty(data.filters)) {
        response = await this.gaApp.api.request().get(url, { params: data })
      } else {
        response = await this.gaApp.api.request().post(url, data)
      }

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async fetchProductsActual(data = {}) {
    const { url } = this.endpoints.actual()

    try {
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  /**
   * Запрос за блоками брендзоны для plp
   */
  async getSlots(params = {}) {
    const { url } = this.endpoints.slots()

    try {
      const response = await this.gaApp.api.request().get(url, { params })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }
}
