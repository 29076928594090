export const PROMOTION_QUERY_KEY = {
  SHOPS: 'shops',
  LIMIT: 'limit',
  PAGE: 'page',
}

export const URL_PAGE_KEY = 'p'

export const PROMOTION_TYPE = {
  CLIENT_DAYS: 1,
  PROMO: 2,
}

export const PROMOTION_INFO_BASE_PATH = '/customer/promo/view/id'
export const PROMOTION_INFO_BASE_PATH_WITH_SLUG = '/promo'

export const PROMOTION_PATH = {
  CLIENT_DAYS: '/clientday',
  PROMO: '/promo',
  INFO: `${PROMOTION_INFO_BASE_PATH}/:id`,
  INFO_WITH_SLUG: `${PROMOTION_INFO_BASE_PATH_WITH_SLUG}/:id`,
}

export const ALL_STORES_KEY = 'all-stores'

export const FILTER_KEY = {
  STORE: 'store',
  CITY: 'city',
}
