import { filtersStore as filters } from './filters'
import { listingStore as listing } from './listing'
import { mainStore as main } from './main'
import { pageStore as page } from './page'

export const stores = (gaApp) => {
  return {
    main: main(gaApp),
    listing: listing(gaApp),
    page: page(gaApp),
    filters: filters(gaApp),
  }
}
