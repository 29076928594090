export class GtmGa4ProductBaseModel {
  constructor(product) {
    if (!product) {
      throw new Error('product is required')
    }

    const {
      id,
      name,
      brand,
      list,
      price,
      variant,
      breadcrumbs,
      position,
      listId,
      reviews,
      plpType,
      inStock,
      analyticsData,
    } = product

    if (breadcrumbs) {
      breadcrumbs.slice(1).forEach((category, index) => {
        if (index) {
          this[`item_category${index + 1}`] = category.text
        } else {
          this.item_category = category.text
        }
      })
    }

    if (variant) {
      this.item_variant = variant
    }

    this.item_name = `${brand} ${name}`
    this.item_id = id
    this.price = price
    this.item_brand = brand
    this.item_list_id = listId
    this.item_list_name = list
    this.index = position
    this.plp_type = plpType
    this.stock = inStock

    if (reviews) {
      const { rating, reviewsCount } = reviews

      if (rating) this.rating = rating
      if (reviewsCount) this.reviews = reviewsCount
    }

    if (analyticsData) {
      const { creativeName, creativeSlot, promotionName } = analyticsData

      if (creativeName) this.creative_name = creativeName
      if (creativeSlot) this.creative_slot = creativeSlot
      if (promotionName) this.promotion_name = promotionName
    }
  }
}
