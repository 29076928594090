import { defineStore, getActivePinia } from 'pinia'

import { state } from './state'

export const skyengPageStore = () => {
  const init = defineStore('infoSkyeng', {
    state,
  })

  return init(getActivePinia())
}
