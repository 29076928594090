import { TYPE } from '../../constants'
import {
  GaAnalyticsProductModel,
  GtmGa4ProductClickModel,
  GtmGaUaProductClickModel,
} from '../../models'
import { BaseBuilder } from '../base/base.builder'

export class ProductClickBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map([
      [TYPE.GTM_UA, GtmGaUaProductClickModel],
      [TYPE.GTM_4, GtmGa4ProductClickModel],
      [TYPE.GAA, GaAnalyticsProductModel],
    ])
    super(models, data)
  }
}
