import chunk from 'lodash/chunk'

export class ViewedProductsService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  setViewed = (data, componentId) => {
    this.gaApp.stores.placements.main.viewedProducts[componentId].products.push(
      data,
    )
    this.gaApp.stores.placements.main.viewedProducts[componentId].addedProducts[
      data.itemId
    ] = true
  }

  getLastChunk = (data, componentId, chunkSize) => {
    const chunks = chunk(
      this.gaApp.stores.placements.main.viewedProducts[componentId].products,
      chunkSize,
    )

    const lastChunk = chunks[chunks.length - 1]

    return lastChunk?.length === chunkSize ? lastChunk : []
  }

  getAllProducts = (componentId) => {
    return this.gaApp.stores.placements.main.viewedProducts[componentId]
      .products
  }

  clearProducts = (componentId) => {
    delete this.gaApp.stores.placements.main.viewedProducts[componentId]
  }

  getViewItem = (componentId, data) => {
    return this.gaApp.stores.placements.main.viewedProducts[componentId]
      .addedProducts[data.itemId]
  }

  setViewItem = (componentId, type) => {
    this.gaApp.stores.placements.main.viewedProducts[componentId] = {}
    this.gaApp.stores.placements.main.viewedProducts[componentId].products = []
    this.gaApp.stores.placements.main.viewedProducts[
      componentId
    ].addedProducts = {}
  }
}
