import { cmsPageStore } from './cms'
import { contactsPageStore } from './contacts'
import { launchQaStore } from './launch-qa'
import { lrpEffaclarStore } from './lrp-effaclar'
import { skyengPageStore } from './skyeng'

export const stores = (gaApp) => {
  return {
    cms: cmsPageStore(gaApp),
    contacts: contactsPageStore(gaApp),
    launchQa: launchQaStore(gaApp),
    lrpEffaclar: lrpEffaclarStore(gaApp),
    skyeng: skyengPageStore(gaApp),
  }
}
