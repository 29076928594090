export class ModalService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  resetState() {
    this.gaApp.stores.location.modal.$reset()
  }

  open() {
    this.gaApp.stores.location.modal.isOpened = true
  }

  close() {
    this.gaApp.stores.location.modal.isOpened = false
  }

  pendingStart() {
    this.gaApp.stores.location.modal.isPending = true
  }

  pendingStop() {
    this.gaApp.stores.location.modal.isPending = false
  }

  setGeolocationPending(value) {
    this.gaApp.stores.location.modal.geolocationPending = value
  }

  resetGeolocation() {
    this.gaApp.stores.location.modal.resetProp('geolocation')
  }

  resetConfirmCountry() {
    this.gaApp.stores.location.modal.resetProp('confirmCountry')
  }

  redirectToCountry() {
    const code = this.gaApp.stores.location.modal.confirmCountry.newCode
    const storeContent = this.gaApp.services.app.main.getStoreContent(code)
    const storeDomain = storeContent?.storeDomain || '/'

    window.location.href = storeDomain
  }

  openConfirmCountry(countryCode) {
    this.gaApp.stores.location.modal.confirmCountry.newCode = countryCode
    this.gaApp.stores.location.modal.confirmCountry.isOpen = true
  }

  async setLocation(location) {
    if (this.gaApp.stores.app.common.toggle.addressBlockEnabled) {
      this.gaApp.stores.location.specify.locationTemp = location
      this.close()
      return
    }

    this.pendingStart()

    await this.gaApp.services.location.api.updateData(location.id)

    this.pendingStop()

    this.close()
  }

  async browserDetectionGeolocation() {
    try {
      const { latitude, longitude } =
        await this.gaApp.services.location.geolocation.browserDetection()

      if (latitude && longitude) {
        const geolocation =
          await this.gaApp.services.location.api.getGeolocation({
            latitude,
            longitude,
          })

        if (geolocation) {
          return geolocation
        }
      }
    } catch (error) {
      console.log(error)
    }

    return null
  }
}
