import { useErrors, useManualErrors, useProxy } from '../composables'
import { buildVuelidate } from '../utils'

/**
 * @typedef {import('vue').Ref<T>} Ref
 * @template T
 */

/**
 * @typedef {object} UseValidationOptions
 * @property {boolean} manualErrors включает функцию добавления собственных ошибок (по-умолчанию `true`)
 */

/**
 * Создает методы для удобной работы с [Vuelidate](https://vuelidate-next.netlify.app/)
 * @param {Ref<object> | object} rules правила валидации Vuelidate
 * @param {Ref<object> | object} state состояние, которое необходимо валидировать
 * @param {UseValidationOptions} options настройки composable
 */
export const useValidation = (rules, state, { manualErrors = true } = {}) => {
  const {
    addManualError,
    removeAllManualErrors,
    removeManualError,
    unifiedRules,
  } = manualErrors ? useManualErrors(rules) : { unifiedRules: rules }

  const v$ = buildVuelidate(unifiedRules, state)

  const {
    getError,
    getErrors,
    hasErrors,
    isAnyDirty,
    isAnyError,
    isError,
    resetErrors,
    setError,
    validate,
  } = useErrors(v$, {
    addManualError,
    removeAllManualErrors,
    removeManualError,
  })

  const { getField, isDirty, isInvalid, isPending, touch } = useProxy(v$)

  return {
    v$,
    getError,
    getErrors,
    getField,
    hasErrors,
    isAnyDirty,
    isAnyError,
    isDirty,
    isError,
    isInvalid,
    isPending,
    resetErrors,
    setError,
    touch,
    validate,
  }
}
