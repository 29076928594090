import { getId } from '@ga/utils'

import { DEFAULT_AVATAR } from '../constants'

export class MainService {
  constructor(gaApp) {
    this.gaApp = gaApp

    this.setSubscriptions()
  }

  get data() {
    return this.gaApp.stores.user.main.data
  }

  get isAuthorized() {
    return this.gaApp.stores.user.main.isAuthorized
  }

  // Метод обновления языка пользователя
  async updateUserLanguage(code) {
    const isAuthorized = this.gaApp.services.user.main.isAuthorized

    if (!isAuthorized) return

    const response = await this.gaApp.services.user.api.updateUserLanguage({
      language: code,
    })

    return response
  }

  resetUser() {
    this.gaApp.stores.user.main.data = {}
    this.gaApp.stores.user.main.isAuthorized = false
  }

  getIsAdult(birthday) {
    const dateNow = this.gaApp.libs.dateWrapper()
    const birthdayInternal = this.gaApp.libs.dateWrapper(birthday)

    const userAge = dateNow.diff(birthdayInternal, 'years')

    const adultAge = this.gaApp.stores.app.common.adultAge

    return userAge >= adultAge
  }

  checkIfAdult(reason) {
    const userIsAdult = this.gaApp.stores.user.main.isAdult
    const userIsAdultWasAsked = this.gaApp.stores.user.main.isAdultWasAsked

    if (userIsAdult || userIsAdultWasAsked) {
      return
    }

    this.gaApp.services.modal.main.openSuperModalAdult({
      reason,
    })
  }

  setUser({ data }) {
    const user = {
      ...this.gaApp.stores.user.main.data,
      ...data,
    }

    this.gaApp.stores.user.main.data = { ...user }

    if (data) {
      this.gaApp.stores.user.main.isAdult =
        this.gaApp.services.user.main.getIsAdult(user.birthDate)

      this.gaApp.stores.user.main.isAuthorized = Boolean(user)

      this.gaApp.services.app.apm.setUserContext({ id: user.id })

      this.gaApp.services.app.apm.setCustomContext({ user })
    } else {
      this.gaApp.services.app.apm.setCustomContext({ user: null })
    }
  }

  getFullName() {
    const firstName = this.data.firstName
    const lastName = this.data.lastName

    if (!firstName && !lastName) {
      return null
    }

    return firstName + ' ' + lastName
  }

  getFormattedPhone() {
    const phone = this.data.phone

    if (!phone) {
      return null
    }

    return this.gaApp.services.app.phone.getFormattedPhone(phone)
  }

  getAvatar() {
    return this.data.avatar?.url ? this.data.avatar : DEFAULT_AVATAR
  }

  dropTemporaryAddresses() {
    this.gaApp.stores.user.main.addresses =
      this.gaApp.stores.user.main.addresses.filter(
        (item) => item.saveId !== null,
      )
  }

  /*
   * TODO: есть 2 стора со списками адресов, в разном формате
   *  stores.user.main.addresses
   *  stores.customer.addresses.list
   *
   *  при удалении из stores.customer.addresses.list,
   *  stores.user.main.addresses обновиться только после перезагрузки страницы
   *
   *  нужен рефактор, чтобы был один источник списка адресов
   * */
  dropAddress(saveId) {
    this.gaApp.stores.user.main.addresses =
      this.gaApp.stores.user.main.addresses.filter(
        (item) => item.saveId !== saveId,
      )
  }

  setUserViewGUID() {
    this.gaApp.stores.user.main.viewGUID = getId()
  }

  // Подписываемся на ивенты
  setSubscriptions() {
    // При смене языка
    this.gaApp.eventBus.subscribe(
      'module/app/lang-switcher/locale-changed',
      (code) => {
        const nextCode =
          this.gaApp.services.app.langSwitcher.getApiLanguageCode(code)

        // Обновляем язык пользователю
        this.gaApp.services.user.main.updateUserLanguage(nextCode)
      },
    )

    // При логине
    this.gaApp.eventBus.subscribe('module/auth/login', async () => {
      const nextCode = this.gaApp.services.app.langSwitcher.getApiLanguageCode(
        this.gaApp.i18n.locale.code,
      )

      // Обновляем язык пользователю
      if (this.gaApp.stores.user.main.data.language !== nextCode) {
        const response =
          await this.gaApp.services.user.main.updateUserLanguage(nextCode)

        // если язык обновился, то обновляем его в сторе
        if (response) {
          this.gaApp.stores.user.main.data.language = response.language
        }
      }
    })
  }
}
