import { vueEmit } from '@ga/shared-browser'

const handlers = new WeakMap()
const states = new WeakMap()

function onFocusIn(el, vnode) {
  const focusWithin = states.get(el)

  if (focusWithin) {
    return
  }

  states.set(el, true)

  vueEmit(vnode, 'focuswithin')
}

function onFocusOut(el, vnode) {
  const focusWithin = states.get(el)

  if (!focusWithin) {
    return
  }

  // Ждем фокусировки следующего элемента
  window.requestAnimationFrame(() =>
    window.requestAnimationFrame(() => {
      const { activeElement } = document
      const isActiveElementWithin = el.contains(activeElement)

      if (!activeElement || !isActiveElementWithin) {
        states.set(el, false)

        vueEmit(vnode, 'blurwithin')
      }
    }),
  )
}

const focusWithin = {
  inserted(el, binding, vnode) {
    const handler = {
      focusin: () => onFocusIn(el, vnode),
      focusout: () => onFocusOut(el, vnode),
    }

    el.addEventListener('focusin', handler.focusin)
    el.addEventListener('focusout', handler.focusout)

    handlers.set(el, handler)
    states.set(el, false)
  },

  unbind(el) {
    const handler = handlers.get(el)

    if (handler) {
      el.removeEventListener('focusin', handler.focusin)
      el.removeEventListener('focusout', handler.focusout)
    }
  },
}

export { focusWithin }
