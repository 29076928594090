import { trimObjectStrings } from '@ga/utils'

export class SkyengService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  changeModalVisibility(value) {
    this.gaApp.stores.info.skyeng.modalOpen = value
  }

  openModalMenu() {
    this.changeModalVisibility(true)
  }

  closeModalMenu() {
    this.changeModalVisibility(false)
  }

  async submitGiftForm(alreadyTakeGift) {
    try {
      this.gaApp.stores.info.skyeng.form.isLoading = true

      const { fullName, email } = trimObjectStrings(
        this.gaApp.stores.info.skyeng.form.fields,
      )

      await this.gaApp.services.info.api.skyengSubscribe({ fullName, email })
      this.gaApp.stores.info.skyeng.isGiftFormSent = true
    } catch (error) {
      console.log(error)

      switch (error.statusCode) {
        case 409:
          alreadyTakeGift()
          break
        default:
          this.gaApp.services.notification.main.open(
            this.gaApp.i18n.t('info.skyeng.form.errors.notification'),
          )
      }
    } finally {
      this.gaApp.stores.info.skyeng.form.isLoading = false
    }
  }

  changeCheckboxStatus(value) {
    this.gaApp.stores.info.skyeng.form.fields.checkbox = value
  }

  checkboxTrueValue() {
    this.changeCheckboxStatus(true)
  }

  checkboxFalseValue() {
    this.changeCheckboxStatus(false)
  }
}
