import { buildGetUrlForListing } from './builders'

export class StreamsRepository {
  constructor(gaApp, endpoints) {
    this.gaApp = gaApp

    this.endpoints = gaApp.api.endpoints(endpoints)
  }

  fetchListing(data) {
    const { url } = this.endpoints.fetchStreamsListing()
    const params = buildGetUrlForListing(data)

    return this.runGetRequest(url, params)
  }

  fetchListingPreview(id) {
    const { url } = this.endpoints.fetchStreamsListingPreview(id)
    return this.runGetRequest(url)
  }

  fetchFilterListing(data) {
    const { url } = this.endpoints.fetchStreamsFilterListing()
    const filters = { rubric: [data.rubric] }
    const params = buildGetUrlForListing({
      pagination: data.pagination,
      filters,
    })

    return this.runGetRequest(url, params)
  }

  fetchStatuses() {
    const { url } = this.endpoints.fetchStreamsStatuses()

    return this.runGetRequest(url)
  }

  fetchStreamInfoById(id) {
    const { url } = this.endpoints.fetchStreamInfoById(id)

    return this.runGetRequest(url)
  }

  fetchStreamInfoByUrl(streamUrl) {
    const { url } = this.endpoints.fetchStreamInfoByUrl(streamUrl)

    return this.runGetRequest(url)
  }

  fetchListingFilters() {
    const { url } = this.endpoints.fetchStreamsFilters()

    return this.runGetRequest(url)
  }

  fetchViewed(data) {
    const { url } = this.gaApp.isWebview
      ? this.endpoints.fetchStreamViewedWebview()
      : this.endpoints.fetchStreamViewed()

    return this.runPostRequest(url, data)
  }

  async runGetRequest(url, params) {
    try {
      const response = await this.gaApp.api.request().get(url, { params })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async runPostRequest(url, data) {
    try {
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }
}
