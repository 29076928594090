/* eslint-disable no-template-curly-in-string */
import { doubleRAF } from '@ga/shared-browser'

export class OneService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async fetchPreviewArticle(id) {
    if (this.gaApp.isServer) {
      this.gaApp.redirectError({ statusCode: 500 })
    }

    if (this.gaApp.stores.articles.one.scrollToTop) {
      this.scrollToTop()
    }

    await this.reset()
    await this.gaApp.services.articles.api.fetchArticle(id)
    this.gaApp.stores.articles.one.isPreview = true
  }

  async fetchArticleByUrl(url) {
    if (this.gaApp.stores.articles.one.scrollToTop) {
      this.scrollToTop()
    }

    await this.reset()
    await this.gaApp.services.articles.api.fetchArticleByUrl(url)
    this.gaApp.stores.articles.one.isPreview = false
  }

  async fetchProductsBySku(skuArray = []) {
    const productsRaw =
      await this.gaApp.services.articles.api.fetchProductsBySku(skuArray)

    const productsFormattedForModuleProductCard = productsRaw.map(
      (product) => ({
        name: product.name,
        brand: product.brand,
        itemId: product.itemId,
        type: product.productType,
        url: product.url,
        inStock: product.inStock,
        reviews: product.reviews ? product.reviews : false,
        media: (() => {
          const media = product.imageUrls.map((image) => ({
            image,
            meta: false,
          }))

          if (!this.gaApp.features.get('articlesHowToShortVideoOn')) {
            return media
          }

          if (product.shortVideos) {
            const inStock = product.inStock

            product.shortVideos.forEach((video) => {
              // если товар не в наличии, выводим только превью-изображение
              const source = inStock
                ? { video, priority: true }
                : {
                    image: video.videoThumbnail,
                    meta:
                      this.gaApp.services.plp.seo.getImageAttributes(product) ??
                      false,
                  }

              media.splice(video.position || 0, 0, source)
            })
          }

          return media
        })(),
        favourite: product.favourite,
        configurable: product.configurable,
        needOnlineConsultation: product.needOnlineConsultation,
        isAdult: product.isAdult,
        prices: product.price
          ? {
              actual: product.price.actual,
              old: product.price.old ? product.price.old : undefined,
              prefix:
                product.price.viewOptions.priceFrom && product.price.hasLoyalty,
              oldStrikethrough: product.price.viewOptions.crossPrice,
            }
          : false,
        attributes: product.attributes
          ? {
              units: {
                name: product.attributes.units.name,
                values: product.attributes.units.values,
                count: product.attributes.units.count,
                currentUnitValue: product.attributes.units.currentUnitValue,
              },
              colors: {
                values: product.attributes.colors.values,
                count: product.attributes.colors.count,
              },
            }
          : false,
        dolyame: product.dolyame ? product.dolyame : false,
        labels: product.labels ? product.labels : false,
        disclaimer: product.disclaimer ? product.disclaimer : false,
      }),
    )

    return {
      productsRaw,
      productsFormattedForModuleProductCard,
    }
  }

  async fetchProductsByUrl(url, pageNumber = 0) {
    const { products, count } =
      await this.gaApp.services.articles.api.fetchProductsByUrl(url, pageNumber)

    return {
      productsRaw: products,
      count,
    }
  }

  async fetchArticleViewed() {
    if (
      this.gaApp.stores.articles.one.isPreview ||
      !this.gaApp.stores.articles.one.id
    ) {
      return
    }

    const captchaToken = !this.gaApp.isWebview
      ? await this.gaApp.services.captcha.main.generateToken()
      : null

    const id = this.gaApp.stores.articles.one.id

    await this.gaApp.services.articles.api.fetchArticleViewed({
      captchaToken,
      id,
    })
  }

  // перед загрузкой новых данных - поднимаем страницу наверх чтобы не было скачка контента
  scrollToTop() {
    doubleRAF().then(() => {
      window?.scrollTo?.({ top: 0 })
    })
  }

  reset() {
    this.gaApp.stores.articles.one.$reset()
  }
}
