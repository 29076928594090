import { computed, unref } from 'vue'

import { TYPE, usePlacements } from '~/modules/placements'

import { useAnalytic } from './../../../scripts/composables/analytic'

import { useProductCardAnalytic, useProductSliderAnalytic } from '~/analytics'

export const useSliderData = () => {
  const { placements, pending } = usePlacements({
    type: TYPE.ERROR,
  })

  const widgetPlacement = computed(() => unref(placements)?.[0] ?? {})

  const widgetProducts = computed(() => unref(widgetPlacement)?.products ?? [])
  const placementTitle = computed(() => unref(widgetPlacement)?.name ?? '')

  const { getCartAnalyticDetails, getAnalyticData } = useAnalytic()

  const analyticData = computed(() => {
    return getAnalyticData({
      title: unref(placementTitle),
      placement: unref(widgetPlacement),
    })
  })

  const buildCartAnalyticsDetails = (index) =>
    getCartAnalyticDetails({
      productIndex: index,
      blockName: unref(placementTitle),
    })

  const productCardListeners = (item, index) =>
    useProductCardAnalytic({ item, index, analyticData }).productCardListeners

  const { productSliderListeners } = useProductSliderAnalytic(analyticData)

  return {
    placementTitle,
    pending,
    analyticData,
    widgetProducts,

    productCardListeners,
    productSliderListeners,

    buildCartAnalyticsDetails,
  }
}
