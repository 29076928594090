import { getCategoriesBreadcrumbs } from '../../../../utils/common/get-categories-breadcrumbs'

export class GtmGa4ProductModel {
  constructor({
    brand,
    name,
    amount,
    id,
    breadcrumbs,
    attributesSelected,
    itemListName,
    inStock,
    itemListId,
    index,
    currency,
    creativeName,
    creativeSlot,
    promotionName,
    hasCoupon,
  }) {
    this.item_name = `${brand} ${name}`
    this.item_id = id
    this.price = amount
    this.item_brand = brand
    this.item_list_name = itemListName
    this.stock = inStock
    this.item_list_id = itemListId
    this.index = index
    this.currency = currency

    getCategoriesBreadcrumbs(breadcrumbs).forEach((category, index) => {
      if (index) {
        this[`item_category${index + 1}`] = category
      } else {
        this.item_category = category
      }
    })

    const variant = Object.values(attributesSelected || {}).join(' ')
    if (variant) {
      this.item_variant = variant
    }

    if (creativeName) this.creative_name = creativeName
    if (creativeSlot) this.creative_slot = creativeSlot
    if (promotionName) this.promotion_name = promotionName
    if (hasCoupon) this.has_promocode = 1
  }
}
