import { BLOCK_TYPES } from '../../components/molecules/content/scripts/consts'

import { BLOCK_VIEWS, BLOCK_VIEWS_BY_TYPES } from './block-views'
import { CHUNK_TRANSITIONS, CHUNK_TYPE } from './chunk-transitions'

/**
 * Функция перегруппировывает блоки исходных секций для дизайна в чанки.
 * @param {Object} sections
 */
export const sectionChunks = (section) => {
  const chunks = []
  let imageCount = 0
  let currentChunkType = CHUNK_TYPE.EMPTY

  section.blocks.forEach((block) => {
    const blockView = BLOCK_VIEWS_BY_TYPES[block.type] ?? BLOCK_VIEWS.NARROW

    const newChunkType = CHUNK_TRANSITIONS[currentChunkType][blockView]

    let newBlock = block

    // Так как происходит разбиение секции на чанки
    // нужно заранее определить позицию вертикальной картинки
    if (block.type === BLOCK_TYPES.IMAGE && block.data.isVerticalImage) {
      const imagePosition = imageCount % 2 === 0 ? 'start' : 'end'

      imageCount = imageCount + 1

      newBlock = {
        ...block,
        data: {
          ...block.data,
          imagePosition,
        },
      }
    }

    if (newChunkType === currentChunkType) {
      const lastChunk = chunks[chunks.length - 1]
      if (lastChunk) lastChunk.blocks.push(newBlock)
    } else {
      chunks.push({
        type: newChunkType,
        blocks: [newBlock],
      })
    }

    currentChunkType = newChunkType
  })

  return chunks
}
