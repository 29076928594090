import { defineStore, getActivePinia } from 'pinia'

import { state } from './state'

export const launchQaStore = () => {
  const init = defineStore('infoLaunchQa', {
    state,
  })

  return init(getActivePinia())
}
