import { getIntlSystemTimezone } from '@ga/shared-dates'

export class DtoConfigService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Преобразует конфигурацию оформления ЭПК в данные стора
   * @param {object} params конфигурация ЭПК из BFF
   */
  getConfigDto({
    backgrounds,
    fieldsInfo,
    foregrounds,
    timeZones,
    giftcardValues,
  }) {
    return {
      data: {
        backgrounds: this.getBackgrounds(backgrounds),
        foregrounds: this.getImages(foregrounds),
        prices: this.getPrices(giftcardValues),
        timezones: this.getTimezones(timeZones),
        rules: fieldsInfo,
      },
    }
  }

  /**
   * Преобразование опций
   * @param {object} data объект параметров
   * @param {string} nameId идентификатор искомого параметра
   * @param {Function} fn функция маппинга опции
   */
  _getOptions(data, nameId, fn) {
    let activeId
    const options = []

    if (data?.length) {
      data.forEach((option, index) => {
        if (index === 0 || option.isDefault) {
          activeId = option[nameId]
        }

        options.push(fn(option))
      })
    }

    return { options, activeId }
  }

  /**
   * Преобразование конфигурации обложек
   * @param {object} data конфигурация обложек
   */
  getImages(data) {
    if (!data?.length) {
      this.errorDto()
    }

    return this._getOptions(data, 'foregroundId', (option) => ({
      id: option.foregroundId,
      data: {
        imgUrl: option.imageUrl,
        previewUrl: option.pickerImageUrl,
      },
    }))
  }

  /**
   * Преобразование конфигурации фонов
   * @param {object} data конфигурация фонов
   */
  getBackgrounds(data) {
    if (!data?.length) {
      this.errorDto()
    }
    return this._getOptions(data, 'backgroundId', (option) => ({
      id: option.backgroundId,
      data: {
        color: option.designColor,
        imgUrl: option.imageUrl,
        previewUrl: option.picker.type === 'image' ? option.picker.value : '',
      },
    }))
  }

  /**
   * Возвращает массив опций, активный id, формат валюты, деноминатор
   * @param {object} options параметры номиналов
   */
  getPrices({ current, valuesRange }) {
    if (!current || !valuesRange?.length) {
      this.errorDto()
    }

    const activeId = this.gaApp.services.app.currency.getNominal({
      amount: current.amount,
      denominator: current.denominator,
    })
    const options = valuesRange.map(({ amount, denominator, currency }) => {
      const priceId = this.gaApp.services.app.currency.getNominal({
        amount,
        denominator,
      })

      const label = this.gaApp.services.app.currency.getNominalFormatted({
        amount,
        denominator,
        currency,
        minimumFractionDigits: 0,
      })

      return {
        id: priceId,
        data: {
          amount,
          denominator,
          currency,
          label,
        },
      }
    })

    // Формат валюты - 'RUB'
    const currency = current?.currency ?? valuesRange?.[0]?.currency

    // Деноминатор для деления цены '1, 10, 100'
    const denominator = current?.denominator ?? valuesRange?.[0]?.denominator

    return { options, activeId, currency, denominator }
  }

  /**
   * Возвращает массив временных зон
   * @param {object} data параметры временных зон
   */
  getTimezones(data) {
    if (!data?.length) {
      this.errorDto()
    }

    const options = []
    let activeId = ''
    const { offsetName, shortOffsetTime } = getIntlSystemTimezone({
      asObject: true,
    })
    const systemTimezone = `${offsetName}${shortOffsetTime}`

    data.forEach((item, index) => {
      const option = {
        value: item.id,
        text: item.name,
      }

      if (index === 0 || option.value === systemTimezone) {
        activeId = option.value
      }

      options.push(option)
    })

    return { options, activeId }
  }

  /**
   * Генерация ошибки преобразования конфигурации
   * @param {string} error сообщение ошибки
   */
  errorDto(error = 'options gift cards not received') {
    this.gaApp.redirectError(error)
    throw this.gaApp.api.error(error)
  }
}
