import replace from 'lodash/replace'

import { TYPE } from '../analytics/constants'
import { MODULE_TYPES } from '../constants'
import { SCROLL_HASH_PREFIX_REGEXP } from '../constants/scroll'

export class MainService {
  constructor(gaApp) {
    this.gaApp = gaApp
    this.pageCreateUnsubscriber = null
    this.subscribeToEventBus()
  }

  get blockScrollNavigation() {
    /**
     * Формируем href, затем вызываем метод сервиса app.scrollNavigation,
     * что бы установить якорь в URL
     */
    const setAnchor = () => {
      const url = new URL(window.location.href)

      return this.gaApp.services.app.scrollNavigation.setAnchor(
        this.gaApp.stores.home.main.scrollAnchor,
        decodeURIComponent(url.href),
      )
    }

    const saveAnchor = (blockId) => {
      this.gaApp.stores.home.main.scrollAnchor = blockId
    }

    /**
     * Записываем в стор якорь взятый из URL
     */
    const saveAndRemoveAnchor = () => {
      const anchor = this.gaApp.services.app.scrollNavigation.getAnchor()
      this.gaApp.stores.home.main.immediateScrollAnchor = anchor
    }

    /**
     * Забираем якорь из стора, скроллим к элементу и затем удаляем якорь из стора
     */
    const scrollToAnchor = () => {
      const anchor = this.gaApp.stores.home.main.immediateScrollAnchor

      this.gaApp.services.app.scrollNavigation.scrollToAnchor(
        anchor,
        undefined,
        'start',
      )

      this.gaApp.stores.home.main.immediateScrollAnchor = null
    }

    const isImmediateAnchor = (id) => {
      const anchorId = replace(
        this.gaApp.stores.home.main.immediateScrollAnchor,
        SCROLL_HASH_PREFIX_REGEXP,
        '',
      )

      return id === anchorId
    }

    return {
      setAnchor,
      saveAnchor,
      saveAndRemoveAnchor,
      scrollToAnchor,
      isImmediateAnchor,
    }
  }

  subscribeToPageCreate() {
    this.pageCreateUnsubscriber = this.gaApp.eventBus.subscribe(
      'module/app/router/page-created',
      () => {
        this.blockScrollNavigation.setAnchor()
      },
    )
  }

  getItemListName(current, next) {
    if (next.includes(current)) {
      return next
    }
    if (!next) {
      return current
    }
    return current ? `${current}/${next}` : next
  }

  // отправка GA аналитики
  async sendGaAnalytic({
    url,
    blockName,
    blockPosition,
    slideName,
    slidePosition,
  }) {
    const { hash } = this.gaApp.route

    // получение данных редиректа для проверки на внутреннюю страницу
    const { data } = await this.gaApp.repositories.home.main.getRedirectData({
      url,
    })

    // если это внутренняя страница (и не промо - тк пока не добавлено)
    if (data.success && data.moduleType !== MODULE_TYPES.PROMO) {
      // получаем данные страницы, на которую совершаем переход (конкретно для получения itemListName)
      const analyticData =
        await this.gaApp.analytics.modules.home.getAnalyticByUrl({
          url: url.replace(hash, ''),
        })

      // получаем данные аналитики из сторы
      const gaAnalytics = analyticData[TYPE.GAA] || null

      // отправляем GA аналитику
      this.gaApp.analytics.modules.home.onContentLinkClick({
        blockName,
        blockPosition,
        slideName,
        slidePosition,
        link: url,
        itemListName: this.getItemListName('главная', gaAnalytics.itemListName),
        type: data?.moduleType,
        pageType: data?.pageType,
        itemId: data?.entityId,
      })
    }

    // делаем принудительный переход
    this.gaApp.services.app.router.push(url)
  }

  // пока страница promo не добавлена в редирект - оставляем, после добавления нужно убрать
  subscribeToEventBus() {
    this.gaApp.eventBus.subscribe('module/app/router/page-created', (data) => {
      if (Object.keys(this.gaApp.stores.home.main.analyticData).length === 0) {
        return
      }

      if (!this.gaApp.stores.home.main.analyticData.itemListName) {
        this.gaApp.stores.home.main.analyticData.itemListName = 'главная'
      }

      // отправляем GA аналитику только если это страница деталки PROMO
      if (data.moduleType === MODULE_TYPES.PROMO) {
        this.gaApp.analytics.modules.home.onContentLinkClick({
          ...this.gaApp.stores.home.main.analyticData,
          type: data.moduleType,
          pageType: data.pageType,
        })
      }
    })
  }

  unsubscribeFromPageCreate() {
    this.pageCreateUnsubscriber()
  }

  enableImmediateShowBlocks() {
    this.gaApp.stores.home.main.immediateShowBlocks = true
  }

  setPageHeading(heading) {
    this.gaApp.stores.home.main.pageHeading = heading
  }

  setAnalyticData(data) {
    this.gaApp.stores.home.main.analyticData = data
  }

  resetAnalyticData() {
    this.gaApp.stores.home.main.analyticData = {}
  }

  webSiteSchema() {
    const baseUrl =
      this.gaApp.services.app.main?.baseUrl || this.gaApp.i18n?.locale?.baseUrl

    return {
      '@type': 'WebSite',
      name: this.gaApp.i18n.t('home.webSiteSchema.name'),
      url: `${baseUrl}/`,
      potentialAction: {
        '@type': 'SearchAction',
        target: '/catalogsearch/result?q={search_term_string}',
        'query-input': 'required name=search_term_string',
      },
    }
  }

  initMount() {
    this.gaApp.services.header.theme.setHeaderTransparentColorScheme()
    this.setPostTasks()
  }

  setPostTasks() {
    this.gaApp.services.app.scheduler.postTasks([
      {
        task: () => this.gaApp.analytics.modules.home.onMounted(),
        priority: 'background',
      },
    ])
  }

  resetStores() {
    this.gaApp.stores.home.main.$reset()
    this.gaApp.stores.home.slots.$reset()
    this.gaApp.stores.home.banners.$reset()
    this.gaApp.stores.home.stories.$reset()
    this.gaApp.stores.home.content.$reset()
    this.gaApp.stores.home.promo.$reset()
    this.gaApp.stores.home.products.$reset()
    this.gaApp.stores.home.info.$reset()
    this.gaApp.stores.home.category.$reset()
    this.gaApp.stores.home.clientDays.$reset()
    this.gaApp.stores.home.listing.$reset()
    this.gaApp.stores.home.categoryList.$reset()
  }
}
