import { CHUNK_SIZE_ANALYTICS, TYPE } from '../../constants'
import {
  GtmGaUaProductViewModel,
  GtmGaUaProductViewResetModel,
} from '../../models'
import { BaseBuilder } from '../base/base.builder'

export class PageLeaveProductViewBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map()

    if (data?.chunk?.length < CHUNK_SIZE_ANALYTICS) {
      models.set(TYPE.GTM_UA_SYSTEM, GtmGaUaProductViewResetModel)
      models.set(TYPE.GTM_UA, GtmGaUaProductViewModel)
    }

    super(models, data)
  }
}
