export const endpoints = {
  brands: () => ({
    url: 'catalog/brands',
  }),
  brandsV2: () => ({
    url: 'brands',
  }),
  // TODO: удалить при окончательном переходе на plaid
  brandsFavorites: () => ({
    url: 'catalog/brands/favorites',
  }),
  searchBrands: () => ({
    url: 'brands/search',
  }),
}
