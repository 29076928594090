import { PROMOTION_QUERY_KEY } from '../constants/keys'
import { PAGE_TYPE } from '../constants/pages'
import { PAGINATION_DIRECTION } from '../constants/pagination'

export class ApiService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async fetchCities() {
    try {
      const { cities, sorted: citiesSorted } =
        await this.gaApp.services.location.retailStores.fetchCities()

      this.gaApp.services.promo.location.setCities({ cities, citiesSorted })
    } catch (error) {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('promo.errors.fetchCities'),
      )
    }
  }

  async fetchCityStores() {
    const { data } = await this.gaApp.repositories.promo.main.getStores()
    this.gaApp.services.promo.stores.setStores(data.shops)
  }

  async fetchPromotions(page, direction = PAGINATION_DIRECTION.NEXT) {
    const params = {
      [PROMOTION_QUERY_KEY.SHOPS]: this.gaApp.stores.promo.listing.shopsKey,
      [PROMOTION_QUERY_KEY.LIMIT]: this.gaApp.stores.promo.page.getPageSize,
      [PROMOTION_QUERY_KEY.PAGE]: page,
    }

    try {
      this.gaApp.services.promo.pagination.setPagination({
        direction,
        value: {
          pending: true,
          error: false,
        },
      })

      const pageType = this.gaApp.stores.promo.page.pageType
      const cacheKey =
        pageType === PAGE_TYPE.LISTING_PROMO ? 'promo' : 'clientDays'

      const repositoryFn =
        pageType === PAGE_TYPE.LISTING_PROMO ? 'promotions' : 'clientDays'

      const cacheData = this.gaApp.services.cache.main.getServerData(cacheKey)
      let response =
        cacheData ||
        (await this.gaApp.repositories.promo.main[repositoryFn](params))

      // Если страницы с данными нет - грузим первую страницу
      if (response.data.count < page) {
        response = await this.gaApp.repositories.promo.main[repositoryFn]({
          ...params,
          [PROMOTION_QUERY_KEY.PAGE]: 1,
        })
      }

      const { data } = response

      this.gaApp.services.promo.listing.setPromotionsCount(data.count)

      this.gaApp.services.promo.listing.setPage({
        number: data.page,
        promotions: data.promotions,
      })

      this.gaApp.services.promo.listing.setMeta({
        metaTitle: data.metaTitle,
        metaDescription: data.metaDescription,
        metaKeywords: data.metaKeywords,
      })
    } catch (error) {
      this.gaApp.services.promo.pagination.setPagination({
        direction,
        value: { error: true },
      })
    } finally {
      this.gaApp.services.promo.pagination.setPagination({
        direction,
        value: { pending: false },
      })
    }
  }

  async fetchPromoInfo(promotionId) {
    const cacheData = this.gaApp.services.cache.main.getServerData('promoInfo')

    const { data } =
      cacheData ||
      (await this.gaApp.repositories.promo.main.promoInfo(promotionId))

    this.gaApp.stores.promo.main.info = data
    this.gaApp.services.promo.main.setMeta({
      metaTitle: data.metaTitle,
      metaDescription: data.metaDescription,
      inListing: data.inListing,
    })
  }
}
