export class GtmGa4ViewItemModel {
  constructor(item) {
    const { id, name, price, brand, variant, quantity, itemListName, stock } =
      item

    this.item_name = name
    this.item_id = id
    this.price = price
    this.item_brand = brand
    this.item_variant = variant
    this.quantity = quantity
    this.item_list_name = itemListName
    this.stock = String(stock)
  }
}
