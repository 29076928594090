import { analytics } from './analytics'
import * as components from './components'
import { locales } from './locales'
import { repositories } from './repositories'
import { services } from './services'
import { stores } from './store'

export { usePlacements } from './composable'
export * from './constants'

export const placements = {
  meta: {
    name: 'placements',
    locales,
  },
  components,
  layers: {
    services,
    repositories,
    stores,
    analytics,
  },
}
