export const TYPE = {
  GTM_UA: 'gtm_ua',
  GTM_UA_SYSTEM: 'gtm_ua_system',
  GTM_4: 'gtm_4',
  MINDBOX: 'mindbox',
  GDESLON: 'gdeslon',
  GAA: 'ga_analytics',
  DIGI: 'digi_analytics',
}

export const SOURCE = 'plaid_backend'

export const ACTIONS = {
  ADD_TO_CART: {
    GA_UA: 'addToCart',
    GA_4: 'add_to_cart',
    MINDBOX: {
      ru: 'Website.AddProductToCartItemList.JS',
      by: 'Website.AddProductToCartItemList.JS',
      kz: 'Website.AddProductToCartItemList.JS',
      qa: 'WebsiteQA.AddProductToCartItemList.JS',
    },
  },
  REMOVE_FROM_CART: {
    GA_UA: 'Remove from Cart',
    GA_4: 'remove_from_cart',
    MINDBOX: {
      ru: 'Website.RemoveProductFromCartItemList.JS',
      by: 'Website.RemoveProductFromCartItemList.JS',
      kz: 'Website.RemoveProductFromCartItemList.JS',
      qa: 'WebsiteQA.RemoveProductFromCartItemList.JS',
    },
  },
  FAVORITE: {
    MINDBOX: {
      ru: 'Website.AddProductToFavoritesItemList.JS',
      by: 'Website.AddProductToFavoritesItemList.JS',
      kz: 'Website.AddProductToFavoritesItemList.JS',
      qa: 'WebsiteQA.AddProductToFavoritesItemList.JS',
    },
  },
  VIEW: {
    MINDBOX: {
      ru: 'Website.ViewCategory.JS',
      by: 'Website.ViewCategory.JS',
      kz: 'Website.ViewCategory.JS',
      qa: 'WebsiteQA.ViewCategory.JS',
    },
  },
}

export const EVENT_TYPES = {
  CART_ADD_EVENT: 'CART_ADD_EVENT',
  WIDGET_CLICK: 'WIDGET_CLICK',
  WIDGET_VIEW: 'WIDGET_VIEW',
}

export const WIDGET_TYPES = {
  RENDER: 'RENDER',
  SLIDER: 'SLIDER',
  VIEW: 'VIEW',
}

export const MERCHANT_ID = '97915'
export const DEDUPLICATION = 'gdeslon'

export const CHUNK_SIZE_ANALYTICS = 1
