export const STATUS = {
  IN_STOCK: 'in-stock',
  LOW_STOCK: 'low-stock',
  OOS: 'oos',
  WHOLE_STOCK_IN_CART: 'whole-stock-in-cart',
}

export const CATEGORY = 'recommendation'

export const TYPE = {
  BRANDZONE: 'brandzone',
  CART: 'cart',
  PDP: 'pdp',
  ERROR: 'errorPage',
  FAVORITES: 'LkFavorites',
  HOME: 'home',
  HOME_LISTING: 'homeListing',
  CATEGORY: 'category',
  PLP: 'plp',
  STORIES: 'stories',
  SEARCH: 'search',
  ORDERS: 'lkOrders',
  PURCHASED: 'lkPurchasedProducts',
}

export const SIZE_SLIDER = {
  L: 'l',
  LH: 'lh',
  M: 'm',
  SX: 'sx',
  SC: 'sc',
  S: 's',
  XS: 'xs',
}

export const PRODUCT_CARD_SIZE = {
  S: 's',
  M: 'm',
  L: 'l',
}

export const VISIBLE_MAX_COUNT = 3
