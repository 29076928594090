export const MODULE_TYPE = 'game'

export const EVENT_TO_LISTEN = {
  GET_USER: 'GET_USER',
  AUTH: 'AUTH',
  SHARE: 'SHARE',
  OPEN: 'OPEN',
  RELOAD: 'RELOAD',
}

export const EVENT_TO_DISPATCH = {
  USER: 'USER',
}

export const AVAILABLE_GAMES = {
  ru: {
    tamagotchi: 'https://tamagotchi.goldapple.ru',
    'ga-super': '/_static/ga-super.html',
  },
  kz: {},
  by: {},
  qa: {},
  ae: {},
}
