export const endpoints = {
  category: () => ({
    url: 'catalog/category',
  }),
  listingFilters: () => ({
    url: 'catalog/filters',
  }),
  listingFiltersV4: () => ({
    url: 'catalog/filters-v4',
  }),
  searchFilters: () => ({
    url: 'catalog/search-filters',
  }),
  searchFiltersV4: () => ({
    url: 'catalog/search-filters-v4',
  }),
  listingProducts: () => ({
    url: 'catalog/products',
  }),
  listingProductsV4: () => ({
    url: 'catalog/products-v4',
  }),
  searchProducts: () => ({
    url: 'catalog/search-products',
  }),
  searchProductsV4: () => ({
    url: 'catalog/search-products-v4',
  }),
  actual: () => ({
    url: 'catalog/products-actual',
  }),
  slots: () => ({
    url: 'catalog/product/slots',
  }),
}
