/**
 * Создает объект параметров для GET запроса листинга материалов

 * @param {Object} pagination - параметры пагинации для запроса
 * @param {Number} pagination.pageNumber - номер запрашиваемой страницы
 * @param {Number} pagination.pageSize - количество элементов которые необходимо вернуть

 * @param {Object} filters - Объектов фильтров (ключ тип фильтра, значение массив строковых значений)
 * @param {[string]} filters.rubric - содержит массив ключей фильтра по рубрике

 * @param {String} lastActualPublicationDate - дата публикации последней статьи, после которой идет поиск
 */
export function buildGetUrlForListing({
  pagination,
  filters,
  lastActualPublicationDate,
}) {
  const params = {
    page: pagination.pageNumber,
    limit: pagination.pageSize,
  }

  if (filters) {
    Object.entries(filters).forEach(([type, values]) => {
      // отсортировать и привести фильтры к нижнему регистру для кеширования
      const sortedValues = values.sort().map((value) => value.toLowerCase())
      params[type] = String(sortedValues)
    })
  }

  if (lastActualPublicationDate) {
    params.lastActualPublicationDate = lastActualPublicationDate
  }

  return params
}
