export const state = () => ({
  queryPlain: '',
  queryOriginal: '', // неизменяемое поле для отправки в аналитику
  resultFirst: [],
  resultSecond: [],
  popularBrands: [],
  middleOfAWord: [],
  firstCharEntryDirect: '',
  firstCharEntryDirectTranslit: '',
})
