import { TYPE } from '../../constants'

export class BaseController {
  constructor(gaApp, core) {
    this.gaApp = gaApp

    this.core = core

    this.metrics = {
      [TYPE.DIGI]: this.core.all?.digiAnalytics,
      [TYPE.GTM_4]: this.core.all?.gtm,
      [TYPE.GAA]: this.core.all?.gaAnalytics,
    }
  }

  getBrandName(str = this.gaApp.route.path) {
    return str?.match(/^\/brands\/([^/]+?)(\/|$|\?)/)?.[1]
  }

  getNormalizeBannerData(data, blockId) {
    const brandName = this.getBrandName()
    const slot = this.gaApp.services.brandzone.main.findSlotById(blockId)

    const blockPosition = slot.index + 1
    const slidePosition = data.index + 1

    const slideName = data.item.name
    const itemListId = `брендзона/block_${blockPosition}/${brandName}`
    const blockName = `брендзона/главный баннер/${brandName}`
    const creativeSlot = `slide_${slidePosition}`

    return {
      blockName,
      slideName,
      itemListId,
      creativeSlot,
    }
  }

  getNormalizeMenuData(data) {
    const brandName = this.getBrandName()

    const { item } = data

    return {
      url: item.link,
      path: item.name,
      itemListName: `брендзона/${brandName}`,
    }
  }

  send(Builder, data) {
    new Builder(data).build().forEach((data, key) => {
      const metric = this.metrics[key]
      if (metric && metric.send) {
        metric.send(data)
      }
    })
  }
}
