export const TYPE = {
  GTM_4: 'gtm_4',
  MINDBOX: 'mindbox',
  GAA: 'ga_analytics',
}
export const SOURCE = 'plaid_backend'

export const ACTIONS = {
  VIEW: {
    GA_4: 'view_item_list',
    MINDBOX: {
      ru: 'Website.ViewCategory.JS',
      by: 'Website.ViewCategory.JS',
      kz: 'Website.ViewCategory.JS',
      qa: 'WebsiteQA.ViewCategory.JS',
    },
  },
  CLICK: {
    GA_4: 'select_item',
  },
  MENU: {
    GA_4: 'user_interaction',
  },
}

export const EVENT_CATEGORIES = {
  ACCOUNT: 'user_account',
  OPEN_WISHLIST: 'open_wishlist',
  FILTERS: 'filters',
  ORDER_INTERACTION: 'order_interaction',
}

export const ACTION_TYPES = {
  APPLY_FILTERS: 'apply_filters',
  CLICK_ABOUT_PICK_UP_POINT: 'click_about_pick_up_point',
  CLICK_BACK_TO_SHOPPING: 'click_back_to_shopping',
  CLICK_CANCEL_ORDER: 'click_cancel_order',
  CLICK_RATE_ORDER: 'click_rate_order',
  CLICK_REPEAT_ORDER: 'click_repeat_order',
  CLICK_RETURN_ORDER: 'click_return_order',
  MENU: 'account_menu_click',
  USER_INFO: 'click_profile',
  CLICK_BRING_NOW: 'click_bring_now',
}

export const SCREEN_TYPES = {
  ACCOUNT: 'account',
  ACCOUNT_MY_PURCHASES: 'account/my_purchases',
  ACCOUNT_ORDER: 'account/order',
}

export const FROM = {
  LK: 'lk',
  TABBAR: 'tabbar',
}

export const CHUNK_SIZE_ANALYTICS = 5

export const PLACEMENT_TYPES = {
  ORDERS: 'account/my_purchases',
}
