export class ApiService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async getToken(game) {
    try {
      const { data } = await this.gaApp.repositories.game.main.getToken(game)

      this.gaApp.stores.game.main.gameToken = data.access_token || null
      this.gaApp.stores.game.main.processingStatus =
        data.processingStatus || null
    } catch (error) {
      this.gaApp.redirectError({ statusCode: 500, message: error })
    }
  }
}
