import { AddressesService } from './addresses.service'
import { AddressesApiService } from './addresses-api.service'
import { AddressesEditService } from './addresses-edit.service'
import { ApiService } from './api.service'
import { CitiesService } from './cities.service'
import { ConfirmService } from './confirm.service'
import { FormatterService } from './formatter.service'
import { GeolocationService } from './geolocation.service'
import { mainService } from './main.service'
import { ModalService } from './modal.service'
import { PageScreenService } from './page-screen.services'
import { RetailStoresService } from './retail-stores.service'
import { SearchService } from './search.service'
import { SpecifyService } from './specify.service'
import { StreetsService } from './streets.service'

export const services = (gaApp) => ({
  addresses: new AddressesService(gaApp),
  addressesEdit: new AddressesEditService(gaApp),
  addressesApi: new AddressesApiService(gaApp),
  api: new ApiService(gaApp),
  cities: new CitiesService(gaApp),
  confirm: new ConfirmService(gaApp),
  formatter: new FormatterService(gaApp),
  geolocation: new GeolocationService(gaApp),
  main: mainService(gaApp),
  modal: new ModalService(gaApp),
  pageScreen: new PageScreenService(gaApp),
  search: new SearchService(gaApp),
  specify: new SpecifyService(gaApp),
  streets: new StreetsService(gaApp),
  retailStores: new RetailStoresService(gaApp),
})
