export class PageService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  getData(redirect) {
    try {
      if (!this.gaApp.features.get('flaconStart')) {
        return this.gaApp.redirectError({ statusCode: 404 })
      }

      switch (redirect.pageType) {
        case 'articles':
          return this.gaApp.services.articles.listing.loadData()

        case 'articles-filter':
          return this.gaApp.services.articles.listing.loadFilterArticles(
            redirect,
          )

        case 'article':
          this.gaApp.services.header.theme.setHeaderRegularColorScheme()
          return this.gaApp.services.articles.one.fetchArticleByUrl(
            redirect.url,
          )

        case 'streams':
          if (!this.gaApp.features.get('flaconUseStreamers')) {
            return this.gaApp.redirectError({ statusCode: 404 })
          }

          this.gaApp.services.header.theme.setHeaderRegularColorScheme()
          return this.gaApp.services.articles.streams.loadData()

        case 'preview':
          return this.gaApp.services.articles.one.fetchPreviewArticle(
            redirect.id,
          )

        case 'preview-listing':
          return this.gaApp.services.articles.listing.loadPreviewListing(
            redirect.id,
          )

        case 'stream':
          if (!this.gaApp.features.get('flaconUseStreamers')) {
            return this.gaApp.redirectError({ statusCode: 404 })
          }

          this.gaApp.services.header.theme.setHeaderRegularColorScheme()
          return this.gaApp.services.articles.stream.fetchDataByUrl(
            redirect.url,
          )

        default:
          return undefined
      }
    } catch (error) {
      this.gaApp.redirectError({ statusCode: 404, message: error })
    }
  }
}
