import { GaAnalyticsBaseModel } from '../ga-analytics-base-model/ga-analytics-base-model'

export class BreadcrumbClickModel extends GaAnalyticsBaseModel {
  constructor(data) {
    super('listing')

    const { lastHref, path } = data

    this.category = {
      url: lastHref,
      itemListName: path,
    }

    this.debug = {
      key: 'placements-breadcrumbs-item-click',
      data,
    }
  }
}
