import { ApiService } from './api.service'
import { DateService } from './date.service'
import { FiltersService } from './filters.service'
import { ListingService } from './listing.service'
import { LocationService } from './location.service'
import { MainService } from './main.service'
import { PageService } from './page.service'
import { PaginationService } from './pagination.service'
import { StoresService } from './stores.service'

export const services = (gaApp) => ({
  api: new ApiService(gaApp),
  date: new DateService(gaApp),
  filters: new FiltersService(gaApp),
  listing: new ListingService(gaApp),
  location: new LocationService(gaApp),
  main: new MainService(gaApp),
  page: new PageService(gaApp),
  pagination: new PaginationService(gaApp),
  stores: new StoresService(gaApp),
})
