import { log } from './ga-analytics.utils.js'

export class GaAnalytics {
  constructor() {
    this.api = null

    this.isInit = false
  }

  create(api, endpoints) {
    try {
      if (this.isInit) {
        log('Модуль уже проинициализирован', api)

        return false
      }

      this.api = api
      this.endpoints = endpoints

      this.isInit = true

      log('Инициализация прошла успешно', api)
    } catch (error) {
      log('Ошибка инициализации', error)

      throw error
    }
  }

  get product() {
    return {
      get: (data) => {
        const { url } = this.endpoints.getProduct()

        return this.api.request().post(url, data)
      },

      set: (data) => {
        const { url } = this.endpoints.setProduct()

        return this.api.request().post(url, data)
      },
    }
  }

  get listing() {
    return {
      get: (data) => {
        const { url } = this.endpoints.getListing()

        return this.api.request().post(url, data)
      },

      set: (data) => {
        const { url } = this.endpoints.setListing()

        return this.api.request().post(url, data)
      },
    }
  }
}
