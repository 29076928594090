import { BaseController } from '../base.controller/base.controller'

export class UserAnalytics extends BaseController {
  constructor(gaApp, core) {
    super(gaApp, core)

    this.init()
  }

  /**
   * Подписка на событие инита window.dataLayer
   */
  init() {
    const unsubscribeDataLayer = this.gaApp.eventBus.subscribe(
      'layer/analitycs/initDataLayer',
      () => {
        this.onUserAuthorized()

        unsubscribeDataLayer()
      },
    )

    /**
     * Подписка на событие авторизации пользователем
     */
    const unsubscribeAuth = this.gaApp.eventBus.subscribe(
      'module/auth/login',
      () => {
        this.onUserAuthorized()

        unsubscribeAuth()
      },
    )
  }

  onUserAuthorized() {
    const userId = this.gaApp.stores.user.main?.data?.id
    const isAuthorized = this.gaApp.stores.user.main.isAuthorized

    if (userId && isAuthorized) {
      window.dataLayer.push({
        user_id: userId,
      })
    }
  }
}
