import { VENDOR } from '../constants'
import { AdvcakeFacade } from '../modules/advcake.facade'
import { DigiAnalyticsFacade } from '../modules/digi-analytics.facade'
import { GaAnalyticsFacade } from '../modules/ga-analytics.facade'
import { GdeslonFacade } from '../modules/gdeslon.facade'
import { GtmFacade } from '../modules/gtm.facade'
import { MindboxFacade } from '../modules/mindbox.facade'
import { YandexMetrikaFacade } from '../modules/yandex-metrika.facade'

export class Analytics {
  constructor(gaApp, libs) {
    this.gaApp = gaApp

    this.libs = libs

    this.storage = {
      [VENDOR.GAA]: {},
    }

    this.state = {
      initialized: false,
    }
  }

  get all() {
    return {
      [VENDOR.YANDEX_METRIKA]: this[VENDOR.YANDEX_METRIKA],
      [VENDOR.GTM]: this[VENDOR.GTM],
      [VENDOR.MINDBOX]: this[VENDOR.MINDBOX],
      [VENDOR.GDESLON]: this[VENDOR.GDESLON],
      [VENDOR.GAA]: this[VENDOR.GAA],
      [VENDOR.ADVCAKE]: this[VENDOR.ADVCAKE],
      [VENDOR.DIGI]: this[VENDOR.DIGI],
    }
  }

  get special() {
    return {
      [VENDOR.GAA]: this[VENDOR.GAA],
      [VENDOR.DIGI]: this[VENDOR.DIGI],
    }
  }

  init(set) {
    Object.keys(set).forEach((key) => this[key].init())

    this.state.initialized = true
  }

  /**
   * Сторонние скрипты могут использовать window.dataLayer
   *
   * Проблема в том, что до тех пор, пока gtm не отправит первое событие,
   * window.dataLayer будет равен undefined, и может сломаться работа сторонних скриптов
   */
  initDataLayer() {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      store_country: this.gaApp.i18n.locale.country.toUpperCase(),
    })
    this.gaApp.eventBus.publish('layer/analitycs/initDataLayer')
  }

  /**
   * Можем инитить аналитику,
   * если она не отключена для конкретного роута
   * или не запущены автотесты
   */
  canStartInitAnalytic() {
    // если у роута отключена аналитика
    const isAnalyticDisabled =
      this.gaApp.services.app.router.isAnalyticDisabled(this.gaApp.route.path)

    // если включены автотесты
    const isAutomatedTesting =
      this.gaApp.isAutomatedTesting || this.gaApp.automatedTestingConfig

    return !isAnalyticDisabled && !isAutomatedTesting
  }

  /**
   * Проверяем права для инициализации всех видов аналитики.
   *
   * Пользователь может отклонить необязательные виды аналитики
   * если включена модалка для принятия кук.
   */
  hasPermissionToInitAll() {
    // пользователь принял соглашение использования кук
    const { isAccepted } = this.gaApp.stores.app.cookie.permission

    return !this.gaApp.features.get('modalCookiesPermission') || isAccepted
  }

  /**
   * Инициализируем аналитику, только на «разрешенных» роутах и
   * если нам не нужно спрашивать разрешение на использование кук
   * или разрешение у нас уже есть
   */
  initAllWithPermissionsChecks() {
    if (this.gaApp.isWebview) {
      return
    }

    // если можем инитить аналитику
    if (this.canStartInitAnalytic()) {
      // проверяем права и инитим все виды
      if (this.hasPermissionToInitAll()) {
        return this.init(this.all)
      }

      // иначе инитим только обязательную аналитику
      return this.init(this.special)
    }
  }

  get [VENDOR.GTM]() {
    return new GtmFacade(this.libs.gtm, this.gaApp)
  }

  get [VENDOR.MINDBOX]() {
    return new MindboxFacade(this.libs.mindbox, this.gaApp)
  }

  get [VENDOR.GDESLON]() {
    return new GdeslonFacade(this.libs.gdeslon, this.gaApp)
  }

  get [VENDOR.GAA]() {
    return new GaAnalyticsFacade(
      this.libs.gaAnalytics,
      this.gaApp,
      this.storage,
    )
  }

  get [VENDOR.ADVCAKE]() {
    return new AdvcakeFacade(this.libs.advcake, this.gaApp)
  }

  get [VENDOR.DIGI]() {
    return new DigiAnalyticsFacade(this.libs.digiAnalytics, this.gaApp)
  }

  get [VENDOR.YANDEX_METRIKA]() {
    return new YandexMetrikaFacade(this.gaApp)
  }
}
