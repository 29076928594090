export class MainRepository {
  constructor(gaApp, endpoints) {
    this.gaApp = gaApp

    this.endpoints = gaApp.api.endpoints(endpoints)
  }

  async getCmsContent(key) {
    const { url } = this.endpoints.cms(key)

    try {
      const response = await this.gaApp.api.request().get(url)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async fetchContactsInfo() {
    const { url } = this.endpoints.contacts()

    try {
      const response = await this.gaApp.api.request().get(url)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async launchQaSubscribe(params) {
    const { url } = this.endpoints.launchQaSubscribe()

    try {
      const response = await this.gaApp.api.request().post(url, params)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async skyengSubscribe(params) {
    const { url } = this.endpoints.skyengSubscribe()

    try {
      const response = await this.gaApp.api.request().post(url, params)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }
}
