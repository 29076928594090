<template>
  <svg fill="none" viewBox="0 0 19 19">
    <mask
      id="info-skyeng-copy-a"
      width="19"
      height="19"
      x="0"
      y="0"
      maskUnits="userSpaceOnUse"
      style="mask-type: alpha"
    >
      <path fill="#C2C9BD" d="M0 .743h18.118v18.118H0z" />
    </mask>
    <g mask="url(#info-skyeng-copy-a)">
      <path
        fill="#000"
        stroke="none"
        d="M3.774 17.351c-.415 0-.77-.148-1.066-.444a1.454 1.454 0 0 1-.443-1.066V5.273h1.51V15.84h8.303v1.51H3.774Zm3.02-3.02c-.415 0-.77-.147-1.066-.443a1.454 1.454 0 0 1-.444-1.066v-9.06c0-.414.148-.77.444-1.066a1.454 1.454 0 0 1 1.066-.443h6.794c.415 0 .77.148 1.067.443.295.296.443.652.443 1.067v9.059c0 .415-.148.77-.444 1.066a1.454 1.454 0 0 1-1.066.443H6.794Zm0-1.51h6.794V3.764H6.794v9.059Z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-info-skyeng-copy',
}
</script>
