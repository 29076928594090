import ar from './ar.json'
import en from './en.json'
import enQAOptions from './en-QA.options.json'
import ru from './ru.json'
import ruBY from './ru-BY.json'
import ruBYOptions from './ru-BY.options.json'
import ruKZ from './ru-KZ.json'
import ruKZOptions from './ru-KZ.options.json'
import ruRUOptions from './ru-RU.options.json'

export const locales = {
  'ru-RU': [ru, ruRUOptions],
  'ru-BY': [ru, ruBY, ruBYOptions],
  'ru-KZ': [ru, ruKZ, ruKZOptions],
  'ar-AE': ar,
  'ar-QA': ar,
  'en-AE': en,
  'en-QA': [en, enQAOptions],
}
