import { trimObjectStrings } from '@ga/utils'

export class LaunchQaService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  changeSuccessModalVisibility(value) {
    this.gaApp.stores.info.launchQa.form.successModalIsOpen = value
  }

  openSuccessModal() {
    this.changeSuccessModalVisibility(true)
  }

  closeSuccessModal() {
    this.changeSuccessModalVisibility(false)
  }

  async subscribe(onAlreadySubscribed) {
    try {
      this.gaApp.stores.info.launchQa.form.isLoading = true

      const { firstName, lastName, email } = trimObjectStrings(
        this.gaApp.stores.info.launchQa.form.fields,
      )

      await this.gaApp.services.info.api.launchQaSubscribe(
        firstName,
        lastName,
        email,
      )

      this.openSuccessModal()
      this.gaApp.analytics.modules.info.onFormSent()
    } catch (error) {
      console.error(error)
      const { alreadySubscribed } = error

      if (alreadySubscribed && onAlreadySubscribed) {
        onAlreadySubscribed()
      }
    } finally {
      this.gaApp.stores.info.launchQa.form.isLoading = false
    }
  }

  changeMenuModalVisibility(value) {
    this.gaApp.stores.info.launchQa.menuModalIsOpen = value
  }

  openMenuModal() {
    this.changeMenuModalVisibility(true)
  }

  closeMenuModal() {
    this.changeMenuModalVisibility(false)
  }

  changeDpaModalVisibility(value) {
    this.gaApp.stores.info.launchQa.dpaModalIsOpen = value
  }

  openDpaModal() {
    this.changeDpaModalVisibility(true)
  }

  closeDpaModal() {
    this.changeDpaModalVisibility(false)
  }
}
