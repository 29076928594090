import {
  ActionBuilder,
  AdvantageBuilder,
  BreadcrumbClickBuilder,
  CartBuilder,
  ClickPreviewMoreDetailsBuilder,
  CouponBuilder,
  FavoriteBuilder,
  GaAnalyticsProductGetBuilder,
  HowToBuilder,
  userInteractionBuilder,
  ViewBuilder,
} from '../../builders'
import { BrandClickBuilder } from '../../builders/brand-click.builder/brand-click.builder'
import { BaseController } from '../base.controller/base.controller'

export class PdpAnalytics extends BaseController {
  /**
   * При входе на страницу, делаем запрос за данными аналитики
   */
  async onPageInit(data) {
    const results = await this.get(GaAnalyticsProductGetBuilder, data)

    return results.reduce((accumulator, current) => {
      const { key, data } = current.value

      accumulator[key] = data
      return accumulator
    }, {})
  }

  /**
   * Просмотр страницы или превью версии.
   */
  onMount(product, analyticData) {
    this.onViewVariant(product, analyticData)
  }

  onViewVariant(product, analyticData) {
    const productData = this.getProductData(product)

    this.send(ViewBuilder, { ...productData, analyticData })
  }

  /**
   * Добавление продукта в корзину.
   */
  onAddToCart(product, analyticData) {
    const productData = this.getProductData({ ...product, addToCart: true })

    this.send(CartBuilder, { ...productData, analyticData })
  }

  /**
   * Удаление продукта из корзины.
   */
  onRemoveFromCart(product, analyticData) {
    const productData = this.getProductData({ ...product, addToCart: false })

    this.send(CartBuilder, { ...productData, analyticData })
  }

  /**
   * Добавление в избранное.
   */
  onAddToFavorites(product, analyticData) {
    const productData = this.getProductData(product)

    this.send(FavoriteBuilder, { ...productData, analyticData })
  }

  /**
   * Клики пользователя на интерактивных элементах страницы.
   */
  onAddAction(product) {
    const productData = this.getProductData(product)

    this.send(ActionBuilder, productData)
  }

  /**
   * При клике хлебную крошку в PDP
   */
  onBreadcrumbClick(breadcrumbs, gtmData) {
    const data = this.getBreadcrumbsData(breadcrumbs)

    this.send(BreadcrumbClickBuilder, { product: data, type: 'GAA' })
    this.send(BreadcrumbClickBuilder, { product: gtmData, type: 'GTM_4' })
  }

  onClickMoreDetails(product, analyticsData) {
    const productData = this.getProductData(product)

    this.send(ClickPreviewMoreDetailsBuilder, { productData, analyticsData })
  }

  onClickAdvantage(click, product) {
    const data = this.getProductData(product)
    data.click = click

    this.send(AdvantageBuilder, data)
  }

  onUserInteraction(data) {
    const productData = this.getProductData(data.product)

    this.send(userInteractionBuilder, { ...productData, ...data })
  }

  onClickBrand(brand, product) {
    if (brand.brandHref) {
      const data = this.getBrandTitleData(brand, product)

      this.send(BrandClickBuilder, data)
    }
  }

  // howto аналитика для длинных видео
  onActionHowTo(data) {
    const { id } = this.getProductData({ view: 'main' })
    this.send(HowToBuilder, { id, ...data })
  }

  onClickCoupon(product) {
    const { id } = this.getProductData(product)
    this.send(CouponBuilder, id)
  }
}
