export class SeoService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  getImageAttributes(product) {
    const { productType = '', brand = '', name = '', attributes } = product

    const title = attributes?.colors?.name
    const alt = `${productType} ${brand} ${name} ${title}`

    return {
      alt,
      title,
    }
  }
}
