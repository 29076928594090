export const mainEndpoints = {
  default: () => ({
    url: 'location',
  }),
  search: () => ({
    url: 'location/suggestions',
  }),
  geoSuggest: () => ({
    url: 'location/suggest',
  }),
  geoGeocode: () => ({
    url: 'location/geocode',
  }),
  geoReverseGeocode: () => ({
    url: 'location/reverse-geocode',
  }),
  geolocation: () => ({
    url: 'location/suggestion-by-coordinates',
  }),
  setAddress: () => ({
    url: 'location/add-address',
  }),
}
