import isEmpty from 'lodash/isEmpty'
import objectHash from 'object-hash'

export class ApiService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Детализированный заказ
   */

  async requestOrderDetail(id) {
    try {
      const response =
        await await this.gaApp.repositories.customer.main.fetchOrderDetailV3(id)

      this.gaApp.stores.customer.order.data = response.data
    } catch (error) {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('customer.orders.error.order'),
      )

      throw error
    }
  }

  async cancelOrder(id) {
    try {
      const response =
        await this.gaApp.repositories.customer.main.cancelOrderV3(id)

      this.gaApp.stores.customer.order.data = response.data
    } catch (error) {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('customer.order.error.cancel'),
      )
    }
  }

  async canCancelOrder(id) {
    try {
      const response =
        await this.gaApp.repositories.customer.main.canCancelOrderV3(id)

      const { canCancel } = response.data

      this.gaApp.stores.customer.order.canCancel = response.data

      if (canCancel) {
        this.gaApp.services.customer.api.cancelOrder(id)
      } else {
        this.gaApp.services.customer.dialog.openOrderCancelDefaultError()
      }
    } catch (error) {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('customer.order.error.cancel'),
      )
    }
  }

  /**
   *  Листинг заказов (список заказов с фильтрами)
   */
  async getOrdersListing() {
    this.gaApp.services.filters.main.resetState()

    const { data: filtersData } = await this.requestFiltersOrders()

    const query = this.gaApp.route.query

    const filtersDataNormalized = filtersData.filters.map((filter) => ({
      ...filter,
      key: filter.id,
    }))

    const filtersFromQuery =
      this.gaApp.services.filters.queryParse.getFiltersFromQuery(
        filtersDataNormalized,
        query,
      )

    this.gaApp.stores.filters.main.setFiltersSelected(filtersFromQuery)

    const page = this.gaApp.stores.customer.orders.lastViewedPage

    this.gaApp.stores.filters.main.setFiltersHash({
      filters: filtersFromQuery,
    })

    const response =
      await this.gaApp.repositories.customer.main.fetchOrdersListingV3({
        pageSize: 24,
        pageNumber: page,

        filters: filtersFromQuery,
      })

    const { orders, countPage, count, completedCount, countSelectedFilters } =
      response.data.orders

    const { analytics, filters, productsCount } = response.data.filters

    const filtersNormalized = filters.map((filter) => ({
      ...filter,
      key: filter.id,
    }))

    this.gaApp.stores.filters.main.setFilters(filtersNormalized)
    this.gaApp.stores.filters.main.setCountProductsFiltered(productsCount)
    this.gaApp.stores.filters.main.setAnalytics(analytics)

    this.gaApp.stores.customer.orders.completedCount = completedCount
    this.gaApp.stores.customer.orders.firstCompletedOrderIndex =
      count - completedCount
    this.gaApp.stores.customer.orders.count = count

    if (orders.length) {
      this.gaApp.stores.customer.orders.hasOrders = !!orders.length

      this.gaApp.services.customer.orders.setPage({
        number: page,
        orders: this.gaApp.services.customer.orders.normalizeOrders(orders),
      })
    }

    const setOrdersHash = () => {
      this.gaApp.stores.customer.orders.ordersHash = objectHash(
        {
          filters: filtersFromQuery,
        },
        { unorderedArrays: true },
      )
    }

    const setCountSelectedFilters = () =>
      this.gaApp.stores.filters.main.setCountSelectedFilters(
        countSelectedFilters,
      )

    const setPageCount = () => {
      this.gaApp.stores.customer.orders.totalPages = countPage
    }

    this.gaApp.services.app.scheduler.postTasks([
      { task: setOrdersHash },
      { task: setCountSelectedFilters },
      { task: setPageCount },
    ])
  }

  /**
   * Фильтры заказов
   */

  async requestFiltersOrders() {
    try {
      this.gaApp.stores.filters.main.setFiltersPending(true)

      const filtersSelected = this.gaApp.stores.filters.main.filtersSelected

      this.gaApp.stores.filters.main.setFiltersHash({
        filters: filtersSelected,
      })

      const response =
        await this.gaApp.repositories.customer.main.fetchFiltersV3({
          filters: filtersSelected,
        })

      const { analytics, filters, productsCount } = response.data

      const filtersNormalized = filters.map((filter) => ({
        ...filter,
        key: filter.id,
      }))

      this.gaApp.stores.filters.main.setFilters(filtersNormalized)
      this.gaApp.stores.filters.main.setCountProductsFiltered(productsCount)
      this.gaApp.stores.filters.main.setAnalytics(analytics)

      this.gaApp.stores.filters.main.setFiltersPending(false)

      return response
    } catch (error) {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('customer.orders.error.filters'),
      )

      this.gaApp.stores.filters.main.setFiltersPending(false)
    }
  }

  /**
   * Заказы
   */
  async requestOrders() {
    this.gaApp.services.filters.main.resetState()

    const page = this.gaApp.stores.customer.orders.lastViewedPage

    await Promise.all([
      this.fetchOrdersByPage({ page }),
      this.requestFiltersOrders(),
    ])
  }

  async fetchOrdersByPage({ page = 1 }) {
    try {
      const filtersSelected = this.gaApp.stores.filters.main.filtersSelected

      this.gaApp.stores.filters.main.setCountSelectedFilters(
        this.gaApp.stores.filters.main.getFiltersMainSelected.length,
      )

      const response =
        await this.gaApp.repositories.customer.main.fetchOrdersV3({
          pageSize: 24,
          pageNumber: page,

          filters: filtersSelected,
        })

      const { orders, countPage, count, completedCount, countSelectedFilters } =
        response.data

      this.gaApp.stores.customer.orders.completedCount = completedCount
      this.gaApp.stores.customer.orders.firstCompletedOrderIndex =
        count - completedCount
      this.gaApp.stores.customer.orders.count = count

      if (orders.length) {
        this.gaApp.stores.customer.orders.hasOrders = !!orders.length

        this.gaApp.services.customer.orders.setPage({
          number: page,
          orders: this.gaApp.services.customer.orders.normalizeOrders(orders),
        })
      }

      const setOrdersHash = () => {
        this.gaApp.stores.customer.orders.ordersHash = objectHash(
          {
            filters: filtersSelected,
          },
          { unorderedArrays: true },
        )
      }

      const setCountSelectedFilters = () =>
        this.gaApp.stores.filters.main.setCountSelectedFilters(
          countSelectedFilters,
        )

      const setPageCount = () => {
        this.gaApp.stores.customer.orders.totalPages = countPage
      }

      this.gaApp.services.app.scheduler.postTasks([
        { task: setOrdersHash },
        { task: setCountSelectedFilters },
        { task: setPageCount },
      ])
    } catch (error) {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('customer.orders.error.ordersPage'),
      )
    }
  }
  /**
   * Доставка по клику
   */

  async onDemandOrderDetailV3(id) {
    try {
      const response =
        await this.gaApp.repositories.customer.main.onDemandOrderDetailV3(id)

      this.gaApp.stores.customer.order.data = response.data
    } catch (error) {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('orders.error.onDemand'),
      )
    }
  }

  async onDemandOrderV3(id) {
    try {
      const response =
        await this.gaApp.repositories.customer.main.onDemandOrderV3(id)

      this.gaApp.stores.customer.orders.updatedOrder = response.data
      this.gaApp.services.customer.orders.updateOrders()
    } catch (error) {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('orders.error.onDemand'),
      )
    }
  }

  async getProfileInfo() {
    if (this.gaApp.features.get('profilePlaid')) {
      const { data } = await this.gaApp.repositories.customer.main.getInfo()
      this.gaApp.services.customer.edit.setFormData(data)
      this.gaApp.services.customer.edit.setAdditionalData(data)
    } else {
      const { data } = this.gaApp.stores.user.main

      this.gaApp.services.customer.edit.setFormData(data)
      this.gaApp.services.customer.edit.setAdditionalData(data)
    }
  }

  async saveProfileInfo(formData) {
    try {
      this.gaApp.stores.customer.edit.isSaving = true

      if (this.gaApp.features.get('profilePlaid')) {
        const { data } =
          await this.gaApp.repositories.customer.main.updateInfo(formData)

        this.gaApp.services.notification.main.open(
          this.gaApp.i18n.t('customer.edit.notification.success'),
        )

        await this.gaApp.services.user.api.fetchUserInfoFull()

        this.gaApp.services.customer.edit.setFormData(data)
        this.gaApp.services.customer.edit.setAdditionalData(data)

        this.gaApp.services.customer.edit.setWasSaved(true)

        return data
      } else {
        const response =
          await this.gaApp.repositories.customer.main.saveProfileInfo(formData)

        const responseMessage =
          !response.success && response.message
            ? response.message
            : this.gaApp.i18n.t('customer.edit.notification.success')

        this.gaApp.services.notification.main.open(responseMessage)

        await this.gaApp.services.user.api.fetchUserInfoFull()

        const { data } = this.gaApp.stores.user.main

        this.gaApp.services.customer.edit.setFormData(data)
        this.gaApp.services.customer.edit.setAdditionalData(data)

        this.gaApp.services.customer.edit.setWasSaved(true)

        return response.data
      }
    } catch (error) {
      const statusCode = error.statusCode
      const isErrorValidation = statusCode === 400
      const errorData = error.data?.data

      if (isErrorValidation) {
        this.gaApp.services.customer.edit.handleValidationError(errorData)
      } else {
        this.gaApp.services.notification.main.open(
          this.gaApp.i18n.t('customer.edit.notification.error'),
        )
      }

      return null
    } finally {
      this.gaApp.stores.customer.edit.isSaving = false
    }
  }

  /**
   * Обновление подписок
   * @param {object} body Измененное состояние подписок
   */
  async updateSubscriptionsSettings(body) {
    try {
      this.gaApp.stores.customer.settings.settingsPending = true

      let response

      // Если ФТ пледа включен
      if (this.gaApp.features.get('settingsPlaid')) {
        // Отправляем измененнное состояние подписок в плед
        const { data } =
          await this.gaApp.repositories.customer.main.updateSubscriptionsSettings(
            body,
          )

        response = data
      } else {
        // Иначе отправляем измененное состояние подписок в магу
        await this.gaApp.repositories.customer.main.setSettings(body)
        // Запрашиваем актуальную инфу пользака, чтобы получить обновленные значения полей подписок
        const { data } = await this.gaApp.services.user.api.fetchUserInfoFull()

        response = data
      }

      // Записываем актуальное состояние подписок в стор
      this.gaApp.services.customer.settings.setSubscriptionsSettings(response)

      // Если ФТ пледа вкл
      if (this.gaApp.features.get('settingsPlaid')) {
        // Показываем уведомление об успешном изменении подписок
        this.gaApp.services.notification.main.open(
          this.gaApp.i18n.t('customer.settings.updateSettingsSuccess'),
        )
      }
    } catch (error) {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('customer.settings.error.updateSettings'),
      )
    } finally {
      this.gaApp.stores.customer.settings.settingsPending = false
    }
  }

  async fetchLoyalty() {
    const { data } = await this.gaApp.repositories.customer.main.getLoyalty()

    const { bonus, title, currentLevel } = data

    if (isEmpty(bonus) || !title || !currentLevel) {
      throw new Error('Ошибка')
    }

    this.gaApp.services.customer.cards.setLoyalty(data)
  }

  async emailConfirm() {
    try {
      await this.gaApp.repositories.customer.main.emailConfirm()

      if (!this.gaApp.stores.customer.edit.isEmailConfirmModalOpened) {
        this.gaApp.services.customer.edit.openEmailConfirmModal()
      }

      this.gaApp.analytics.modules.giftCardsB2b.onMailConfirmed()
    } catch (error) {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('customer.edit.confirmEmail.error'),
      )
    }
  }

  /**
   * Получение подписок
   */
  async getSubscriptionsSettings() {
    let response

    // Если ФТ пледа вкл
    if (this.gaApp.features.get('settingsPlaid')) {
      // Получаем данные из пледа
      const { data } =
        await this.gaApp.repositories.customer.main.getSubscriptionsSettings()

      response = data
    } else {
      // Иначе вытаскиваем нужные поля из юзера
      response = this.gaApp.stores.user.main.data
    }

    // Записываем данные о подписках в стор
    this.gaApp.services.customer.settings.setSubscriptionsSettings(response)
  }
}
