<template>
  <svg fill="none" viewBox="0 0 9 13">
    <path
      stroke="#fff"
      stroke-linejoin="round"
      stroke-width="2.583"
      d="M7.333 11.667 2 6.333 7.333 1"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-info-skyeng-arrow-left',
}
</script>
