import { GaSelect } from '../select'

export const VIEW = {
  REGULAR: 'regular',
  FLAG_ONLY: 'flag-only',
}

// @vue/component
export default {
  name: 'ga-select-country',

  components: {
    GaSelect,
  },

  inheritAttrs: false,

  model: {
    prop: 'value',
    event: 'change',
  },

  props: {
    view: {
      type: String,
      default: VIEW.REGULAR,
      validator: (value) => Object.values(VIEW).includes(value),
    },
    noCountryCode: {
      type: Boolean,
      default: false,
    },
    noSelectedCountryCode: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    showSelectedCountryCode() {
      return !this.noSelectedCountryCode
    },
  },

  methods: {
    blur() {
      this.$refs.root.blur()
    },
  },
}
