import { GaAnalyticsBaseModel } from '../ga-analytics-base-model/ga-analytics-base-model'

export class GaAnalyticsProductModel extends GaAnalyticsBaseModel {
  constructor(product) {
    super('product')

    this.item = {
      itemId: product.id,
      itemListName: product.list,
      searchTerm: product.searchTerm,
    }

    this.debug = {
      key: 'search-modal-product',
      data: product,
    }
  }
}
