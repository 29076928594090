/*
 * TODO:
 *  - удалить fetchUserAvatar при удалении ФТ profilePlaid
 */
export class ApiService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async updateUserLanguage(body) {
    try {
      const { data } =
        await this.gaApp.repositories.user.main.updateUserLanguage(body)

      return data
    } catch (error) {
      return null
    }
  }

  async getProofAge() {
    try {
      const cacheData = this.gaApp.services.cache.main.getServerData('adult')

      const { data } =
        cacheData ||
        (await this.gaApp.repositories.user.main.getData({
          sections: ['adult_goods'],
        }))

      const { adultGoods, rawAdultGoods } = data

      if (!adultGoods || !rawAdultGoods) {
        return
      }

      this.gaApp.services.cache.main.saveAdult(rawAdultGoods)

      this.gaApp.stores.user.main.isAdult = adultGoods.isAdult
    } catch (error) {}
  }

  async getAddress() {
    const geoAddressesEnabled = this.gaApp.features.get(
      'customerAddressFromMagentoToPlaid',
    )

    return geoAddressesEnabled
      ? await this.getAddressV2()
      : await this.getAddressV1()
  }

  async getAddressV1() {
    try {
      const cacheData =
        this.gaApp.services.cache.main.getServerData('userAddress')

      const { data } =
        cacheData || (await this.gaApp.repositories.user.main.getAddress())

      const { addresses, selectedAddress } = data

      if (addresses) {
        this.gaApp.stores.user.main.addresses = addresses
      }

      if (selectedAddress) {
        this.gaApp.services.location.main.setLocation(selectedAddress)
      }
    } catch (error) {}
  }

  async getAddressV2() {
    try {
      const cacheData =
        this.gaApp.services.cache.main.getServerData('userAddress')

      const { data } =
        cacheData || (await this.gaApp.repositories.user.main.getAddressV2())

      const { addresses, selectedAddress } = data

      if (addresses) {
        this.gaApp.stores.user.main.addresses = addresses
      }

      if (selectedAddress) {
        this.gaApp.services.location.main.setLocation(selectedAddress)
      }
    } catch (error) {}
  }

  async setProofAge() {
    try {
      const { data } = await this.gaApp.repositories.user.main.setProofAge()

      const { rawAdultGoods } = data

      if (!rawAdultGoods) {
        return
      }

      this.gaApp.services.cache.main.saveAdult(rawAdultGoods)
    } catch (error) {}
  }

  async fetchUserInfoShort() {
    const { data } = await this.gaApp.repositories.user.main.getInfoShort()
    this.gaApp.services.user.main.setUser({ data })

    return { data }
  }

  async fetchUserInfoFull() {
    try {
      const cacheData =
        this.gaApp.services.cache.main.getServerData('userInfoFull')

      const { data } =
        cacheData || (await this.gaApp.repositories.user.main.getInfoFull())

      const { avatar, ...userData } = data

      if (this.gaApp.features.get('profilePlaid')) {
        this.gaApp.services.user.main.setUser({ data })
      } else {
        this.gaApp.services.user.main.setUser({ data: userData })
      }

      return { data }
    } catch (error) {}
  }

  async fetchUserInfoFullWithToken() {
    try {
      const { data } = await this.gaApp.repositories.user.main.getInfoFull()

      return { data }
    } catch (error) {}
  }

  async fetchUserAvatar() {
    try {
      const cacheData = this.gaApp.services.cache.main.getServerData('avatar')

      const { data } =
        cacheData || (await this.gaApp.repositories.user.main.getAvatar())

      this.gaApp.services.user.main.setUser({ data })

      return { data }
    } catch (error) {}
  }
}
