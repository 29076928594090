import { PROMOTION_PATH } from '../constants/keys'
import { PAGE_TYPE } from '../constants/pages'

export const modulePromoSsrRoutes = [
  {
    moduleType: 'promo',
    pageType: PAGE_TYPE.LISTING_CLIENT_DAYS,
    path: PROMOTION_PATH.CLIENT_DAYS,
    meta: {
      authRequired: false,
      analyticDisabled: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/promo/page/listing' */
        '~/modules/promo/components/pages/listing.vue'
      ),
  },
  {
    moduleType: 'promo',
    pageType: PAGE_TYPE.LISTING_PROMO,
    path: PROMOTION_PATH.PROMO,
    meta: {
      authRequired: false,
      analyticDisabled: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/promo/page/listing' */
        '~/modules/promo/components/pages/listing.vue'
      ),
  },
  {
    moduleType: 'promo',
    pageType: PAGE_TYPE.MAIN,
    path: PROMOTION_PATH.INFO,
    meta: {
      authRequired: false,
      analyticDisabled: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/promo/page/main' */
        '~/modules/promo/components/pages/main.vue'
      ),
  },
  {
    moduleType: 'promo',
    pageType: PAGE_TYPE.MAIN,
    path: PROMOTION_PATH.INFO_WITH_SLUG,
    meta: {
      authRequired: false,
      analyticDisabled: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/promo/page/main' */
        '~/modules/promo/components/pages/main.vue'
      ),
  },
]

export const routes = [...modulePromoSsrRoutes]
