import {
  BreadcrumbClickBuilder,
  PageViewBuilder,
  ProductCartBuilder,
  ProductClickBuilder,
  ProductFavoriteBuilder,
  ProductViewBuilder,
  TitleClickBuilder,
  WidgetAddToCartBuilder,
  WidgetClickBuilder,
  WidgetRenderBuilder,
  WidgetViewBuilder,
} from '../../builders'
import { BaseController } from '../base.controller/base.controller'

export class PlacementsAnalytics extends BaseController {
  /**
   * Клик по товару
   */
  onProductClick(product) {
    const data = this.getProductData(product)

    this.send(ProductClickBuilder, data)
  }

  /**
   * Добавление продукта в корзину.
   */
  onAddToCart(product) {
    const data = this.getProductData(product)

    this.send(ProductCartBuilder, { ...data, addToCart: true })
  }

  /**
   * Добавление продукта в избранное.
   */
  onAddToFavorite(product) {
    const data = this.getProductData(product)

    this.send(ProductFavoriteBuilder, data)
  }

  /**
   * Просмотр товаров
   */
  onProductView(data, componentId, chunkSize) {
    const product = this.getProductData(data)

    // отмечаем продукты как просмотренные
    if (
      !this.gaApp.services.placements.viewedProducts.getViewItem(
        componentId,
        data,
      )
    ) {
      this.gaApp.services.placements.viewedProducts.setViewed(data, componentId)
    }

    // получаем чанк просмотренных продуктов и не отправленных в аналитику
    const sentChunk = this.gaApp.services.placements.viewedProducts
      .getLastChunk(data, componentId, chunkSize)
      .map((product) => this.getProductData(product))

    const products = this.gaApp.services.placements.viewedProducts
      .getAllProducts(componentId)
      .map((product) => this.getProductData(product))

    // если в чанке есть что отправить - отправляем
    if (sentChunk.length) {
      this.send(ProductViewBuilder, {
        product,
        chunk: sentChunk,
        products,
      })
    }
  }

  /**
   * При уходе со страницы
   */
  onDestroyProductView(componentId, chunkSize) {
    /**
     * Если при уходе со страницы, юзер увидел 1-4 товара,
     * то нужно отправить об этом событие в аналитику
     */
    const products =
      this.gaApp.services.placements.viewedProducts.getAllProducts(componentId)
    const productsLength = products.length
    const productsLengthRest = productsLength % chunkSize
    const lengthRestDiff = productsLength - productsLengthRest

    if (productsLengthRest && productsLengthRest < chunkSize) {
      const chunk = products
        .slice(lengthRestDiff)
        .map((product) => this.getProductData(product))

      this.send(ProductViewBuilder, {
        product: chunk[0],
        chunk,
        products,
      })
    }

    // Очищаем список просмотренных продуктов
    this.gaApp.services.placements.viewedProducts.clearProducts(componentId)
  }

  /**
   * При входе на страницу PLP
   */
  onMounted(data) {
    this.referer = this.gaApp.route.query?.referer
    this.send(PageViewBuilder, data)
  }

  /**
   * При клике хлебную крошку в PLP
   */
  onBreadcrumbClick(breadcrumbs) {
    const data = this.getBreadcrumbsData(breadcrumbs)
    this.send(BreadcrumbClickBuilder, data)
  }

  /**
   * При клике тайтла в слайдере
   */
  onTitleClick({ itemListId, itemListName, url }) {
    this.send(TitleClickBuilder, { itemListId, itemListName, url })
  }

  /**
   * Отрисовка блока рекомендаций на странице
   */
  onWidgetRender(data) {
    this.send(WidgetRenderBuilder, data)
  }

  /**
   * Просмотр блока рекомендаций
   */
  onWidgetView(data) {
    this.send(WidgetViewBuilder, data)
  }

  /**
   * Клик на карточке блока рекомендаций
   */
  onWidgetClick({ product, placement, index }) {
    this.send(WidgetClickBuilder, {
      product,
      placement,
      index,
    })
  }

  /**
   * Добавление в корзину из карточки блока рекомендаций
   */
  onWidgetAddToCart({ product, placement }) {
    this.send(WidgetAddToCartBuilder, {
      product: this.getProductData(product),
      placement,
    })
  }
}
