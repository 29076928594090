import { computed, ref, watch } from 'vue'

/**
 * Формирует необходимые поля для работы с формой
 *
 * @param {Object} $gaApp - контекст приложения
 * @param {Ref<string>} phone - значение phone из props
 * @param {Ref<string>} phoneFormatted - значение phoneFormatted из props
 * @param {Ref<string>} phoneCode - значение phoneCode из props
 * @param {Function} emit - emit функция контекста
 */
export function useValues($gaApp, phone, phoneFormatted, phoneCode, emit) {
  // Страна
  const countryInternal = computed({
    get() {
      return $gaApp.i18n.locale.country
    },

    set(value) {
      emit('update:country', value)
    },
  })

  const countries = computed(() =>
    $gaApp.stores.app.common.stores.map(
      // flag - флаг страны
      // text - название страны, например text: 'Россия'
      // code - код страны, например code: 'ru'
      ({ flag, text, code }) => ({
        flag,
        text,
        country: code,
        value: code,
      }),
    ),
  )

  // Телефон
  const phoneInternal = ref(phone.value)
  const phoneFormattedInternal = ref(phoneFormatted.value)

  watch(phoneInternal, (value) => {
    emit('update:phone', value)
  })

  // Код телефона
  const phoneCodes = computed(() => $gaApp.stores.app.common.phoneCodes)

  const phoneCodeInternal = ref(phoneCode.value)
  const phoneMetadata = computed(() => {
    return phoneCodes.value.find(
      ({ value }) => value === phoneCodeInternal.value,
    )
  })

  watch(phoneCodeInternal, (value) => {
    emit('update:phone-code', value)
  })

  return {
    countryInternal,
    countries,
    phoneInternal,
    phoneFormattedInternal,
    phoneCodeInternal,
    phoneMetadata,
    phoneCodes,
  }
}
