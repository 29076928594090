import { PAGE_TYPE } from '../consts/pages'

export const moduleInfoSsrRoutes = [
  {
    moduleType: 'info',
    pageType: PAGE_TYPE.DOCUMENTS,
    path: '/documents',
    meta: {
      addressDisabled: true,
      authRequired: false,
      analyticDisabled: false,
      webview: true,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/info/page/documents' */
        '~/modules/info/components/pages/cms-page-with-accordion.vue'
      ),
  },
  {
    moduleType: 'info',
    pageType: PAGE_TYPE.DOCUMENTS,
    path: '/dostavka-i-oplata',
    meta: {
      addressDisabled: true,
      authRequired: false,
      analyticDisabled: false,
      webview: true,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/info/page/delivery' */
        '~/modules/info/components/pages/cms-page-with-accordion.vue'
      ),
  },
  {
    moduleType: 'info',
    pageType: PAGE_TYPE.DOCUMENTS,
    path: '/dostavka-i-oplata-mobile',
    meta: {
      addressDisabled: true,
      authRequired: false,
      analyticDisabled: false,
      webview: true,
      webviewOnly: true,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/info/page/delivery-mobile' */
        '~/modules/info/components/pages/cms-page-with-accordion.vue'
      ),
  },
  {
    moduleType: 'info',
    pageType: PAGE_TYPE.CONTACTS,
    path: '/contacts',
    meta: {
      addressDisabled: true,
      authRequired: false,
      analyticDisabled: false,
      webview: true,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/info/page/contacts' */ '~/modules/info/components/pages/contacts.vue'
      ),
  },
  {
    moduleType: 'info',
    pageType: 'info/loyalty',
    path: '/loyalty',
    meta: {
      addressDisabled: true,
      authRequired: false,
      analyticDisabled: false,
      webview: true,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/info/page/loyalty' */
        '~/modules/info/components/pages/loyalty.vue'
      ),
  },
  {
    moduleType: 'info',
    pageType: 'info/launch-qa',
    path: '/soon',
    meta: {
      authRequired: false,
      analyticDisabled: false,
      webview: true,
      headerVisible: false,
      footerVisible: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/info/page/launch-qa' */
        '~/modules/info/components/pages/launch-qa.vue'
      ),
  },
  {
    moduleType: 'info',
    pageType: 'info-launch-qa-email-confirm',
    path: '/soon/email-confirmed',
    meta: {
      authRequired: false,
      analyticDisabled: false,
      webview: true,
      headerVisible: false,
      footerVisible: false,
    },
    component: () =>
      import('~/modules/info/components/pages/launch-qa-email-confirmed.vue'),
  },
  {
    moduleType: 'info',
    pageType: 'info-launch-qa-email-updated',
    path: '/soon/email-update',
    meta: {
      authRequired: false,
      analyticDisabled: false,
      webview: true,
      headerVisible: false,
      footerVisible: false,
    },
    component: () =>
      import('~/modules/info/components/pages/launch-qa-email-updated.vue'),
  },
  {
    moduleType: 'info',
    pageType: 'info/app',
    path: '/app',
    meta: {
      addressDisabled: true,
      authRequired: false,
      analyticDisabled: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/info/page/app' */
        '~/modules/info/components/pages/app.vue'
      ),
  },
  {
    moduleType: 'info',
    pageType: 'info/lrp-effaclar',
    path: '/problem-skin',
    meta: {
      addressDisabled: true,
      authRequired: false,
      analyticDisabled: false,

      headerVisible: false,
      footerVisible: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/info/page/lrp-effaclar' */
        '~/modules/info/components/pages/lrp-effaclar.vue'
      ),
  },
  {
    moduleType: 'info',
    pageType: PAGE_TYPE.FAQ,
    meta: {
      addressDisabled: true,
      authRequired: false,
      analyticDisabled: false,
      webview: true,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/info/page/faq' */
        '~/modules/info/components/pages/cms-page-with-accordion.vue'
      ),
  },
  {
    moduleType: 'info',
    pageType: 'info/back-to-school',
    path: '/lp/back-to-school',
    meta: {
      authRequired: false,
      analyticDisabled: false,
      headerVisible: false,
      footerVisible: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/info/page/back-to-school' */
        '~/modules/info/components/pages/back-to-school.vue'
      ),
  },
  {
    moduleType: 'info',
    pageType: 'info/skyeng',
    path: '/lp/learn-english',
    meta: {
      authRequired: false,
      analyticDisabled: false,
      headerVisible: false,
      footerVisible: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/info/page/skyeng' */
        '~/modules/info/components/pages/skyeng.vue'
      ),
  },
]

export const routes = [...moduleInfoSsrRoutes]
