import isEqual from 'lodash/isEqual'

export class FiltersService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  setFiltersData(data) {
    const { analytics, filters, productsCount } = data

    const filtersNormalized = filters.map((filter) => ({
      ...filter,
    }))

    this.gaApp.stores.filters.main.setFilters(filtersNormalized)
    this.gaApp.stores.filters.main.setCountProductsFiltered(productsCount)
    this.gaApp.stores.filters.main.setAnalytics(analytics)

    this.gaApp.stores.filters.main.setFiltersPending(false)
  }

  areHashesEqual() {
    const { filtersHash } = this.gaApp.stores.filters.main
    const { plp } = this.gaApp.stores.purchased

    return isEqual(filtersHash, plp.productsHash)
  }

  async refreshProducts({ select }) {
    this.gaApp.stores.purchased.plp.pages = []

    await Promise.all([
      this.gaApp.services.filters.main.pageNumber.updateFilter(1),

      this.gaApp.services.filters.main.updateFilter({
        filter: { ...select },
      }),

      this.gaApp.services.purchased.plp.fetchProducts(),
    ])
  }
}
