export const endpoints = {
  cms: (key) => ({
    url: `info/${key}`,
  }),
  contacts: () => ({
    url: 'info/contacts',
  }),
  launchQaSubscribe: () => ({
    url: 'info/launch-qa/subscribe',
  }),
  skyengSubscribe: () => ({
    url: 'info/skyeng/subscribe',
  }),
}
