export class GtmGa4ProductBaseModel {
  constructor(product) {
    if (!product) {
      throw new Error('product is required')
    }

    const { id, name, brand, list, price, variant, breadcrumbs, position } =
      product

    if (breadcrumbs) {
      breadcrumbs.slice(1).forEach((category, index) => {
        if (index) {
          this[`item_category${index + 1}`] = category.text
        } else {
          this.item_category = category.text
        }
      })
    }

    if (variant) {
      this.item_variant = variant
    }

    this.item_name = `${brand} ${name}`
    this.item_id = id
    this.price = price
    this.item_brand = brand
    this.item_list_name = list
    this.index = position
  }
}
