import { FLACON_STREAM_URL, PAGE_TYPE } from '../constants'

export class PageService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  getData(redirect) {
    try {
      switch (redirect.pageType) {
        case PAGE_TYPE.main:
          return this.gaApp.services.streamerce.main.loadStreams()
        case PAGE_TYPE.mainOld:
          return this.gaApp.redirect(FLACON_STREAM_URL)
        default:
          return undefined
      }
    } catch (error) {
      this.gaApp.redirectError({ statusCode: 500, message: error.message })
    }
  }
}
