export const BLOCK_TYPES = {
  LOGO: 'logo',
  BANNER: 'banner',
  SLIDER: 'slider',
  PRODUCTS: 'products',
  LISTING: 'listing',
  VIDEO_SLIDER: 'videoSlider',
  CATEGORIES: 'categoriesTiles',
  CATEGORY: 'category',
}

export const SLOT_COMPONENTS = {
  [BLOCK_TYPES.LOGO]: 'ga-brandzone-slot-logo',
  [BLOCK_TYPES.BANNER]: 'ga-brandzone-slot-banner',
  [BLOCK_TYPES.SLIDER]: 'ga-brandzone-slot-slider',
  [BLOCK_TYPES.LISTING]: 'ga-brandzone-slot-listing',
  [BLOCK_TYPES.PRODUCTS]: 'ga-brandzone-slot-products',
  [BLOCK_TYPES.VIDEO_SLIDER]: 'ga-brandzone-slot-video-slider',
  [BLOCK_TYPES.CATEGORIES]: 'ga-brandzone-slot-categories-tiles',
  [BLOCK_TYPES.CATEGORY]: 'ga-brandzone-slot-category',
}

export const SLOT_COMPONENTS_FEATURE = {
  [BLOCK_TYPES.VIDEO_SLIDER]: 'bzWidgetHowToLongVideo',
  [BLOCK_TYPES.CATEGORY]: 'bzWidgetCategory',
}

export const BANNER_TYPES = {
  ABOUT_BRAND: 'aboutBrand',
  FULL_LENGTH: 'bannerFullLength',
  LEFT_RIGHT: 'bannerLeftRight',
  WITH_BORDERS: 'bannerWithBorders',
}

export const SLOT_BANNER = {
  [BANNER_TYPES.FULL_LENGTH]: 'ga-banner-type-1',
  [BANNER_TYPES.ABOUT_BRAND]: 'ga-banner-type-2',
  [BANNER_TYPES.WITH_BORDERS]: 'ga-banner-type-3',
  [BANNER_TYPES.LEFT_RIGHT]: 'ga-banner-type-6',
}

export const HEADER_MODE = {
  FIXED: 'fixed',
  SIMPLE: 'simple',
  STICKY: 'sticky',
  HEADER: 'header',
  LAYOUT_PREPEND: 'layout-prepend',
}

export const CHUNK_SIZE_ANALYTICS = 1
export const LISTING_MAX_PAGE_INFINITE_LOADER = 5
export const DEFAULT_BACKGROUND_COLOR = '#fff'
export const DEFAULT_CONTRAST_COLOR = '#000'

export const INITIAL_UNDEFER_COUNT = 2

export const LANDING = {
  DIOR: 'dior',
}

export const DISCLAIMER_MODS = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal',
}

export const DISCLAIMER_SIZES = {
  XXS: 'xxs',
  XS: 'xs',
  S: 's',
}
