export class LangSwitcherService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  get currentStore() {
    return this.gaApp.services.app.main.currentStoreContent
  }

  get availableLanguages() {
    return this.gaApp.stores.app.common.convertedLanguages.filter(
      (lang) => lang.code !== this.gaApp.i18n.locale.code,
    )
  }

  get allLanguages() {
    return this.gaApp.stores.app.common.convertedLanguages
  }

  get currentLanguage() {
    return this.gaApp.stores.app.common.convertedLanguages.find(
      (lang) => lang.code === this.gaApp.i18n.locale.code,
    )
  }

  get hasAvailableLanguages() {
    return !!this.availableLanguages.length
  }

  // Достаем из мапы код языка, который соответствует коду в массиве languages
  getApiLanguageCode(code) {
    return this.gaApp.stores.app.common.initialLanguagesCodeMap[code] || code
  }

  switchLocale(code) {
    this.gaApp.i18n.setLocaleCookie(code)
    this.gaApp.eventBus.publish('module/app/lang-switcher/locale-changed', code)
  }

  openModal() {
    this.gaApp.stores.app.langSwitcher.modal.opened = true
  }

  closeModal() {
    this.gaApp.stores.app.langSwitcher.modal.opened = false
  }
}
