import { doubleRAF } from '@ga/shared-browser'

export class MainService {
  constructor(gaApp) {
    this.gaApp = gaApp
    this.loginUnsubscriber = null
  }

  /**
   * при событии login
   * запрашиваем избранные бренды,
   * если пользователь находится на странице брендов
   */
  subscribeToLogin() {
    if (this.gaApp.stores.user.main.isAuthorized) {
      return
    }

    this.loginUnsubscriber = this.gaApp.eventBus.subscribe(
      'module/auth/login',
      ({ meta } = {}) => {
        doubleRAF(() => {
          if (!meta?.redirect) {
            this.gaApp.features.get('newBrands')
              ? this.gaApp.services.brands.api.getBrands()
              : this.gaApp.services.brands.api.getFavorites()
          }
        })
      },
    )
  }

  unsubscribeFromLogin() {
    if (this.loginUnsubscriber) {
      this.loginUnsubscriber()
      this.loginUnsubscriber = null
    }
  }

  /**
   * Меняет значение флага, указывающего на процесс прокрутки страницы
   */
  setPageScrolling(value) {
    this.gaApp.stores.brands.main.isPageScrolling = value
  }

  /**
   * Меняет значение флага, указывающего на процесс навигации по алфавитному указателю
   */
  setBlocksScrolling(value) {
    this.gaApp.stores.brands.main.isBlocksScrolling = value
  }

  reset() {
    this.gaApp.stores.brands.main.$reset()
  }
}
