import { computed, reactive, ref, toRefs, unref } from 'vue'

import { useBem } from '@ga/bem'
import { scrollLockScrollable } from '@ga/shared-directives'
import { getId } from '@ga/utils'

import { GaFocusTrap } from '../../focus-trap'
import { GaPortal } from '../../portal'
import { GaModalBackButton } from '../back-button'
import { GaModalCloseButton } from '../close-button'
import { GaModalStickyHeader } from '../sticky-header'

import {
  BACKGROUND_COLOR_DEFAULT,
  CLOSE_BUTTON_TYPE,
  POSITION,
  TRANSITION,
} from './scripts/const'
import {
  useCloseTriggers,
  useFocusTrap,
  useHeader,
  useModalAutomat,
  useOuter,
  useOverlay,
  useRoot,
  useScroll,
  useShowParams,
  useTransition,
} from './scripts/use'

// @vue/component
export default {
  name: 'ga-modal',

  components: {
    GaPortal,
    GaFocusTrap,
    GaModalStickyHeader,
    GaModalBackButton,
    GaModalCloseButton,
  },

  directives: {
    scrollLockScrollable,
  },

  model: {
    prop: 'opened',
    event: 'toggleOpened',
  },

  props: {
    className: {
      type: [String, Array],
      default: null,
    },
    closeButtonClassName: {
      type: [String, Array],
      default: null,
    },
    overlayClassName: {
      type: [String, Array],
      default: null,
    },
    opened: {
      type: Boolean,
      required: true,
    },
    locked: {
      type: Boolean,
      default: false,
    },
    disableTransitions: {
      type: Boolean,
      default: false,
    },
    portalEnabled: {
      type: Boolean,
      default: true,
    },
    portalTarget: {
      type: String,
      default: null,
    },
    noOverlay: {
      type: Boolean,
      default: false,
    },
    transparentOverlay: {
      type: Boolean,
      default: false,
    },
    initialFocus: {
      type: Function,
      default: () => null,
    },
    noCloseAction: {
      type: Boolean,
      default: false,
    },
    noScroll: {
      type: Boolean,
      default: false,
    },
    fullwidth: {
      type: Boolean,
      default: false,
    },
    fullheight: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: POSITION.RIGHT,
      validator: (value) => Object.values(POSITION).includes(value),
    },
    transitionOpening: {
      type: String,
      default: TRANSITION.SLIDE,
      validator: (value) => Object.values(TRANSITION).includes(value),
    },
    transitionClosing: {
      type: String,
      default: TRANSITION.SLIDE,
      validator: (value) => Object.values(TRANSITION).includes(value),
    },
    transitionOpeningDelay: {
      type: Number,
      default: null,
    },
    transitionClosingDelay: {
      type: Number,
      default: null,
    },
    headerShowFrom: {
      type: Number,
      default: 150,
    },

    backgroundColor: {
      type: String,
      default: BACKGROUND_COLOR_DEFAULT,
    },

    zIndex: {
      type: Number,
      default: 5000,
    },

    closeButtonType: {
      type: String,
      default: CLOSE_BUTTON_TYPE.CROSS,
    },

    immediateRender: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit, slots }) {
    const modal = ref()
    const inner = ref()

    const refs = reactive({
      modal,
      inner,
    })

    const {
      opened,
      locked,
      fullwidth,
      fullheight,
      position,
      transitionOpening,
      transitionClosing,
      transitionOpeningDelay,
      transitionClosingDelay,
      noScroll,
      className,
      headerShowFrom,
      backgroundColor,
      zIndex,
      closeButtonType,
      immediateRender,
    } = toRefs(props)

    const closeButtonComponent = computed(() => {
      return unref(closeButtonType) === CLOSE_BUTTON_TYPE.CROSS
        ? 'ga-modal-close-button'
        : 'ga-modal-back-button'
    })

    const b = useBem()

    const { state, open, close, transitionHooks } = useModalAutomat(
      opened,
      locked,
      emit,
    )

    const { visible, displayed, rendered } = useShowParams(
      opened,
      state,
      immediateRender,
    )

    const { focusTrapped, fallbackFocus } = useFocusTrap(state, refs)

    const { onOverlayClick, onEscapeKeyDown, onCloseActionClick } =
      useCloseTriggers(close, focusTrapped)

    const { transitionStyles, transitionMods, transitionProps } = useTransition(
      state,
      transitionOpening,
      transitionClosing,
      transitionOpeningDelay,
      transitionClosingDelay,
      position,
      fullwidth,
      b,
    )

    const {
      scrollPosition,
      onScroll,
      scrollTo,
      getScrollHeight,
      getScrollTop,
    } = useScroll(visible, noScroll, refs, emit)

    const { hasHeader, headerShown } = useHeader(
      visible,
      scrollPosition,
      headerShowFrom,
      slots,
      emit,
    )

    const { rootClass, rootStyles } = useRoot(
      className,
      position,
      noScroll,
      transitionMods,
      transitionStyles,
      zIndex,
      hasHeader,
      headerShown,
      b,
    )

    const { overlayStyles } = useOverlay(transitionStyles)
    const { outerStyles } = useOuter(
      fullwidth,
      fullheight,
      transitionStyles,
      backgroundColor,
    )

    return {
      closeButtonComponent,
      b,
      uuid: getId(),
      displayed,
      rendered,
      open,
      close,
      transitionHooks,
      focusTrapped,
      fallbackFocus,

      onOverlayClick,
      onEscapeKeyDown,
      onCloseActionClick,

      transitionProps,

      rootClass,
      rootStyles,

      overlayStyles,

      outerStyles,

      onScroll,

      headerShown,

      modal,
      inner,

      // публичные методы
      scrollTo,
      getScrollHeight,
      getScrollTop,
    }
  },
}
