export class PageService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async getData(redirect) {
    try {
      switch (redirect.pageType) {
        case 'digital':
          return await this.getDigitalConfig()

        case 'email-confirm':
          return await this.confirmEmail()

        case 'plastic':
          return await this.getPlasticConfig()

        case 'b2b-receive':
          return await this.routeReceivePage(redirect)
      }
    } catch (error) {
      this.gaApp.redirectError({ statusCode: 500, message: error })
    }
  }

  /**
   * Подтверждение адреса электронной почты
   */
  confirmEmail() {
    return this.gaApp.services.giftCardsB2b.api.emailConfirm(
      this.gaApp.route?.query?.code,
    )
  }

  /**
   * Получение конфигурации для оформления КЭПК
   */
  async getDigitalConfig() {
    await this.gaApp.services.giftCardsB2b.api.getDigitalConfig()

    if (!this.gaApp.stores.giftCardsB2b.digital.sets.items.length) {
      this.gaApp.services.giftCardsB2b.digitalSets.addSet()
    }
  }

  /**
   * Получение конфигурации для оформления КЭПК
   */
  async getPlasticConfig() {
    await this.gaApp.services.giftCardsB2b.api.getPlasticConfig()
  }

  /**
   * Перенаправление на страницу получения подарочной карты
   * @param {object} redirect данные редиректа страницы
   * @param {object} [redirect.id] идентификатор подарочной карты
   */
  redirectToReceive({ id }) {
    return this.gaApp.redirect(`/cards/receive/${id}`)
  }

  /**
   * Определяет какую страницу отобразить для пользователя опираясь на значение фича-тогла `giftCardsB2bReceivePage`
   * @param {object} redirect данные редиректа страницы
   */
  routeReceivePage(redirect) {
    const isPageEnabled = this.gaApp.features.get('giftCardsB2bReceivePage')

    if (!isPageEnabled) {
      return this.redirectToReceive(redirect)
    }

    return this.gaApp.services.giftCards.page.getReceivePageData(redirect)
  }
}
