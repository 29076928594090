import { BREAKPOINT, POSITION, SIZE } from '../../constants'

export class MainService {
  constructor(gaApp) {
    this.gaApp = gaApp

    this.subscribeAppInit()
  }

  subscribeAppInit() {
    if (this.gaApp.isWebview) return

    const eventBus = this.gaApp.eventBus

    eventBus.subscribe('module/app/init', () => {
      this.subscribeEvents()
    })
  }

  /**
   * Тикер живет, пока живет приложение,
   * отписка не нужна
   */
  subscribeEvents() {
    const refreshTicker = this.refreshTicker.bind(this)
    const onPageMounted = this.onPageMounted.bind(this)
    const updateParams = this.updateTickerParemeters.bind(this)

    const eventBus = this.gaApp.eventBus
    eventBus.subscribe('module/app/router/page-created', updateParams)
    eventBus.subscribe('module/app/router/page-mounted', onPageMounted)

    eventBus.subscribe('module/auth/login', refreshTicker)
    eventBus.subscribe('module/auth/logout', refreshTicker)
  }

  onPageMounted() {
    this.refreshTicker()
    this.resetAnalytics()
  }

  resetAnalytics() {
    this.gaApp.analytics.modules.ticker.clearviewedTickerIds()
  }

  async refreshTicker() {
    this.resetTicker()

    try {
      const { pageType, moduleType, entityId, tickerPosition } =
        this.gaApp.stores.ticker.main.parameters

      const ticker = await this.gaApp.services.ticker.api.requestTicker({
        pageType,
        moduleType,
        entityId,
      })

      this.setTicker(ticker)
      this.setTickerPosition(tickerPosition)

      this.gaApp.services.ticker.ribbon.init(ticker?.data, ticker?.duration)
    } catch (error) {
      const errorApm = new Error(`Ошибка рефреша тикера`)
      errorApm.errorData = JSON.stringify(error)
      this.gaApp.services.app.apm.captureError(errorApm)
    }
  }

  updateTickerParemeters(data) {
    const pageType = data.redirect?.pageType ?? data.pageType
    const moduleType = data.redirect?.moduleType ?? data.moduleType
    const entityId = data.redirect?.entityId
    const tickerPosition = data.meta.tickerPosition ?? POSITION.BOTTOM

    this.gaApp.stores.ticker.main.parameters = {
      pageType,
      moduleType,
      entityId,
      tickerPosition,
    }
  }

  /**
   * Сброс тикера
   */
  resetTicker() {
    this.setTicker(null)
    this.setTickerGap(null)
    this.setTickerPosition(null)

    this.gaApp.services.ticker.ribbon.reset()
  }

  /**
   * Установка тикера
   */
  setTicker(value) {
    this.gaApp.stores.ticker.main.ticker = value
    this.gaApp.stores.app.common.ticker.has = Boolean(value)
  }

  /**
   * Установка позиции тикера
   */
  setTickerPosition(value) {
    this.gaApp.stores.ticker.main.tickerPosition = value
    this.gaApp.stores.app.common.ticker.position = value
  }

  /**
   * Установка высоты тикера
   */
  setTickerGap(value = null) {
    this.gaApp.stores.ticker.main.tickerGap = value
    this.gaApp.stores.app.common.ticker.gap = value
  }

  /**
   * Получение данных текущего тикера
   *
   * @returns {object}
   */
  getCurrentTicker() {
    const { ticker, ribbon } = this.gaApp.stores.ticker.main

    return ticker.data.find((ticker) => ticker.id === ribbon.currentId)
  }

  /**
   * Получение имени текущего брейкпонта
   *
   * @returns {string}
   */
  getCurrentBreakpointName() {
    if (this.gaApp.mq('tablet-')) {
      return BREAKPOINT.MOBILE
    }

    if (this.gaApp.mq('desktop-')) {
      return BREAKPOINT.TABLET
    }

    return BREAKPOINT.DESKTOP
  }

  /**
   * Получение текущего текста тикера на основе брейкпоинта
   *
   * @returns {string}
   */
  getСurrentText(ribbon) {
    return ribbon?.responsiveText[this.getCurrentBreakpointName()]
  }

  /**
   * Получение текущего размера тикера на основе брейкпоинта
   *
   * @returns {string}
   */
  getСurrentSize() {
    return this.getCurrentBreakpointName() === BREAKPOINT.MOBILE
      ? SIZE.M
      : SIZE.L
  }
}
