import { AR_QA_FEATURES } from './ar-QA.features'
import { EN_QA_FEATURES } from './en-QA.features'
import { RU_BY_FEATURES } from './ru-BY.features'
import { RU_KZ_FEATURES } from './ru-KZ.features'
import { RU_RU_FEATURES } from './ru-RU.features'

export const FT_UPDATE_TIME = 5 // минуты
export const FT_LAST_UPDATE_LOCAL_STORE_KEY = 'module-ft-last-update'

export const STORE_FEATURES_INITIAL = {
  modalCookiesPermission: false, // включает модалку/панель запроса разрешения на использование кук
  modalCookiesPermissionSwitchToPanel: false, // переключает запрос разрешения на использование кук с модалки на панель внизу
  сookiesNotificationPanel: false, // включает панель оповещения об использовании кук
  giftCardsB2bNewLink: true, // Включает лендинг КПК
  giftCardsB2bNewPlastic: false, // Включает прямую ссылку на страницу КППК (если выключен giftCardsB2bNewLink)
  reviewFormPreloadImage: false, // Включает загрузку изображений в превью перед загрузкой изображений на сервер
  isReviewAvailable: true, // открывает функционал отзывов
  reviewShowButtonToForm: true, // показывать кнопку для перехода на форму отзыва без блока отзывов
  reviewProductsAvailable: false, // показывать модалку с продуктами для отзыва
  includeGoogleMaps: false, // включает подключение гугл карт
  giftCardsNewReceiveColors: true, // Включает новые цвета на странице получения подарочной карты
  giftCardsReactions: false, // Включает реакции при получении карты
  geoPolygonDeliveryAreas: false, // включает гео-полигоны
  giftCardsUnlimitedTerm: false, // устанавливает ограничение срока подарочных карт как "бессрочно"
  giftCardsB2bReceivePage: false, // включает отдельную страницу получения подарочных карт для b2b
  pdpHowToShortVideoOn: false, // добавляет короткие видео в галерею pdp
  pdpHowToLongVideoOn: true, // открывает слайдер с видеоплеером на пдп
  pdpHowToShortVideoLoop: false, // добавляет зацикливание коротких видео в галерею pdp
  plpHowToShortVideoOn: false, // добавляет короткие видео в карточки plp
  plpHowToShortVideoOnlyOne: false, // воспроизводит только одно видео в очереди воспроизведения plp
  disableAddressClarificationPopUp: false, // Выключение плашки уточнения адреса для авторизованных
  promoUrlKey: false, // включает ЧПУ формирование url для страниц промо
  sharingCart: false, // Включает функционал "поделиться корзиной"
  customBrandzoneEnabled: true, // Включет функционал кастомных брендзон
  giftCardsBalanceFractions: false, // включает дробные значения баланса карты на странице баланса ПК
  giftCardsNewPlasticLink: false, // включает новую ссылку на пластиковые подарочные карты
  setNearestCityWithStores: false, // Передача кординат для сортировки городов по ближайшему (офффлайн магазины на пдп)
  giftCardsB2bNewWhatsAppSupportLink: false, // изменяет ссылку поддержки WhatsApp на новую
  giftCardsB2bLandingFaq: false, // включает раздел FAQ на лендинге КПК
  giftCardsNewPayment: false, // включает выбор способа оплаты при оформлении ЭПК
  enableLoyaltyProgress: false, // включает прогресс бар на карте лояльности в лк
  giftCardsAboutNewText: false, // включает измененный текст на странице /cards/about
  giftCardsMenuFetch: false, // включает перенос управления меню ПД на бэк
  giftCardsHintsWithWhatsapp: false, // добавляет whatsapp в текстовки при оформлении ЭПК
  multiparcelDelivery: false, // Отключает показ прогресса бесплатной доставки на корзине
  plpProductCardUniversal: false, // отображение универсальной карточки продукта
  eyezonV2: false, // Переключает eyezon на новую версию скрипта
  eyezonV2StaticScript: false, // Переключает версию скрипта в eyezon на статический скрипт
  newBrands: false, // переводит работу брендов на plaid api
  brandSearchPrototype: false, // прототип нового поиска по брендам с запросами в мкс
  brandsFakeItemsRendering: false, // облегченный рендеринг брендов на основе псевдо-item
  giftCardsDesignTypes: false, // Включает выбор типа дизайна ЭПК при оформлении
  giftCardsDesignTypesNewLabel: false, // Включает отображение лейбла NEW в табах выбора дизайна ЭПК
  giftCardsCardTypesInTabs: false, // Включает выбор типа карты в виде табов
  profileExpirePoints: false, // Включает фичу сгорающих юонусок в лк
  pdpBrandzoneSlots: false, // Включает слоты брендзоны в pdp
  geoPositionBanForExternals: false, // не показываем модалку перехода в другую страну когда определяем адрес по гео
  addressSuggestQueryFormatForSplit: false, // смена формата квери для саджеста улицы
  mainPageWidgetProductSlider: false, // Подключает слайдер плейсментов из виджета на главной
  flaconStart: true, // Разрешает отдачу материалов флакона, если выключен 404
  plpCoupon: false, // Включает плашку "промокод" на plp
  plpCouponV2: false, // Включает плашку "промокод" версии 2 на plp
  giftCardsPricePromptBubbles: false, // Включает отображение подсказок при выборе номинала
  benefitsModal: false, // Включает новую модалку для бенефитов
  articlesHowToShortVideoOn: false, // Добавляет короткие видео в карточки статей
  searchModalWidgetProductListing: false, // Листинг продуктов из виджета в модалке поиска
  searchNoResultWidgetProductListing: false, // Листинг продуктов из виджета в результатах поиска для альтернативных продуктов
  flaconAMP: false, // Включает добавление мета тегов линковки на amp версию
  profilePlaid: false, // Включает получение и сохранение данных для страницы редактирования в ЛК из пледа, выключает запрос аатара с маги
  settingsPlaid: false, // Включает получение и сохранение данных для страницы настроек оповещений в ЛК из пледа
  profileMenuFaqLink: false, // Включает отображение ссылки "вопросы-ответы" в меню ЛК
  addressSeparateSuggestGeocodeRequests: false, // переход на ручки плейд, разделенные подсказок на 2 запроса
  addressGeoLocationRequest: false, // переход suggestions/geolocation на ручку плейда
  customerAddressFromMagentoToPlaid: false, // Переход адресов в ЛК на ручки плейда
  flaconUseStreamers: false, // Включает стримы для флакона
  reviewGalleryDesktopZoom: false, // Включает зум слайдов на десктопе в слайдере отзывов
  navIndependentScroll: false, // Включает независимый скролл уровней навигации хедера
  flaconUseNewEndpointForGetArticle: false, // Включает использование нового апи бека для получения статью по url
  cartNewDesignFreeDelivery: false, // Включает новый дизайн лоадера бесплатной доставки
  pdpCoupon: false, // Включает плашку "промокод" на pdp
  pdpHideDescription: false, // Включает скрытие описания на pdp
  newBenefits: false, // Включает запрос бенефитов пдп из отдельной ручки v2
  downloadAppModal: false, // Включает модалку "скачать приложение"
  bzWidgetHowToLongVideo: false, // Включает виджет слайдера видео в брендзоне
  flaconUseAdultModal: false, // Включает показ модального окна подтверждения возраста для материалов 18+
  flaconUseNewPaginationList: false, // Включает использование нового апи бека для получения списка статей
  productCardPostRenderElements: false, // Включает рендринг некоторых элементов карточки в фоновом режиме
  flaconUseFiltersInFetchProductsRequest: false, // Добавляет параметр фильтры в запрос продуктов
  bzWidgetCategory: false, // Включает виджет категории в брендзоне
  newSortingByRatingAndReviews: false, // Включает использование повышенной версии api (v4) запроса продуктов и фильтров для PLP и поиска
  yandexMetrikaScript: false, // Включает установку скрипта яндекс метрики
  bzHowToShortVideoOn: false, // добавляет короткие видео в листинг брендзоны
}

export const STORE_FEATURES_BY_REGION = {
  'ru-RU': {
    ...RU_RU_FEATURES,
  },
  'ru-BY': {
    ...RU_BY_FEATURES,
  },
  'ru-KZ': {
    ...RU_KZ_FEATURES,
  },
  'en-QA': {
    ...EN_QA_FEATURES,
  },
  'ar-QA': {
    ...AR_QA_FEATURES,
  },
  'en-AE': {
    ...EN_QA_FEATURES,
  },
  'ar-AE': {
    ...AR_QA_FEATURES,
  },
}
