import { useContext } from '@nuxtjs/composition-api'

import { unref } from 'vue'

export const useProductCardAnalytic = ({ analyticData, item, index }) => {
  const { $gaApp } = useContext()

  const product = item?.product
  const digiAnalytics = unref(analyticData)?.digiAnalytics

  const onWidgetClick = () => {
    $gaApp.analytics.global.productCard.onWidgetClick({
      product,
      index,
      placement: unref(analyticData)?.placement,
    })
  }

  const onProductClick = () => {
    $gaApp.analytics.global.productCard.onProductClick({
      product,
      index,
      analyticData: unref(analyticData),
    })

    if (digiAnalytics) {
      onWidgetClick()
    }
  }

  const onProductAddToCart = () => {
    $gaApp.analytics.global.productCard.onAddToCart({
      product,
      index,
      analyticData: unref(analyticData),
    })

    if (digiAnalytics) {
      $gaApp.analytics.global.productCard.onWidgetAddToCart({
        product,
        index,
        analyticData: unref(analyticData),
        placement: unref(analyticData)?.placement,
      })

      onWidgetClick()
    }
  }

  const onProductAddToFavorite = () => {
    $gaApp.analytics.global.productCard.onAddToFavorite({
      product,
      index,
      analyticData: unref(analyticData),
    })

    if (digiAnalytics) {
      onWidgetClick()
    }
  }

  return {
    productCardListeners: {
      'click-card': onProductClick,
      'add-to-cart': onProductAddToCart,
      'add-to-favorite': onProductAddToFavorite,
    },
  }
}
