import { bannerStore as banner } from './banner'
import { categoriesTilesStore as categoriesTiles } from './categories-tiles'
import { categoryStore as category } from './category'
import { listingStore as listing } from './listing'
import { logoStore as logo } from './logo'
import { mainStore as main } from './main'
import { mediaStore as media } from './media'
import { navModalStore as navModal } from './nav-modal'
import { pageStore as page } from './page'
import { productsStore as products } from './products'
import { sliderStore as slider } from './slider'
import { slotsStore as slots } from './slots'
import { videoSliderStore as videoSlider } from './video-slider'

export const stores = (gaApp) => {
  return {
    main: main(gaApp),
    navModal: navModal(gaApp),
    banner: banner(gaApp),
    slots: slots(gaApp),
    logo: logo(gaApp),
    slider: slider(gaApp),
    videoSlider: videoSlider(gaApp),
    products: products(gaApp),
    listing: listing(gaApp),
    page: page(gaApp),
    category: category(gaApp),
    categoriesTiles: categoriesTiles(gaApp),
    media: media(gaApp),
  }
}
