export class MainRepository {
  constructor(gaApp, endpoints) {
    this.gaApp = gaApp

    this.endpoints = gaApp.api.endpoints(endpoints)
  }

  async setData(data = {}) {
    const { url } = this.endpoints.default()

    try {
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async getData(parameters = {}) {
    const params = { ...parameters }

    const { url } = this.endpoints.default()

    try {
      const response = await this.gaApp.api.request().get(url, {
        params,
      })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async getSearch(parameters = {}) {
    const params = { ...parameters }
    const { url } = this.endpoints.search()

    try {
      const response = await this.gaApp.api.request().get(url, {
        params,
      })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async geoSuggest(params = {}) {
    const { url } = this.endpoints.geoSuggest()

    try {
      const response = await this.gaApp.api.request().get(url, {
        params,
      })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async geoGeocode(params = {}) {
    const { url } = this.endpoints.geoGeocode()

    try {
      const response = await this.gaApp.api.request().get(url, {
        params,
      })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async geoReverseGeocode(params = {}, requestParams = {}) {
    const { url } = this.endpoints.geoReverseGeocode()

    try {
      const response = await this.gaApp.api.request(requestParams).get(url, {
        params,
      })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async getDataByCoordinates(parameters = {}, requestParams = {}) {
    const params = { ...parameters }
    const { url } = this.endpoints.geolocation()

    try {
      const response = await this.gaApp.api.request(requestParams).get(url, {
        params,
      })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async setAddress(data = {}) {
    const { url } = this.endpoints.setAddress()

    try {
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }
}
