import { SETTINGS } from '../constants'

export class SettingsService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Запись данных о подписках в стор
   * @param {object} data объект данных
   */
  setSubscriptionsSettings(data) {
    // Записываем в стор поля подписок
    this.gaApp.stores.customer.settings.options =
      this.getSubscriptionsSettings(data)

    // Если ФТ пледа вкл
    if (this.gaApp.features.get('settingsPlaid')) {
      // Записываем текущее значение согласия пользака на подписки
      this.gaApp.stores.customer.settings.subscriptionsSigned =
        data.subscriptionsSigned
    }
  }

  /**
   * Нормализация и отправка данных о подписках
   * @param {object[]} settings массив с инфой о тогглах подписок
   * @param {string} toggleName название измененного тоггла
   * @param {boolean} toggleValue предыдущее значение измененного тоггла
   */
  updateSubscriptionsSettings(settings, toggleName, toggleValue) {
    // TODO: Убрать нормализацию тогглов и признака согласия на подписки, когда будем выпиливать ФТ
    // TODO: Вместо этого нужно будет тянуть данные напрямую из стора
    // формируем объект для отправки на бек
    const dataNormalized = {}

    // Если ФТ пледа вкл
    if (this.gaApp.features.get('settingsPlaid')) {
      // заполняем объект значениями тогглов
      settings.forEach((setting) => {
        dataNormalized[setting.name] = setting.checked
      })

      // Если пользак изменил хотя бы один тоггл в true, то отправляем флаг
      // subsriptionsSigned = true, иначе отправляем то значение, которое пришло с бека
      const subscriptionsSigned = settings.some((setting) => setting.checked)

      dataNormalized.subscriptionsSigned =
        subscriptionsSigned ||
        this.gaApp.stores.customer.settings.subscriptionsSigned
    } else {
      // нормализуем названия тогглов в lowerCase для маги
      settings.forEach((s) => {
        if (s.name === toggleName) {
          dataNormalized[s.name.toLowerCase()] = !toggleValue
        } else {
          dataNormalized[s.name.toLowerCase()] = s.checked
        }
      })
    }

    this.gaApp.services.customer.api.updateSubscriptionsSettings(dataNormalized)
  }

  /**
   * Обновление состояния тоггла
   * @param {string} name название измененного тоггла
   * @param {boolean} value предыдущее значение измененного тоггла
   */
  updateSubscription(name, value) {
    this.gaApp.stores.customer.settings.options[name] = !value
  }

  /**
   * Маппинг полей подписок
   * @param {object} data объект данных
   * @returns {object} объект полей подписок и их значений
   */
  getSubscriptionsSettings(data) {
    const settings = {}
    const settingsNames = Object.values(SETTINGS)

    Object.entries(data).forEach(([key, value]) => {
      if (settingsNames.includes(key)) {
        settings[key] = value
      }
    })

    return settings
  }
}
