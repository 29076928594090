import { MODAL_STATE } from '../constants/products'

/**
 * Предоставляет методы для работы с локальным кэшем браузера в контексте модального окна или всплывающего окна приложения. Вот что делают его методы:
`getModalCache()` - получает данные модального окна из локального кэша браузера.

`setModalCache(modalData)` - сохраняет данные модального окна в локальный кэш браузера.

`resetModalCache(orderId)` - сбрасывает данные модального окна в кэше, устанавливая счетчик просмотров в 0 и текущий orderId.

`incrementModalViews()` - увеличивает счетчик просмотров модального окна на 1 и обновляет время последнего обновления в кэше.
 */

export class CacheService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  getModalCache() {
    return this.gaApp.services.app.browserStorage.local.get(
      MODAL_STATE.CACHE_KEY,
    )
  }

  setModalCache(modalData) {
    this.gaApp.services.app.browserStorage.local.set(
      MODAL_STATE.CACHE_KEY,
      modalData,
    )
  }

  resetModalCache(orderId) {
    const resetData = {
      countViews: 0,
      orderId,
      updateAt: 0,
    }
    try {
      this.setModalCache(resetData)
      return true
    } catch {
      return false
    }
  }

  incrementModalViews() {
    const modalData = this.getModalCache()
    if (!modalData) {
      return
    }

    const currentTime = this.gaApp.services.review.date.getDate()

    const updateData = {
      countViews: modalData.countViews + 1,
      orderId: modalData.orderId,
      updateAt: currentTime,
    }

    this.setModalCache(updateData)
  }
}
