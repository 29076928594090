import { TYPE } from '../../constants'
import {
  GtmGa4ProductCartModel,
  GtmGaUaProductCartModel,
  MindboxProductCartModel,
} from '../../models'
import { BaseBuilder } from '../base/base.builder'

export class ProductCartBuilder extends BaseBuilder {
  constructor(data, country) {
    const models = new Map([
      [TYPE.GTM_UA, GtmGaUaProductCartModel],
      [TYPE.GTM_4, GtmGa4ProductCartModel],
      [TYPE.MINDBOX, MindboxProductCartModel],
    ])
    super(models, data, country)
  }
}
