import { queryHighlight } from '@ga/utils'

import { MAX_VISIBLE_HISTORY_REQUESTS, SEARCH_HISTORY_KEY } from '../constants'

export class SearchHistoryService {
  constructor(gaApp) {
    this.gaApp = gaApp

    this.setSubscriptions()
  }

  /**
   * Из localStorage сохраняем в store приложения
   */
  setSearchHistory() {
    this.gaApp.stores.search.main.searchHistory =
      this.gaApp.services.app.browserStorage.local.get(SEARCH_HISTORY_KEY) || []
  }

  /**
   * Добавляет в историю запросов новый запрос, закидываем в localStorage и store приложения
   * По принципу новые запросы вначале
   * Максимум показываем 5 запросов
   * @param {string} request
   */
  addSearchRequest(request) {
    if (request === '') {
      return
    }

    const formattedRequest = request.toLowerCase().trim()

    const currentHistory = this.gaApp.stores.search.main.searchHistory
    let newHistory

    if (currentHistory.includes(formattedRequest)) {
      newHistory = currentHistory.filter((item) => item !== formattedRequest)

      newHistory.unshift(formattedRequest)
    } else {
      newHistory =
        currentHistory.length < MAX_VISIBLE_HISTORY_REQUESTS
          ? [formattedRequest, ...currentHistory]
          : [formattedRequest, ...currentHistory].slice(
              0,
              MAX_VISIBLE_HISTORY_REQUESTS,
            )
    }

    this.gaApp.services.app.browserStorage.local.set(
      SEARCH_HISTORY_KEY,
      newHistory,
    )

    this.gaApp.stores.search.main.searchHistory = newHistory
  }

  /**
   * Удаляем историю из localStorage и store приложения
   */
  clearHistory() {
    this.gaApp.stores.search.main.searchHistory = []
    this.gaApp.stores.search.main.formattedSearchHistory = []
    this.clearHistoryStorage()
  }

  /**
   * Удаляем историю из localStorage
   */
  clearHistoryStorage() {
    this.gaApp.services.app.browserStorage.local.set(SEARCH_HISTORY_KEY, [])
  }

  /**
   * Подписываемся на ивенты
   */
  setSubscriptions() {
    // Очищаем историю при смене языка
    this.gaApp.eventBus.subscribe(
      'module/app/lang-switcher/locale-changed',
      () => {
        this.clearHistoryStorage()
      },
    )

    // Очищаем историю при логауте
    this.gaApp.eventBus.subscribe('module/auth/logout', () => {
      this.clearHistory()
    })
  }

  /**
   * Удаляем какой-нибудь один запрос из истории
   * удаляется из localStorage и store приложения
   * @param {string} name
   */
  deleteItem(name) {
    const newFormattedHistory =
      this.gaApp.stores.search.main.formattedSearchHistory.filter(
        (item) => item.name !== name,
      )

    this.gaApp.stores.search.main.formattedSearchHistory = newFormattedHistory

    const newHistory = this.gaApp.stores.search.main.searchHistory.filter(
      (itemName) => itemName !== name,
    )

    this.gaApp.services.app.browserStorage.local.set(
      SEARCH_HISTORY_KEY,
      newHistory,
    )

    this.gaApp.stores.search.main.searchHistory = newHistory
  }

  /**
   * Проверяет необходимость отображения тултипа
   * @param {HTMLElement} textRef
   * @returns {boolean}
   */
  checkIsTooltipNeeded(textRef) {
    return textRef.offsetWidth > textRef.parentElement.offsetWidth
  }

  /**
   * Форматирует историю запросов для отображения:
   * выделяет совпадения с search query
   * добавляет тултип, если текст обрезается
   */
  updateFormattedSearchHistory({ rows, isHoverable } = {}) {
    const searchQuery = this.gaApp.stores.search.main.searchInputValue
    const formattedSearchHistory =
      this.gaApp.stores.search.main.formattedSearchHistory

    this.gaApp.stores.search.main.formattedSearchHistory =
      formattedSearchHistory.map(({ name: query }, i) => {
        // получаем элемент с текстом запроса
        const textRef = rows?.[i]?.querySelector('[data-search-query]')
        // определяем, нужно ли показывать тултип
        const hasTooltip =
          !!textRef && isHoverable && this.checkIsTooltipNeeded(textRef)
        // подсвечиваем совпадение по search query
        const queryHtml = searchQuery
          ? queryHighlight(query, searchQuery, { htmlTag: 'b' })
          : query

        return this.getFormattedSearchRequest({ query, queryHtml, hasTooltip })
      })
  }

  /**
   * Фильтрует и возвращает историю запросов по search query
   * @returns {Array<string>}
   */
  getSearchHistoryBySearchQuery() {
    return this.gaApp.stores.search.main.searchHistory.filter((query) => {
      const regex = new RegExp(
        this.gaApp.stores.search.main.searchInputValue,
        'i',
      )
      return (
        query !== this.gaApp.stores.search.main.searchInputValue &&
        regex.test(query)
      )
    })
  }

  /**
   * Принимает данные и возвращает объект типа FormattedSearchRequest
   * @param {object} data
   * @param {string} data.query
   * @param {string | undefined} data.queryHtml
   * @param {boolean} data.hasTooltip
   * @returns {object} объект типа FormattedSearchRequest
   */
  getFormattedSearchRequest({ query, queryHtml, hasTooltip = false }) {
    return {
      name: query,
      queryHtml: queryHtml || query,
      hasTooltip,
    }
  }

  /**
   * Форматирует историю запросов и фильтрует по search query
   * для первичного отображения
   */
  setFormattedSearchHistory() {
    const searchQuery = this.gaApp.stores.search.main.searchInputValue
    let matchedHistory = this.gaApp.stores.search.main.searchHistory

    if (searchQuery) {
      matchedHistory = this.getSearchHistoryBySearchQuery()
    }

    this.gaApp.stores.search.main.formattedSearchHistory = matchedHistory.map(
      (query) => this.getFormattedSearchRequest({ query }),
    )
  }
}
