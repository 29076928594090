<template>
  <svg fill="none" viewBox="0 0 25 24">
    <g fill="#04121B">
      <path
        fill-rule="evenodd"
        d="M12.5 20c6.523 0 10-5.477 10-8 0-2.523-3.477-8-10-8s-10 5.477-10 8c0 2.523 3.477 8 10 8Zm0-3a5 5 0 1 0 0-10 5 5 0 0 0 0 10Z"
        clip-rule="evenodd"
      />
      <path d="M12.5 15a3 3 0 1 0 0-6v3h-3a3 3 0 0 0 3 3Z" />
    </g>
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-info-skyeng-show',
}
</script>
