export const state = () => {
  return {
    menu: {
      main: [],
      additional: [],
      activeItem: null,
      selectedItem: null,
    },
    qrCodeModal: {
      active: false,
      code: '',
      qrCodeType: '',
    },
    pickupPointModal: {
      active: false,
      data: {},
    },
    onDemandModal: {
      active: false,
    },
    logoutPending: false,
  }
}
