export class MainRepository {
  constructor(gaApp, endpoints) {
    this.gaApp = gaApp

    this.endpoints = gaApp.api.endpoints(endpoints)
  }

  async getData() {
    const { url } = this.endpoints.default()

    try {
      const response = await this.gaApp.api.request().get(url)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async addItems(items) {
    const { url } = this.endpoints.items()

    try {
      const response = await this.gaApp.api.request().post(url, { items })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async addItem({ data }) {
    const { url } = this.endpoints.item()

    try {
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async addItemByBarcode({ data }) {
    const { url } = this.endpoints.itemByBarcode()

    try {
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async deleteItems({ params }) {
    const { url } = this.endpoints.items()

    try {
      const response = await this.gaApp.api.request().delete(url, {
        params,
      })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async updateItemQty({ data }) {
    const { url } = this.endpoints.updateItemQty()

    try {
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async applyCoupon({ data }) {
    const { url } = this.endpoints.coupon()

    try {
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async cancelCoupon({ params }) {
    const { url } = this.endpoints.coupon()

    try {
      const response = await this.gaApp.api.request().delete(url, {
        params,
      })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async recollectAvailableCoupons(params) {
    const { url } = this.endpoints.recollectAvailableCoupons()

    try {
      const response = await this.gaApp.api.request().get(url, { params })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async validateStock({ data }) {
    const { url } = this.endpoints.validateStock()

    try {
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async reorder(orderId) {
    const params = { orderId }

    const { url } = this.endpoints.reorder()

    try {
      const response = await this.gaApp.api.request().get(url, { params })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }
}
