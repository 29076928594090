export const state = () => ({
  form: {
    isLoading: false,
    fields: {
      fullName: '',
      email: '',
      checkbox: false,
    },
  },
  modalOpen: false,
  isGiftFormSent: false,
})
