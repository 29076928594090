export const queryHighlight = (
  string,
  query,
  { htmlTag = 'span', className = null },
) => {
  if (!query) {
    return string
  }

  const pattern = new RegExp(query, 'i')

  const classAttr = className ? ` class= "${className}"` : ''

  return string.replace(
    pattern,
    (match) => `<${htmlTag}${classAttr}>${match}</${htmlTag}>`,
  )
}
