export const state = () => {
  return {
    id: '',
    isAgeLimit: false,
    seo: [],
    title: '',
    subtitle: '',
    image: null,
    coverCaption: null,
    coverCaptionColor: null,
    author: null,
    rubric: {
      keyFilter: '',
      name: '',
      url: '',
    },
    disclaimer: null,
    rawDate: '',
    date: '',
    sections: [],
    recommendations: [],
    isPreview: false,
    scrollToTop: false,
  }
}
