export * from './support-messengers'

export const SCROLL_HASH_PREFIX = '#s-'
export const SCROLL_HASH_PREFIX_REGEXP = new RegExp(`^${SCROLL_HASH_PREFIX}`)
export const IS_ACCEPTED_COOKIES_KEY = 'is-accepted-cookies'
export const DEVICE_ID_COOKIES_KEY = 'ga-device-id'
export const COOKIES_NOFIFICATION_ANSWERED_KEY =
  'ga-cookie-notification-answered'
export const WEBVIEW_COOKIES_KEY = 'ga-webview'

export const SETTINGS_LANG_NAMES_MAP = {
  ru_RU: 'ru',
}
