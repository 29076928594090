import { SETTINGS_LANG_NAMES_MAP } from '../../constant'

export const getters = (gaApp) => ({
  contactsModalData(state) {
    const phones = state.phones
    const emails = state.emails
    const messengers = state.messengers

    return {
      phones,
      messengers,
      emails,
    }
  },

  getTickerGap() {
    const hasTicker = this.ticker.has
    const tickerGap = this.ticker.gap

    return (hasTicker && tickerGap) || 0
  },

  getTickerTopGap() {
    return this.ticker.position === 'top' && this.getTickerGap
  },

  getTickerBottomGap() {
    return this.ticker.position === 'bottom' && this.getTickerGap
  },

  // Приводим коды языков к понятным для i18n
  convertedLanguages() {
    return this.languages.map((lang) => {
      const nextLang = { ...lang, initialCode: lang.code }

      if (SETTINGS_LANG_NAMES_MAP[nextLang.code]) {
        nextLang.code = SETTINGS_LANG_NAMES_MAP[nextLang.code]
      }

      return nextLang
    })
  },

  // Мапа языков i18n и тех, что пришли из api
  initialLanguagesCodeMap() {
    return this.convertedLanguages.reduce((acc, lang) => {
      // i18n код = коду из массива languages
      acc[lang.code] = lang.initialCode
      return acc
    }, {})
  },
})
