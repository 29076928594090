export const PAGE_TYPE = {
  THANK_YOU: 'thank-you',
}

export const DELIVERY_TYPES = {
  PICKUP: 'pickup',
  ON_DEMAND: 'onDemand',
  EXPRESS: 'express',
  COURIER: 'courier',
}

export const ORDER_TOTAL_NAMES = {
  SUBTOTAL: 'subtotal',
  DELIVERY: 'delivery',
  DISCOUNT: 'discount',
  PROMOCODE_DISCOUNT: 'promocodeDiscount',
  GIFTCARD_DISCOUNT: 'giftcardDiscount',
  BONUS_DISCOUNT: 'bonusDiscount',
}
