/**
 * Возвращает IANA значение таймзоны по переданной GMT строке
 * @param {string} gmt - значение таймзоны в GMT формате
 * @param {string} country - значение страны в iso формате
 * @returns {string} - значение таймзоны в IANA формате
 */

export function GMTToIANA(gmt, country) {
  return {
    ru: {
      'GMT+3': 'Europe/Moscow',
      'GMT+2': 'Europe/Kaliningrad',
      'GMT+4': 'Europe/Samara',
      'GMT+5': 'Asia/Yekaterinburg',
      'GMT+6': 'Asia/Omsk',
      'GMT+7': 'Asia/Krasnoyarsk',
      'GMT+8': 'Asia/Irkutsk',
      'GMT+9': 'Asia/Yakutsk',
      'GMT+10': 'Asia/Vladivostok',
      'GMT+11': 'Asia/Srednekolymsk',
      'GMT+12': 'Asia/Kamchatka',
    },
    by: {
      'GMT+3': 'Europe/Minsk',
    },
    kz: {
      'GMT+5': 'Asia/Aqtobe',
      'GMT+6': 'Asia/Almaty',
    },
  }[country]?.[gmt]
}
