export * from './edit'
export * from './settings'

export const MENU_ITEM_STATES = {
  DEFAULT: 'default',
  ACTIVE: 'active',
  SELECTED: 'selected',
}

export const MENU_ITEM_NAMES = {
  ORDERS: 'orders',
  FAVORITES: 'favorites',
  GIFTCARDS: 'giftcards',
  EDIT: 'edit',
  ADDRESSES: 'addresses',
  SETTINGS: 'settings',
  FAQ: 'faq',
}

export const PAGINATION_BUTTON = {
  NEXT: 'next',
  PREV: 'prev',
}

export const DIALOG = {
  CONFIRMATION: 'confirmation',
  CANCELLED: 'cancelled',
  COMPLETED: 'completed',
  DELIVERING: 'delivering',
  DEFAULT: 'default',
}

export const CATEGORY = 'favorites'
