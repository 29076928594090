<template>
  <svg fill="none" viewBox="0 0 8 13">
    <path
      stroke="#fff"
      stroke-linejoin="round"
      stroke-width="2.583"
      d="m1 11.667 5.333-5.334L1 1"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-info-skyeng-arrow-right',
}
</script>
