import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4MenuClickModel extends GtmBaseModel {
  constructor(data = {}) {
    super()

    const { path } = data

    this.action_type = 'menu_click'
    this.path = path
  }
}
