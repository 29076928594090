import {
  ARTICLES_BASE_URL,
  STREAM_BASE_URL,
  STREAMS_BASE_URL,
} from '../constants'

export const moduleArticlesSsrRoutes = [
  // articles
  {
    moduleType: 'articles',
    pageType: 'article',
    path: '/flacon/article/:url',
    meta: {
      addressDisabled: true,
      authRequired: false,
      analyticDisabled: false,
      webview: true,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/articles/page/main' */
        '~/modules/articles/components/pages/article.vue'
      ),
  },
  {
    moduleType: 'articles',
    pageType: 'articles',
    path: ARTICLES_BASE_URL,
    meta: {
      addressDisabled: true,
      authRequired: false,
      analyticDisabled: false,
      webview: true,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/articles/page/list' */
        '~/modules/articles/components/pages/articles.vue'
      ),
  },

  // live
  {
    moduleType: 'articles',
    pageType: 'stream',
    path: STREAM_BASE_URL,
    meta: {
      addressDisabled: true,
      authRequired: false,
      analyticDisabled: false,
      webview: true,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/articles/page/stream' */
        '~/modules/articles/components/pages/stream.vue'
      ),
  },
  {
    moduleType: 'articles',
    pageType: 'streams',
    path: STREAMS_BASE_URL,
    meta: {
      addressDisabled: true,
      authRequired: false,
      analyticDisabled: false,
      webview: true,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/articles/page/streams' */
        '~/modules/articles/components/pages/streams.vue'
      ),
  },

  // filters
  {
    moduleType: 'articles',
    pageType: 'articles-filter',
    path: '/flacon/:filterType/:filterKey',
    meta: {
      addressDisabled: true,
      authRequired: false,
      analyticDisabled: false,
      webview: true,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/articles/page/list' */
        '~/modules/articles/components/pages/articles.vue'
      ),
  },
]

export const routes = [
  // previews
  {
    moduleType: 'articles',
    pageType: 'preview',
    path: '/flacon/preview/:id',
    meta: {
      addressDisabled: true,
      authRequired: false,
      analyticDisabled: false,
      webview: true,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/articles/page/main' */
        '~/modules/articles/components/pages/article.vue'
      ),
  },
  {
    moduleType: 'articles',
    pageType: 'preview-listing',
    path: '/flacon/preview-listing/:id',
    meta: {
      addressDisabled: true,
      authRequired: false,
      analyticDisabled: false,
      webview: true,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/articles/page/main' */
        '~/modules/articles/components/pages/articles.vue'
      ),
  },

  ...moduleArticlesSsrRoutes,
]
