import merge from 'lodash/merge'

import footer from '~/modules/footer/locales/ru-KZ'
import footerOptions from '~/modules/footer/locales/ru-KZ.options'

export default () => {
  return {
    ...merge(footer, footerOptions),
  }
}
