<template>
  <svg fill="none" viewBox="0 0 33 32">
    <path
      fill="#000"
      d="M15.84 5.375c.193-.816.289-1.224.444-1.316.133-.079.299-.079.432 0 .155.092.251.5.444 1.316l.94 3.983c.35 1.48.524 2.221.904 2.822.336.53.785.98 1.316 1.316.6.38 1.341.555 2.822.904l3.983.94c.816.193 1.224.289 1.316.444.079.133.079.299 0 .432-.092.155-.5.251-1.316.444l-3.983.94c-1.48.35-2.221.524-2.822.904-.53.336-.98.785-1.316 1.316-.38.6-.555 1.341-.904 2.822l-.94 3.983c-.193.816-.289 1.224-.444 1.316-.133.079-.299.079-.432 0-.155-.092-.251-.5-.444-1.316l-.94-3.983c-.35-1.48-.524-2.221-.904-2.822a4.239 4.239 0 0 0-1.316-1.316c-.6-.38-1.341-.555-2.822-.904l-3.983-.94c-.816-.193-1.224-.289-1.316-.444a.424.424 0 0 1 0-.432c.092-.155.5-.251 1.316-.444l3.983-.94c1.48-.35 2.221-.524 2.822-.904.53-.336.98-.785 1.316-1.316.38-.6.555-1.341.904-2.822l.94-3.983Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-info-skyeng-sparcle',
}
</script>
