import { GaAnalyticsBaseModel } from '../ga-analytics-base-model/ga-analytics-base-model'

export class GaAnalyticsGetListingAnalyticsModel extends GaAnalyticsBaseModel {
  constructor(data) {
    super('listing')

    const { url, itemListName } = data.category

    this.category = {
      url,
      itemListName,
    }
  }
}
