/*
 * возвращает ближайшее значение из массива
 * @param {number[]} array - массив значений
 * @param {number} value - значение
 * @returns {number} ближайшее значение
 */

export const findClosestValueInArray = (array, value) => {
  // TODO: Проверить, подходит ли reduce
  return array.reduce((prev, curr) => {
    return Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev
  }, [])
}
