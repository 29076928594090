import has from 'lodash/has'
import isEmpty from 'lodash/isEmpty'

import { DATE_FORMAT, GENDER } from '../constants'

export class EditService {
  constructor(gaApp) {
    this.gaApp = gaApp
    this.editStore = this.gaApp.stores.customer.edit
  }

  setFormData(data) {
    this.editStore.form.firstName = data.firstName || ''
    this.editStore.form.lastName = data.lastName || ''
    this.editStore.form.middleName = data.middleName || ''
    this.editStore.form.gender = data.gender || GENDER.default.value
    this.editStore.form.birthday = data.birthDate
      ? this.gaApp.libs
          .dateWrapper(data.birthDate)
          .format(DATE_FORMAT[this.gaApp.i18n.locale.code])
      : ''
    this.editStore.form.email = data.email || ''
    this.editStore.form.phone = data.phone || ''
    this.editStore.form.personalDataSigned = data.personalDataSigned ?? false

    this.editStore.form.subscriptionsSigned = data.subscriptionsSigned ?? false

    if (this.gaApp.features.get('profilePlaid')) {
      this.editStore.form.city = !isEmpty(data.city)
        ? data.city
        : { name: '', cityFiasId: '' }
    } else {
      this.editStore.form.cityName = data.city || ''
    }
  }

  setAdditionalData(data) {
    this.editStore.isEmailConfirmed = !!data.emailVerified

    this.editStore.wasPhoneSaved = !!data.phone
    this.editStore.isBirthdayEditBlocked = data.actions
      ? !data.actions.enableEditBirthDate
      : false

    this.editStore.personalDataSigned = data.personalDataSigned ?? false

    this.editStore.subscriptionsSigned = data.subscriptionsSigned ?? false
  }

  setCity(cityData) {
    if (this.gaApp.features.get('profilePlaid')) {
      this.editStore.form.city = {
        name: cityData.location.city,
        cityFiasId: cityData.location.id,
      }
    } else {
      this.editStore.form.cityName = cityData.location.city
      this.editStore.form.cityId = cityData.location.id
    }
  }

  saveForm() {
    this.gaApp.services.customer.api.saveProfileInfo(
      this.editStore.normalizedForm,
    )
  }

  /**
   * Обработка 400 ошибки при апдейте личных данных пользователя
   * @param {Object} errorData данные полученной ошибки
   */
  handleValidationError(errorData) {
    const invalidParameters = errorData?.invalidParameters

    // формируем объект ошибок в зависимости от наличия параметров ошибки
    const fieldsErrors = invalidParameters
      ? this.getFormErrors(invalidParameters)
      : null

    // если сформированный объект ошибок не пустой
    if (!isEmpty(fieldsErrors)) {
      // сохраняем в стор этот объект ошибок (непосредственно в поля ошибки сетятся в скрипте компонента)
      this.gaApp.stores.customer.edit.formErrors = fieldsErrors
    } else {
      // иначе показываем дефолтную нотификацию об ошибке сохранения данных
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('customer.edit.notification.error'),
      )
    }
  }

  /**
   * Возвращает объект с ошибками полей
   * @param {Object} errorParameters данные полученной ошибки
   * @returns {object} объект, где ключи - имена полей формы,
   * а значения - строка localizedMessage из полученных с бэка invalidParameters ошибки
   */
  getFormErrors(errorParameters) {
    return Object.entries(errorParameters).reduce((acc, [key, value]) => {
      if (
        // проверяем наличие ключа объекта параметров ошибки среди полей формы в сторе
        has(this.gaApp.stores.customer.edit.form, key) &&
        // и наличие сообщения в значении этого ключа
        value.localizedMessage
      ) {
        // заполняем объект ошибок свойствами "имя поля: ошибка"
        acc[key] = value.localizedMessage
      }

      return acc
    }, {})
  }

  resetFormErrors() {
    this.gaApp.stores.customer.edit.formErrors = null
  }

  setWasSaved(value) {
    this.gaApp.stores.customer.edit.wasSaved = value
  }

  setBirthdayEditBlocked(value) {
    this.gaApp.stores.customer.edit.isBirthdayEditBlocked = value
  }

  openEmailConfirmModal() {
    this.gaApp.stores.customer.edit.isEmailConfirmModalOpened = true
  }

  openSupportEditModal() {
    this.gaApp.stores.customer.edit.isSupportEditModalOpened = true
  }
}
