import { CommonRepository } from '../common/common.repository'

export class MainRepository extends CommonRepository {
  async getProduct(parameters = {}) {
    const params = { ...parameters }
    const { url } = this.endpoints.getProduct()
    try {
      const response = await this.gaApp.api.request().get(url, { params })

      return this.gaApp.api.response(response)
    } catch (error) {
      this.gaApp.api.error(error)
    }
  }

  async getProductBase(parameters = {}) {
    const params = { ...parameters }
    const { url } = this.endpoints.getProductBase()
    try {
      const response = await this.gaApp.api.request().get(url, { params })

      return this.gaApp.api.response(response)
    } catch (error) {
      this.gaApp.api.error(error)
    }
  }

  async getProductAdditional(parameters = {}) {
    const params = { ...parameters }
    const { url } = this.endpoints.getProductAdditional()
    try {
      const response = await this.gaApp.api.request().get(url, { params })

      return this.gaApp.api.response(response)
    } catch (error) {
      this.gaApp.api.error(error)
    }
  }

  async getRecommendations(data = {}) {
    const { url } = this.endpoints.getRecommendations()

    try {
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async getCityStores(parameters = {}) {
    const params = { ...parameters }
    const { url } = this.endpoints.getCityStores()

    try {
      const response = await this.gaApp.api.request().get(url, {
        params,
      })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async getStoresStock(parameters = {}) {
    const params = { ...parameters }
    const { url } = this.endpoints.getStoresStock()

    try {
      const response = await this.gaApp.api.request().post(url, params)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async getSlots(parameters = {}) {
    const params = { ...parameters }
    const { url } = this.endpoints.getSlots()

    try {
      const response = await this.gaApp.api.request().get(url, { params })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async getBenefits(parameters = {}) {
    const params = { ...parameters }
    const { url } = this.gaApp.features.get('newBenefits')
      ? this.endpoints.getBenefitsV2()
      : this.endpoints.getBenefits()

    try {
      const response = await this.gaApp.api.request().get(url, { params })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }
}
