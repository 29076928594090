import { EVENT_TYPES } from '../../../constants'

export class DigiAnalyticsProductCartModel {
  constructor({ product, placement }) {
    this.data = {
      eventType : EVENT_TYPES.CART_ADD_EVENT,
      productId : product.id,
      price : product.price,
      productName : product.name,
      sku : this.generateSku(placement),
    }
  }

  generateSku(placement) {
    return ['recsClick', placement.name, placement.id, placement.strategyName].join('|')
  }
}

