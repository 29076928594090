import { TYPE } from '../../constants'
import {
  GdeslonProductViewModel,
  GtmGa4ProductViewModel,
  GtmGaUaProductViewModel,
  GtmGaUaProductViewResetModel,
} from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class ProductViewBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map([
      [TYPE.GTM_4, GtmGa4ProductViewModel],
      [TYPE.GDESLON, GdeslonProductViewModel],
    ])

    if (data.chunk.length) {
      models.set(TYPE.GTM_UA_SYSTEM, GtmGaUaProductViewResetModel)
      models.set(TYPE.GTM_UA, GtmGaUaProductViewModel)
    }

    super(models, data)
  }
}
