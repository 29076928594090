import {
  ARTICLES_PAGE_CHUNK_SIZE,
  COUNT_OF_LOAD_IMAGES,
  COUNT_OF_LOAD_IMAGES_WEBVIEW,
  LOAD_DIR,
} from '../constants'

export class ListService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  addPage(page, dir) {
    // если направление загрузки назад, страница первая и кол-во статей нечетное
    // удалить последнюю статью из списка
    const needDeleteLastArticle =
      dir === LOAD_DIR.PREV && page.number === 0 && page.items.length % 2 !== 0

    if (needDeleteLastArticle) {
      page.items.pop()
    }

    const currentPage = this.buildPage(page)
    this.gaApp.stores.articles.list.pages.push(currentPage)
    this.gaApp.stores.articles.list.pages.sort((a, b) => a.number - b.number)
  }

  // на странице должно быть четное кол-во материалов - по дизайну
  // если кол-во нечетное, переносим последнюю статью на следующую страницу
  // нечетное кол-во допустимо только для последней страницы
  buildPage(page) {
    const itemsForPage = [...page.items]

    // если есть перенесенный материал - добавляем элемент в массив
    if (this.gaApp.stores.articles.list.articleForNextPage) {
      itemsForPage.unshift(this.gaApp.stores.articles.list.articleForNextPage)

      this.gaApp.stores.articles.list.articleForNextPage = null
    }

    // +1 так как нумерация страниц с 0, а общее кол-во с 1
    const isLastPage =
      this.gaApp.stores.articles.list.totalPagesAmount === page.number + 1

    if (!isLastPage && itemsForPage.length % 2 !== 0) {
      this.gaApp.stores.articles.list.articleForNextPage = itemsForPage.pop()
    }

    return {
      number: page.number,
      items: itemsForPage,
    }
  }

  clearPages() {
    this.gaApp.stores.articles.list.pages = []
    this.gaApp.stores.articles.list.totalArticlesAmount = 0
    this.gaApp.stores.articles.list.lastViewedPageNumber = 0
    this.gaApp.stores.articles.list.fetchingPageNumber = null
    this.gaApp.stores.articles.list.articleForNextPage = null
  }

  setCurrentPageNumber(pageNumber) {
    this.gaApp.stores.articles.list.currentPageNumber = pageNumber
  }

  isPageExists(pageNumber) {
    return pageNumber < this.gaApp.stores.articles.list.totalPagesAmount
  }

  isPageRendered(pageNumber) {
    return this.gaApp.stores.articles.list.pages.some(
      (item) => item.number === pageNumber,
    )
  }

  isPageFetching(pageNumber) {
    return this.gaApp.stores.articles.list.fetchingPageNumber === pageNumber
  }

  setInitialListingDataToStore(response) {
    const { items, totalCount, title, seo } = response

    this.gaApp.stores.articles.list.totalArticlesAmount =
      totalCount || items.length

    this.gaApp.stores.articles.list.seoTitle = title || ''
    this.gaApp.stores.articles.list.seoAttrs = seo || []

    this.addPage({
      number: this.gaApp.stores.articles.list.currentPageNumber,
      items: items || [],
    })
  }

  prepareParams({ pageNumber, pageSize, filters, lastActualPublicationDate }) {
    const baseParams = {
      pagination: {
        pageNumber,
        pageSize: pageSize || ARTICLES_PAGE_CHUNK_SIZE,
      },
    }

    const rawFilters = filters || this.gaApp.stores.articles.list.activeFilters

    const requestFilters =
      this.gaApp.services.articles.filters.buildFiltersForRequest(rawFilters)

    return {
      ...baseParams,
      filters:
        Object.keys(requestFilters).length > 0 ? requestFilters : undefined,
      lastActualPublicationDate,
    }
  }

  // Loading
  setLoadingDirection(dir) {
    this.gaApp.stores.articles.list.loadingDirection = dir
  }

  increaseLoadedImageCount() {
    this.gaApp.stores.articles.list.loadedFirstImages += 1
  }

  resetFirstLoadedImagesCount() {
    this.gaApp.stores.articles.list.loadedFirstImages = 0
  }

  lazyImageLimit() {
    // Для SSR фича с ожиданием картинок не нужна
    // так как получается вечный лоадер
    if (this.gaApp.isServer) {
      return 0
    }

    return this.gaApp.isWebview
      ? COUNT_OF_LOAD_IMAGES_WEBVIEW
      : COUNT_OF_LOAD_IMAGES
  }

  async updateListingOnFilterChange(filter) {
    const serviceKey = this.gaApp.stores.articles.list.serviceKey

    await this.clearPages()
    await this.gaApp.services.articles.filters.toggleFilter(filter)
    await this.gaApp.services.articles[serviceKey].fetchInitialData()
  }

  async clearListing() {
    const serviceKey = this.gaApp.stores.articles.list.serviceKey

    await this.gaApp.services.articles.filters.resetFilters()
    await this.gaApp.services.articles[serviceKey].fetchInitialData()
  }

  reset() {
    this.gaApp.stores.articles.list.$reset()
  }
}
