export const INFO_ORDER = ['inn', 'kpp', 'ognip', 'name', 'address']
export const COVER_SELECTOR_SLIDES_PER_VIEW = 5

export const SUPPORT = {
  EMAIL: 'email',
  PHONE: 'phone',
  WHATS_APP: 'whatsApp',
  TELEGRAM: 'telegram',
}

export const SUPPORT_LIST = [
  SUPPORT.EMAIL,
  SUPPORT.PHONE,
  SUPPORT.WHATS_APP,
  SUPPORT.TELEGRAM,
]

export const DAYS_UNTIL_NEAREST_DELIVERY_DATE = 3

// Временное решение, пока идет разработка backend
// TODO: убрать, когда появится метод backend
export const HOLIDAY_DATES = [
  '2023-12-30',
  '2023-12-31',
  '2024-01-01',
  '2024-01-02',
  '2024-01-03',
  '2024-01-04',
  '2024-01-05',
  '2024-01-06',
  '2024-01-07',
  '2024-01-08',
  '2024-02-23',
  '2024-03-08',
  '2024-04-29',
  '2024-04-30',
  '2024-05-01',
  '2024-05-09',
  '2024-05-10',
  '2024-06-12',
  '2024-11-04',
  '2024-12-30',
  '2024-12-31',
]

export const LANDING_BENEFITS_OFFSET = 40

export const LANDING_MENU_ITEM = {
  FAQ: 'faq',
}
