export class ApiService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async fetchNavigation(parameters = {}) {
    try {
      const cacheData =
        this.gaApp.services.cache.main.getServerData('navigation')

      const { data } =
        cacheData ||
        (await this.gaApp.repositories.header.main.fetchNavigation(parameters))

      this.gaApp.stores.header.nav.nav = data || []
    } catch (error) {
      throw new Error(`Ошибка получения навигации`)
    }
  }
}
