export class AddressesEditService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  openInfoModal(addressId) {
    this.gaApp.stores.location.addresses.activeId = addressId
    this.gaApp.stores.location.addresses.showInfoModal = true
  }

  closeInfoModal() {
    this.gaApp.stores.location.addresses.showInfoModal = false
  }

  openEditModal(addressId) {
    this.gaApp.analytics.modules.location.onClickEditAddress()

    this.gaApp.stores.location.addresses.activeId = addressId
    this.gaApp.stores.location.addresses.showEditModal = true
  }

  closeEditModal() {
    this.gaApp.stores.location.addresses.showEditModal = false
  }

  openDeleteModal(addressId) {
    this.gaApp.analytics.modules.location.onClickDeleteAddress()

    this.gaApp.stores.location.addresses.activeId = addressId
    this.gaApp.stores.location.addresses.showDeleteModal = true
  }

  closeDeleteModal() {
    this.gaApp.stores.location.addresses.showDeleteModal = false
  }

  initFormField(value = '', props = {}, error = false) {
    return {
      value,
      error,
      props,
    }
  }

  initFormFields() {
    const activeAddress =
      this.gaApp.stores.location.addresses.addressListAsObject[
        this.gaApp.stores.location.addresses.activeId
      ]

    if (activeAddress) {
      this.gaApp.stores.location.addresses.form = {
        address: this.initFormField(
          this.gaApp.services.location.addresses.formatAddressStreetHouse(
            this.gaApp.stores.location.addresses.activeId,
          ),
          { disabled: true, readonly: true },
        ),
        apartmentNumber: this.initFormField(activeAddress.apartmentNumber),
        entranceNumber: this.initFormField(activeAddress.entranceNumber),
        floorNumber: this.initFormField(activeAddress.floorNumber),
        intercomNumber: this.initFormField(activeAddress.intercomNumber),
      }
    }
  }

  updateFormField({ fieldName, value }) {
    this.gaApp.stores.location.addresses.form[fieldName] = {
      ...this.gaApp.stores.location.addresses.form[fieldName],
      value,
    }
  }

  async edit(id) {
    const { apartmentNumber, entranceNumber, floorNumber, intercomNumber } =
      this.gaApp.stores.location.addresses.form

    const dataV1 = {
      address_id: id,
      apartment_number: apartmentNumber.value,
      entrance_number: entranceNumber.value,
      floor_number: floorNumber.value,
      intercom_number: intercomNumber.value,
    }

    const dataV2 = {
      id,
      apartmentNumber: apartmentNumber.value,
      entranceNumber: entranceNumber.value,
      floorNumber: floorNumber.value,
      intercomNumber: intercomNumber.value,
    }

    const geoAddressesEnabled = this.gaApp.features.get(
      'customerAddressFromMagentoToPlaid',
    )
    const data = geoAddressesEnabled ? dataV2 : dataV1

    await this.gaApp.services.location.addressesApi.updateAddress(data)

    this.closeEditModal()
  }

  async delete() {
    await this.gaApp.services.location.addressesApi.deleteAddress(
      this.gaApp.stores.location.addresses.activeId,
    )

    this.closeDeleteModal()
  }
}
