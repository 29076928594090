import { SOURCE } from '../../../constants'
import { GtmBaseModel } from '../gtm-base/gtm-base.model'
import { GtmGa4ProductBaseModel } from '../gtm-ga4-product-base.model/gtm-ga4-product-base.model'

export class GtmGa4ProductViewModel extends GtmBaseModel {
  constructor({ chunk }) {
    if (!chunk.length) {
      throw new Error('data chunk is required')
    }

    super({ event: 'view_item_list', eventSource: SOURCE })

    const { id, searchTerm, list } = chunk[0]

    this.item_list_name = list
    this.item_list_id = id
    this.search_term = searchTerm
    this.ecommerce = {
      items: chunk.map((product) => new GtmGa4ProductBaseModel(product)),
    }
  }
}
