// @vue/component
export default {
  name: 'ga-details',

  props: {
    error: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },

  computed: {
    lowercase() {
      return Object.keys(this.$scopedSlots).length === 0
    },
  },
}
