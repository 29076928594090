import { VENDOR } from '../../constants'

const KEY_BY_TYPE = {
  product: 'item',
  listing: 'category',
}

export class GaAnalyticsFacade {
  constructor(gaAnalytics, gaApp, storage) {
    this.module = gaAnalytics
    this.gaApp = gaApp
    this.storage = storage
  }

  get entity() {
    return VENDOR.GAA
  }

  init() {
    if (!this.module) {
      throw new Error(`Модуль ${this.entity} отсутствует`)
    }

    const endpoints = this.gaApp.api.endpoints({
      setProduct: () => ({
        url: 'analytics/item/add',
      }),

      getProduct: () => ({
        url: 'analytics/item/get',
      }),

      setListing: () => ({
        url: 'analytics/category/add',
      }),

      getListing: () => ({
        url: 'analytics/category/get',
      }),
    })

    return this.module.create(this.gaApp.api, endpoints)
  }

  /**
   * @param {type} param0 "listing" или "product"
   */
  send({ type, ...args }) {
    if (!this.gaApp.analytics.libs.state.initialized) {
      return false
    }

    if (!this.module) {
      throw new Error(`Модуль ${this.entity} отсутствует`)
    }

    if (!type) {
      throw new Error(`В модуле ${this.entity} отсутствует type`)
    }

    if (!this.module[type].set) {
      throw new Error(`Ошибка в модуле ${this.entity}`)
    }

    if (!args[KEY_BY_TYPE[type]]?.itemListName) {
      const debugKey = args.debug?.key

      const error = new Error(`Отсутствует itemListName [${debugKey}]`)
      error.data = JSON.stringify(args.debug || {})
      error.name = 'Analytic item list name error'

      this.gaApp.services.app.apm.captureError(error)

      return false
    }

    if (args?.debug) {
      delete args.debug
    }

    const deviceId = this.gaApp.services.app.main.deviceId

    this.storage[this.entity] = {}
    this.storage[this.entity][type] =
      args[KEY_BY_TYPE.product] || args[KEY_BY_TYPE.listing]

    const set = () => this.module[type].set({ ...args, deviceId })

    return this.gaApp.services.app.scheduler.postTask(set, 'background')
  }

  /**
   * @param {type} param0 "listing" или "product"
   */
  get({ type, ...args }) {
    if (!this.gaApp.analytics.libs.state.initialized) {
      return false
    }

    if (!this.module) {
      throw new Error(`Модуль ${this.entity} отсутствует`)
    }

    if (!type) {
      throw new Error(`В модуле ${this.entity} отсутствует type`)
    }

    if (!this.module[type]?.get) {
      throw new Error(`Ошибка в модуле ${this.entity}`)
    }

    if (this.storage[this.entity][type]) {
      const data = this.storage[this.entity][type]
      this.storage[this.entity] = {}

      return new Promise((resolve) => {
        resolve({
          data: {
            data,
          },
        })
      })
    }

    const deviceId = this.gaApp.services.app.main.deviceId

    return this.module[type].get({ ...args, deviceId })
  }
}
