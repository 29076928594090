export const endpoints = {
  getDataMainPage: () => ({
    url: 'gift-cards/pages/main',
  }),
  getDataMenu: () => ({
    url: 'gift-cards/pages/menu',
  }),
  getDataAboutPage: () => ({
    url: 'gift-cards/pages/about',
  }),
  getConfig: () => ({
    url: 'gift-cards/electronic/configuration',
  }),
  basketId: () => ({
    url: 'gift-cards/electronic/basket-id',
  }),
  paymentOrderCard: () => ({
    url: 'gift-cards/electronic/payment/order-card',
  }),
  receiveCard: () => ({
    url: 'gift-cards/electronic/receive',
  }),
  receiveReturnCard: () => ({
    url: 'gift-cards/electronic/receiveReturnCard',
  }),
  sendReaction: () => ({
    url: 'gift-cards/electronic/reactions/send',
  }),
  getReactionsConfig: () => ({
    url: 'gift-cards/electronic/reactions/config',
  }),
  receiveReaction: (reactionId) => ({
    url: `gift-cards/electronic/reactions/receive/${reactionId}`,
  }),
  getDenomination: () => ({
    url: 'gift-cards/denomination',
  }),
  getPaymentSavedCards: () => ({
    url: `gift-cards/electronic/payment/cards`,
  }),
  removePaymentSavedCard: (cardId) => ({
    url: `gift-cards/electronic/payment/cards/${cardId}`,
  }),
  paymentSbpCard: () => ({
    url: 'gift-cards/electronic/payment/order-sbp',
  }),
  checkOrderStatus: (paymentId) => ({
    url: `gift-cards/electronic/payment/status/${paymentId}`,
  }),
  startGeneration: () => ({
    url: `gift-cards/ai/image-generation`,
  }),
  getAiImage: (yandexImageId) => ({
    url: `gift-cards/ai/image-generation/${yandexImageId}`,
  }),
  getAiConfig: () => ({
    url: 'gift-cards/ai/configuration',
  }),
}
