import { prepareItemsForLocalStorage } from '../helpers'

export class ApiService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  setCart(cart) {
    this.gaApp.services.cart.main.setCart(cart)
  }

  setAvailableCoupons(coupons) {
    this.gaApp.services.cart.main.setAvailableCoupons(coupons)
  }

  saveCart(rawCart) {
    this.gaApp.services.cache.main.saveCart(rawCart)
    this.gaApp.services.cache.main.saveCartItems(
      prepareItemsForLocalStorage(this.gaApp.stores.cart.main.analyticItems),
    )
  }

  setLoadingItems(ids = []) {
    this.gaApp.stores.cart.modal.itemsLoading = ids
  }

  async getData() {
    try {
      const cacheData = this.gaApp.services.cache.main.getServerData('cart')

      const { data } =
        cacheData || (await this.gaApp.repositories.cart.main.getData())
      const { cart, rawCart, message } = data

      if (!cart) {
        const error = new Error('Не удалось получить данные корзины')
        error.localizedMessage = message
        throw error
      }

      this.setCart(cart)
      this.saveCart(rawCart)
    } catch (error) {
      console.log(error)
      this.gaApp.services.notification.main.open(
        error.localizedMessage
          ? error.localizedMessage
          : this.gaApp.i18n.t('cart.error.get'),
      )
    }
  }

  async addItems(items = []) {
    try {
      const { data } = await this.gaApp.repositories.cart.main.addItems(items)
      const { cart, rawCart, message } = data

      if (!cart) {
        const error = new Error('Не удалось добавить продукты')
        error.localizedMessage = message
        throw error
      }

      this.setCart(cart)
      this.saveCart(rawCart)
    } catch (error) {
      if (error.localizedMessage) {
        this.gaApp.services.notification.main.open(error.localizedMessage)
      }

      throw error
    }
  }

  async addItem(product = {}, hasAlert = true) {
    try {
      const { data } = await this.gaApp.repositories.cart.main.addItem({
        // data: { sku: '18056600027' || product.id },
        data: {
          sku: product.id,
          analyticsDetails: product.analyticsDetails,
        },
      })
      const { cart, rawCart, message } = data

      if (!cart) {
        const error = new Error('Не удалось добавить продукт')
        error.localizedMessage = message
        throw error
      }

      this.setCart(cart)

      if (hasAlert) {
        this.gaApp.services.cart.notification.addSuccess(product)
      }

      this.saveCart(rawCart)
    } catch (error) {
      error.localizedMessage
        ? this.gaApp.services.notification.main.open(error.localizedMessage)
        : this.gaApp.services.cart.notification.addFailure(product)
    }
  }

  async addItemByBarcode(product = {}, hasAlert = false) {
    try {
      const { data } = await this.gaApp.repositories.cart.main.addItemByBarcode(
        {
          // data: { barcode: '8714100725924' },
          data: { barcode: product.id },
        },
      )
      const { cart, rawCart, message } = data

      if (!cart) {
        const error = new Error('Не удалось добавить продукт по баркоду')
        error.localizedMessage = message
        throw error
      }

      this.setCart(cart)

      if (hasAlert) {
        this.gaApp.services.cart.notification.addSuccess(product)
      }

      this.saveCart(rawCart)
    } catch (error) {
      error.localizedMessage
        ? this.gaApp.services.notification.main.open(error.localizedMessage)
        : this.gaApp.services.cart.notification.addFailure(product)
    }
  }

  async validateStock() {
    try {
      const items = this.gaApp.services.cart.main.getItemsStatuses()

      const { data } = await this.gaApp.repositories.cart.main.validateStock({
        data: { items },
      })

      const { availableCouponsRecollect, isValid, needRequestData } = data
      const isCartActual = isValid && !needRequestData

      if (!isCartActual) {
        await this.getData()
      }

      if (isCartActual && availableCouponsRecollect) {
        await this.recollectAvailableCoupons()
      }
    } catch (error) {
      await this.getData()
    }
  }

  async loadValidCartData() {
    if (this.gaApp.stores.cart.modal.isEmpty) {
      await this.getData()
    } else {
      await this.validateStock()
    }
  }

  async recollectAvailableCoupons() {
    const cartId = this.gaApp.stores.cart.main.id

    try {
      const { data } =
        await this.gaApp.repositories.cart.main.recollectAvailableCoupons({
          cartId,
        })
      const { availableCoupons } = data

      this.setAvailableCoupons(availableCoupons)
    } catch (error) {
      this.gaApp.services.notification.main.open(
        error.localizedMessage
          ? error.localizedMessage
          : this.gaApp.i18n.t('cart.error.get'),
      )
    }
  }

  async updateItemQty({ id, qty }) {
    try {
      this.setLoadingItems([id])

      const { data } = await this.gaApp.repositories.cart.main.updateItemQty({
        data: {
          itemId: id,
          itemQty: qty,
        },
      })

      const { cart, rawCart, message } = data

      if (!cart) {
        const error = new Error('Не удалось обновить количество')
        error.localizedMessage = message
        throw error
      }

      this.setCart(cart)
      this.saveCart(rawCart)
    } catch (error) {
      this.gaApp.services.notification.main.open(
        error.localizedMessage
          ? error.localizedMessage
          : this.gaApp.i18n.t('cart.error.updateQty'),
      )
      this.getData()
    } finally {
      this.setLoadingItems()
    }
  }

  async deleteItems({ ids }) {
    try {
      this.setLoadingItems(ids)

      const params = {
        itemIds: ids.join(','),
      }

      const { data } = await this.gaApp.repositories.cart.main.deleteItems({
        params,
      })

      const { cart, rawCart, message } = data

      if (!cart) {
        const error = new Error('Не удалось удалить продукты')
        error.localizedMessage = message
        throw error
      }

      this.setCart(cart)
      this.saveCart(rawCart)
    } catch (error) {
      this.gaApp.services.notification.main.open(
        error.localizedMessage
          ? error.localizedMessage
          : this.gaApp.i18n.t('cart.error.delete'),
      )
      this.getData()
    } finally {
      this.setLoadingItems()
    }
  }

  async setCoupon(coupon = null) {
    const prevValue = this.gaApp.stores.cart.modal.coupon.value
    const isApplying = Boolean(coupon)
    const value = coupon?.value || ''

    try {
      const captchaToken =
        await this.gaApp.services.captcha.main.generateToken()
      let result = {}

      if (isApplying) {
        result = await this.gaApp.repositories.cart.main.applyCoupon({
          data: { captchaToken, value },
        })
      } else {
        result = await this.gaApp.repositories.cart.main.cancelCoupon({
          params: {
            captchaToken,
          },
        })
      }

      const { cart, rawCart, message } = result.data
      const isSuccess = Boolean(cart || !message)

      if (!isSuccess) {
        this.gaApp.analytics.modules.cart.onCouponApply(coupon, false)
        this.gaApp.stores.cart.main.totals.coupon = value
        this.gaApp.services.cart.modal.setCouponErrorMessage(message)

        return
      }

      this.gaApp.services.cart.modal.setCouponErrorMessage(null)

      if (!cart) {
        const error = new Error('Не удалось применить или удалить купон')
        error.localizedMessage = message
        throw error
      }

      if (isApplying) {
        this.gaApp.analytics.modules.cart.onCouponApply(coupon, true)
      } else {
        this.gaApp.analytics.modules.cart.onCouponRemove({ value: prevValue })
      }

      this.setCart(cart)
      this.saveCart(rawCart)
    } catch (error) {
      this.gaApp.services.notification.main.open(
        error.localizedMessage
          ? error.localizedMessage
          : this.gaApp.i18n.t('cart.error.coupon'),
      )
      this.getData()

      throw error
    }
  }

  async getProductsActual(ids) {
    try {
      const { data } =
        await this.gaApp.repositories.plp.main.fetchProductsActual(ids)

      return data?.products ?? []
    } catch (error) {
      console.error(error)

      return []
    }
  }

  async reorder(id) {
    try {
      this.gaApp.stores.cart.main.reorderPending = true

      const response = await this.gaApp.repositories.cart.main.reorder(id)

      const { cart, rawCart, message, isPartial } = response.data

      if (!cart) {
        const error = new Error('Не удалось добавить товары в корзину')

        error.localizedMessage = message
        throw error
      }

      if (isPartial) {
        this.gaApp.services.cart.notification.addNotification(
          this.gaApp.i18n.t('cart.error.partialReorder'),
        )
      }

      this.setCart(cart)
      this.saveCart(rawCart)

      this.gaApp.services.modal.main.openSuperModalCart()
    } catch (error) {
      error.localizedMessage
        ? this.gaApp.services.cart.notification.addNotification(
            error.localizedMessage,
          )
        : this.gaApp.services.cart.notification.addNotification(
            this.gaApp.i18n.t('cart.error.reorder'),
          )
    } finally {
      this.gaApp.stores.cart.main.reorderPending = false
    }
  }

  async getSharingShare(products) {
    this.gaApp.stores.cart.share.loadingHash = true

    try {
      const { data } = await this.gaApp.repositories.cart.share.getHash({
        products,
      })
      const { hash } = data

      if (hash.length === 0) {
        throw new Error('Не удалось получить хэш расшаренных товаров')
      }

      return hash
    } catch (error) {
      this.showError(error, this.gaApp.i18n.t('cart.error.somethingWrong'))

      return ''
    } finally {
      this.gaApp.stores.cart.share.loadingHash = false
    }
  }

  async getSharingList(params) {
    this.gaApp.stores.cart.share.loadingProducts = true

    try {
      const { data } = await this.gaApp.repositories.cart.share.getList(params)
      const { products } = data

      if (products.length === 0) {
        throw new Error('Не удалось получить расшаренные товары')
      }

      return products
    } catch (error) {
      this.showError(error, this.gaApp.i18n.t('cart.error.somethingWrong'))

      return []
    } finally {
      this.gaApp.stores.cart.share.loadingProducts = false
    }
  }

  showError(error, fallbackText) {
    const errorText = error.localizedMessage || fallbackText

    this.gaApp.services.notification.main.open(errorText)
  }
}
