import * as components from './components'
import { locales } from './locales'
import { services } from './services'
import { stores } from './stores'

export { useSuperModal, useSuperModalHooks, useModalStore } from './composable'

export { AUTH_MODAL_REASON_TO_OPEN } from './constants'

export const modal = {
  meta: {
    name: 'modal',
    locales,
  },
  components,
  layers: {
    services,
    stores,
  },
}
