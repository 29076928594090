import Vue from 'vue'

import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../client/layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'

/* Plugins */

import nuxt_plugin_plugin_47557304 from 'nuxt_plugin_plugin_47557304' // Source: ./composition-api/plugin.mjs (mode: 'all')
import nuxt_plugin_amp_1ff358de from 'nuxt_plugin_amp_1ff358de' // Source: ./amp.js (mode: 'all')
import nuxt_plugin_vuescrollto_772c2a5d from 'nuxt_plugin_vuescrollto_772c2a5d' // Source: ./vue-scrollto.js (mode: 'client')
import nuxt_plugin_dayjsplugin_3974059e from 'nuxt_plugin_dayjsplugin_3974059e' // Source: ./dayjs-plugin.js (mode: 'all')
import nuxt_plugin_pluginutils_1e7d398f from 'nuxt_plugin_pluginutils_1e7d398f' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_2a159f1a from 'nuxt_plugin_pluginrouting_2a159f1a' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_21e99ad6 from 'nuxt_plugin_pluginmain_21e99ad6' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_d55bcc58 from 'nuxt_plugin_cookieuniversalnuxt_d55bcc58' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_advcake_20a92352 from 'nuxt_plugin_advcake_20a92352' // Source: ./advcake.js (mode: 'client')
import nuxt_plugin_eyezonmock_0212871c from 'nuxt_plugin_eyezonmock_0212871c' // Source: ./eyezon.mock.js (mode: 'client')
import nuxt_plugin_digianalytics_0a07408c from 'nuxt_plugin_digianalytics_0a07408c' // Source: ./digi-analytics.js (mode: 'client')
import nuxt_plugin_gaanalytics_56b5b3f9 from 'nuxt_plugin_gaanalytics_56b5b3f9' // Source: ./ga-analytics.js (mode: 'client')
import nuxt_plugin_gdeslonmock_372f7294 from 'nuxt_plugin_gdeslonmock_372f7294' // Source: ./gdeslon.mock.js (mode: 'client')
import nuxt_plugin_mindbox_404594c9 from 'nuxt_plugin_mindbox_404594c9' // Source: ./mindbox.js (mode: 'client')
import nuxt_plugin_gtm_2a91b402 from 'nuxt_plugin_gtm_2a91b402' // Source: ./gtm.js (mode: 'all')
import nuxt_plugin_axios_2e0a522c from 'nuxt_plugin_axios_2e0a522c' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_distplugin44f599a2_2c9999b4 from 'nuxt_plugin_distplugin44f599a2_2c9999b4' // Source: ./dist.plugin.44f599a2.mjs (mode: 'all')
import nuxt_plugin_apmclient_fd0dba74 from 'nuxt_plugin_apmclient_fd0dba74' // Source: ../client/plugins/apm.client.js (mode: 'client')
import nuxt_plugin_initapp_4c56a4c4 from 'nuxt_plugin_initapp_4c56a4c4' // Source: ../client/plugins/init.app.js (mode: 'all')
import nuxt_plugin_analytics_2e48c327 from 'nuxt_plugin_analytics_2e48c327' // Source: ../client/plugins/analytics.js (mode: 'all')
import nuxt_plugin_api_785206da from 'nuxt_plugin_api_785206da' // Source: ../client/plugins/api.js (mode: 'all')
import nuxt_plugin_vuedoublerafclient_60c008d4 from 'nuxt_plugin_vuedoublerafclient_60c008d4' // Source: ../client/plugins/vue-double-raf.client.js (mode: 'client')
import nuxt_plugin_libsclient_48a2a83c from 'nuxt_plugin_libsclient_48a2a83c' // Source: ../client/plugins/libs.client.js (mode: 'client')
import nuxt_plugin_bemclassname_654d87d9 from 'nuxt_plugin_bemclassname_654d87d9' // Source: ../client/plugins/bem-class-name (mode: 'all')
import nuxt_plugin_datatestid_c90f18ee from 'nuxt_plugin_datatestid_c90f18ee' // Source: ../client/plugins/data-test-id (mode: 'all')
import nuxt_plugin_dayjs_35268fa2 from 'nuxt_plugin_dayjs_35268fa2' // Source: ../client/plugins/dayjs (mode: 'all')
import nuxt_plugin_dates_3526b662 from 'nuxt_plugin_dates_3526b662' // Source: ../client/plugins/dates (mode: 'all')
import nuxt_plugin_mediaquery_6c026069 from 'nuxt_plugin_mediaquery_6c026069' // Source: ../client/plugins/media-query (mode: 'all')
import nuxt_plugin_notifier_5195ff1c from 'nuxt_plugin_notifier_5195ff1c' // Source: ../client/plugins/notifier (mode: 'all')
import nuxt_plugin_modules_568b5106 from 'nuxt_plugin_modules_568b5106' // Source: ../client/plugins/modules.js (mode: 'all')
import nuxt_plugin_i18n_6a80ea94 from 'nuxt_plugin_i18n_6a80ea94' // Source: ../client/plugins/i18n (mode: 'all')
import nuxt_plugin_cpa_6d76a95b from 'nuxt_plugin_cpa_6d76a95b' // Source: ../client/plugins/cpa.client (mode: 'client')
import nuxt_plugin_initclient_ad36b038 from 'nuxt_plugin_initclient_ad36b038' // Source: ../client/plugins/init.client.js (mode: 'client')
import nuxt_plugin_initserver_0f1e196c from 'nuxt_plugin_initserver_0f1e196c' // Source: ../client/plugins/init.server.js (mode: 'server')
import nuxt_plugin_meta_598eade0 from 'nuxt_plugin_meta_598eade0' // Source: ./composition-api/meta.mjs (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

async function createApp(ssrContext, config = {}) {
  const store = null
  const router = await createRouter(ssrContext, config, { store })

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"},{"name":"HandheldFriendly","content":"true"},{"name":"MobileOptimized","content":"width"},{"name":"apple-mobile-web-app-capable","content":"yes"},{"name":"format-detection","content":"telephone=no, address=no, email=no"},{"http-equiv":"X-UA-Compatible","content":"IE=edge"}],"script":[{"type":"text\u002Fjavascript","charset":"utf-8","innerHTML":"document.documentElement.style.setProperty(`--window-inner-height`, `${window.innerHeight}px`)"},{"hid":"gtm-script","innerHTML":"if(!window._gtm_init){window._gtm_init=1;(function(w,n,d,m,e,p){w[d]=(w[d]==1||n[d]=='yes'||n[d]==1||n[m]==1||(w[e]&&w[e][p]&&w[e][p]()))?1:0})(window,navigator,'doNotTrack','msDoNotTrack','external','msTrackingProtectionEnabled');(function(w,d,s,l,x,y){w[x]={};w._gtm_inject=function(i){if(w.doNotTrack||w[x][i])return;w[x][i]=1;w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s);j.async=true;j.src='https:\u002F\u002Fwww.googletagmanager.com\u002Fgtm.js?id='+i;f.parentNode.insertBefore(j,f);}})(window,document,'script','dataLayer','_gtm_ids','_gtm_inject')}"}],"link":[],"style":[],"noscript":[{"hid":"gtm-noscript","pbody":true,"innerHTML":"\u003Ciframe src=\"https:\u002F\u002Fwww.googletagmanager.com\u002Fns.html?id=GTM-K9LNN8Q&\" height=\"0\" width=\"0\" style=\"display:none;visibility:hidden\" title=\"gtm\"\u003E\u003C\u002Fiframe\u003E"}],"__dangerouslyDisableSanitizersByTagID":{"gtm-script":["innerHTML"],"gtm-noscript":["innerHTML"]}},

    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_47557304 === 'function') {
    await nuxt_plugin_plugin_47557304(app.context, inject)
  }

  if (typeof nuxt_plugin_amp_1ff358de === 'function') {
    await nuxt_plugin_amp_1ff358de(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuescrollto_772c2a5d === 'function') {
    await nuxt_plugin_vuescrollto_772c2a5d(app.context, inject)
  }

  if (typeof nuxt_plugin_dayjsplugin_3974059e === 'function') {
    await nuxt_plugin_dayjsplugin_3974059e(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_1e7d398f === 'function') {
    await nuxt_plugin_pluginutils_1e7d398f(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_2a159f1a === 'function') {
    await nuxt_plugin_pluginrouting_2a159f1a(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_21e99ad6 === 'function') {
    await nuxt_plugin_pluginmain_21e99ad6(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_d55bcc58 === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_d55bcc58(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_advcake_20a92352 === 'function') {
    await nuxt_plugin_advcake_20a92352(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_eyezonmock_0212871c === 'function') {
    await nuxt_plugin_eyezonmock_0212871c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_digianalytics_0a07408c === 'function') {
    await nuxt_plugin_digianalytics_0a07408c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_gaanalytics_56b5b3f9 === 'function') {
    await nuxt_plugin_gaanalytics_56b5b3f9(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_gdeslonmock_372f7294 === 'function') {
    await nuxt_plugin_gdeslonmock_372f7294(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_mindbox_404594c9 === 'function') {
    await nuxt_plugin_mindbox_404594c9(app.context, inject)
  }

  if (typeof nuxt_plugin_gtm_2a91b402 === 'function') {
    await nuxt_plugin_gtm_2a91b402(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_2e0a522c === 'function') {
    await nuxt_plugin_axios_2e0a522c(app.context, inject)
  }

  if (typeof nuxt_plugin_distplugin44f599a2_2c9999b4 === 'function') {
    await nuxt_plugin_distplugin44f599a2_2c9999b4(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_apmclient_fd0dba74 === 'function') {
    await nuxt_plugin_apmclient_fd0dba74(app.context, inject)
  }

  if (typeof nuxt_plugin_initapp_4c56a4c4 === 'function') {
    await nuxt_plugin_initapp_4c56a4c4(app.context, inject)
  }

  if (typeof nuxt_plugin_analytics_2e48c327 === 'function') {
    await nuxt_plugin_analytics_2e48c327(app.context, inject)
  }

  if (typeof nuxt_plugin_api_785206da === 'function') {
    await nuxt_plugin_api_785206da(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuedoublerafclient_60c008d4 === 'function') {
    await nuxt_plugin_vuedoublerafclient_60c008d4(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_libsclient_48a2a83c === 'function') {
    await nuxt_plugin_libsclient_48a2a83c(app.context, inject)
  }

  if (typeof nuxt_plugin_bemclassname_654d87d9 === 'function') {
    await nuxt_plugin_bemclassname_654d87d9(app.context, inject)
  }

  if (typeof nuxt_plugin_datatestid_c90f18ee === 'function') {
    await nuxt_plugin_datatestid_c90f18ee(app.context, inject)
  }

  if (typeof nuxt_plugin_dayjs_35268fa2 === 'function') {
    await nuxt_plugin_dayjs_35268fa2(app.context, inject)
  }

  if (typeof nuxt_plugin_dates_3526b662 === 'function') {
    await nuxt_plugin_dates_3526b662(app.context, inject)
  }

  if (typeof nuxt_plugin_mediaquery_6c026069 === 'function') {
    await nuxt_plugin_mediaquery_6c026069(app.context, inject)
  }

  if (typeof nuxt_plugin_notifier_5195ff1c === 'function') {
    await nuxt_plugin_notifier_5195ff1c(app.context, inject)
  }

  if (typeof nuxt_plugin_modules_568b5106 === 'function') {
    await nuxt_plugin_modules_568b5106(app.context, inject)
  }

  if (typeof nuxt_plugin_i18n_6a80ea94 === 'function') {
    await nuxt_plugin_i18n_6a80ea94(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_cpa_6d76a95b === 'function') {
    await nuxt_plugin_cpa_6d76a95b(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_initclient_ad36b038 === 'function') {
    await nuxt_plugin_initclient_ad36b038(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_initserver_0f1e196c === 'function') {
    await nuxt_plugin_initserver_0f1e196c(app.context, inject)
  }

  if (typeof nuxt_plugin_meta_598eade0 === 'function') {
    await nuxt_plugin_meta_598eade0(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    app,
    router
  }
}

export { createApp, NuxtError }
