import flow from 'lodash/flow'

export class ProductsService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  blockExists(block) {
    return Object.keys(
      this.gaApp.stores.brandzone.products.productSliderData,
    ).includes(block.id)
  }

  updateBlockData(block) {
    const index =
      this.gaApp.stores.brandzone.products.productSliderData[block.id]?.index

    this.gaApp.stores.brandzone.products.items[index].data =
      this.mapBlockData(block).data
  }

  addBlockData(block) {
    this.gaApp.stores.brandzone.products.items.push({
      id: block.id,
      data: this.mapBlockData(block).data,
    })
  }

  mapBlockData(block) {
    return flow(this.excludeOOS.bind(this))(block)
  }

  excludeOOS(block) {
    block.data.content.products = block.data.content.products.filter(
      (product) => product.inStock,
    )

    return block
  }

  fillStore(block) {
    if (this.blockExists(block)) {
      this.updateBlockData(block)
    } else {
      this.addBlockData(block)
    }
  }

  reset() {
    this.gaApp.stores.brandzone.products.$reset()
  }
}
