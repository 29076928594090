import { EVENT } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4FavoriteModel extends GtmBaseModel {
  constructor({ items }) {
    super({ event: EVENT.FAVORITE.GA4 })
    this.item_list_name = 'cart'

    this.ecommerce = {
      items,
    }
  }
}
