import { TARGET } from '../../constants/common'
import { CommonService } from '../common/_common.service'

/**
 * PreviewService наследуется от CommonService и предназначен
 * для работы с предварительным просмотром продукта на странице.
 */

export class PreviewService extends CommonService {
  constructor(gaApp) {
    super(gaApp, TARGET.PREVIEW)
  }

  /**
   * Метод для получения данных о продукте.
   * Он вызывает метод getProductData из родительского класса CommonService, передавая itemId.
   */
  async getProduct({ itemId = null } = {}) {
    return await Promise.allSettled([
      this.getProductDataBase({ itemId }),
      this.getProductDataAdditional({ itemId }),
    ])
  }

  /**
   * Метод для получения данных о доставке продукта.
   * Он вызывает метод getDeliveryData из родительского класса CommonService, передавая itemId.
   */
  getDelivery({ itemId }) {
    return this.getDeliveryData({ itemId })
  }

  /**
   * Метод для установки аналитических данных в store pdp.preview.analyticsData.
   * Принимает itemListName (имя списка продуктов), searchTerm (поисковый запрос),
   * itemListId (идентификатор списка продуктов), index (индекс в списке),
   * analyticsData - аналитические данные для отправки ga-analytics.
   */
  setAnalyticsData({
    itemListName,
    searchTerm,
    itemListId,
    index,
    analyticsData,
  }) {
    this.gaApp.stores.pdp.preview.analyticsData = {
      itemListName,
      searchTerm,
      itemListId,
      index,
      indexTotal: index,
      ...analyticsData,
    }
  }

  /**
   * Метод для установки номера страницы списка продуктов в store pdp.preview.listingPageNumber.
   */
  setListingPageNumber({ pageNumber }) {
    this.gaApp.stores.pdp.preview.listingPageNumber = pageNumber
  }

  /**
   * Метод для установки номера страницы списка продуктов и аналитических данных.
   * Вызывает методы setListingPageNumber и setAnalyticsData.
   */
  setPageNumberWithAnalytics({
    pageNumber = null,
    itemListName = null,
    searchTerm = null,
    analyticsData = null,
  } = {}) {
    this.setListingPageNumber({ pageNumber })
    this.setAnalyticsData({ itemListName, searchTerm, analyticsData })
  }
}
