<template>
  <svg fill="none" stroke="none" viewBox="0 0 88 88">
    <g clip-path="url(#info-skyeng-logo-small-a)">
      <rect width="88" height="88" fill="#000" rx="21.275" />
      <path
        fill="#00C1FF"
        d="M26.32 59.869c-3.5 2.028-6.015.338-6.015-2.668V31.597c0-3.006 2.513-4.696 6.015-2.668l21.806 12.634c2.175 1.26 2.175 4.41 0 5.67 0 .001-16.354 9.478-21.806 12.636Z"
      />
      <path
        fill="#6FE4FF"
        d="M44.328 59.869c-3.5 2.028-6.016.338-6.016-2.668V31.597c0-3.006 2.514-4.696 6.016-2.668l21.328 12.357c2.388 1.384 2.388 4.842 0 6.225-.002 0-15.996 9.269-21.328 12.358Z"
      />
      <mask
        id="info-skyeng-logo-small-b"
        width="30"
        height="33"
        x="38"
        y="28"
        maskUnits="userSpaceOnUse"
        style="mask-type: alpha"
      >
        <path
          fill="#6FE4FF"
          d="M44.324 59.869c-3.5 2.028-6.015.338-6.015-2.668V31.597c0-3.006 2.513-4.696 6.015-2.668l21.328 12.357c2.388 1.384 2.388 4.842 0 6.225-.002 0-15.996 9.269-21.328 12.358Z"
        />
      </mask>
      <g
        filter="url(#info-skyeng-logo-small-c)"
        mask="url(#info-skyeng-logo-small-b)"
      >
        <path
          fill="#009EE4"
          d="M26.32 59.869c-3.5 2.028-6.015.338-6.015-2.668V31.597c0-3.006 2.513-4.696 6.015-2.668l21.806 12.634c2.175 1.26 2.175 4.41 0 5.67 0 .001-16.354 9.478-21.806 12.636Z"
        />
      </g>
    </g>
    <defs>
      <clipPath id="info-skyeng-logo-small-a">
        <path fill="#fff" d="M0 0h88v88H0z" />
      </clipPath>
      <filter
        id="info-skyeng-logo-small-c"
        width="32.663"
        height="35.922"
        x="18.699"
        y="26.438"
        color-interpolation-filters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          mode="normal"
          result="shape"
        />
        <feGaussianBlur
          result="effect1_foregroundBlur_2194_789"
          stdDeviation=".803"
        />
      </filter>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-info-skyeng-logo-small',
}
</script>
